.candidate-page {
    .contact.contact-dark .form__item {
        input {
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                transition: background-color 50000s ease-in-out 0s;
                -webkit-text-fill-color: var(--color-dark-blue) !important;
            }
        }
    }

    .hero-section {
        padding-bottom: 50px;
    }

    .insights.insights-dark {
        margin-top: -31px;
    }

    .hero-insights__search {
        margin-top: ac(98px, 35px);
        padding: 30px;

        width: 100%;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        .search__inner {
            margin: 0 auto;
            max-width: 800px;

            button i {
                font-size: ac(33px, 25px);
            }
        }
    }

    .jobs {
        z-index: 2;
        padding-bottom: ac(95px, 112px);
        padding-top: ac(108px, 40px);

        & + .total {
            z-index: 0;
        }
    }

    .jobs + .insights-dark {
        --y1: 0;
        margin-top: 0;

        .insights-shape {
            display: none;
        }
    }

    .sectors-section + .contact-dark {
        padding-top: 50px !important;
        background: var(--color-white);
        clip-path: polygon(
                0 0,
                50% 50px,
                100% 50px,
                100% calc(100% - 80px),
                calc(100% - 160px) 100%,
                0 100%
        );
        padding-bottom: 85px;
        color: var(--color-dark-blue);

        h1,
        h2,
        h3,
        label,
        input,
        span {
            color: inherit;
        }
        .thanks-title{
            color: inherit!important;
        }

        .contact__head-title {
            color: inherit !important;
        }

        input {
            border-color: var(--color-dark-blue);
        }

        .select-wr .select2-container {
            border-color: var(--color-dark-blue);
        }

        .upload-btn {
            label {
                border-color: var(--color-dark-blue);
            }
        }

        .checkbox-entry {
            span::before {
                border-color: var(--color-dark-blue);
            }

            input:checked + span::before {
                border-color: transparent;
            }
        }

        .form__item:focus-within {
            label {
                color: var(--color-dark-blue);
            }

            input {
                border-color: var(--color-light-blue);
            }
        }
    }

    .jobs-decor-shape {
        clip-path: polygon(0 0, 100% 60.3%, 0 100%, 0 0);
        transform: translateY(39.8%);
        width: 49.4%;

        &::before {
            padding-top: 33.95%;
        }

        img {
            object-position: center 67%;
        }
    }

    .contact.contact-dark {
        padding: 0 !important;
        margin-top: 0;

        .cont {
            padding-bottom: 91px;
            padding-top: 107px;
        }

        textarea {
            &:focus {
                &::placeholder {
                    color: var(--color-white);
                }
            }
        }
    }

    .sectors-section {
        padding-top: ac(134px, 50px);
        padding-bottom: ac(70px, 10px);
    }

    .jobs__decor {
        clip-path: polygon(0 0, 100% 61.2%, 0 100%);
        transform: translateY(38%);
        width: ac(723px, 220px);
        @media (max-width: 550px) {
            img {
                object-position: left bottom;
            }
        }
        @media (max-width: 370px) {
            width: 163px;
        }

        &::before {
            padding-top: 34%;
        }
    }
}

.job-search-page {
    .hero-search__search {
        padding: 30px;

        .search__inner {
            margin: 0 auto;
            max-width: 800px;

            button i {
                font-size: ac(33px, 25px);
            }
        }
    }
}

.section-approach-candidate.section-approach {
    .section-approach-thumbnail {
        img {
            object-position: left center;
        }
    }
}

.candidate-page {
    .total {
        --clip-w: ac(249px, 150px);
        --clip-h: ac(50px, 35px);
        --y2: ac(723px, 375px);
        --y1: 0;
        clip-path: polygon(
                0 var(--y1),
                var(--y2) 0,
                87% 0,
                100% 0,
                100% calc(100% - var(--clip-h)),
                calc(100% - var(--clip-w)) 100%,
                0 100%,
                0 0
        );
        padding-top: ac(115px, 108px);
        padding-bottom: ac(80px, 50px);
        z-index: 1;
    }
}

.jobs-decor-shape {
    position: absolute;
    bottom: 0;
    transform: translateY(28%);
    left: 0;
    width: 50%;
    min-width: 375px;
    clip-path: polygon(0 0, 100% 72%, 0 100%, 0 0);

    &::before {
        content: "";
        display: block;
        padding-top: 45.9197%;
    }

    img {
        max-width: unset;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        transform: rotateY(180deg);
        object-position: center 0%;
    }
}

.partners.partners-white {
    --y1: 77px;
    --left-clip: ac(700px, 375px);
    --y2: ac(125px, 60px);
    --ytop: var(--y1);
    --ybot: var(--y2);
    background: var(--color-white);
    padding-top: var(--y1);
    -webkit-clip-path: polygon(
            var(--left-clip) var(--y1),
            100% var(--y1),
            100% calc(100% - var(--y2)),
            calc(100% - 165px) 100%,
            0 100%,
            0 0
    );
    clip-path: polygon(
            var(--left-clip) var(--y1),
            100% var(--y1),
            100% calc(100% - var(--y2)),
            calc(100% - 165px) 100%,
            0 100%,
            0 0
    );

    .cont {
        padding-top: ac(201px, 50px);
        padding-bottom: ac(100px, 50px);
    }

    .partners__cont {
        color: var(--color-dark-blue);

        h2 {
            color: var(--color-dark-blue);
        }

        p {
            color: var(--color-dark-blue);
        }

        .btn {
            color: var(--color-dark-blue);
        }
    }
}

.main:has(.insights-dark-bg:last-of-type) {
    .insights-dark-bg:last-of-type {
        padding-bottom: ac(150px, 0px);
    }
}
