.partnerships-page {
    .hero-section {
        padding-bottom: ac(250px, 120px);
    }

    .case-card {
        *, p {
            font-size: ac(15px, 14px);
            @media (max-width: 650px) {
                font-size: ac(15px, 11px, 320, 650);
            }
        }
    }

    @media (max-width: 1024px) {
        .footer__map {
            margin-top: 0;
        }
    }
}


.hero-partnerships {
    .hero-title {
        margin-bottom: 42px;
    }
}


.partnerships-box {
    margin-top: ac(-296px, -80px);

    &__inner {
        padding: 88px 0 ac(185px, 90px);
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 260px);
        grid-auto-rows: 260px;
        grid-gap: 31px;
        justify-items: center;
        align-items: center;
        @media (max-width: 1200px) {
            grid-template-columns: repeat(3, 260px);
        }
        @media (max-width: 1024px) {
            grid-template-columns: repeat(2, 260px);
        }
        @media (max-width: 650px) {
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
            grid-auto-rows: 1fr;
            grid-gap: 15px;
            .case-card {
                width: 100%;

            }
        }
    }


    &__pagination {
        margin: ac(63px, 40px) auto 0px;
    }
}


.partnerships-page .contact {

    .insights-shape {
        clip-path: polygon(0 0, 100% 62.7%, 0 100%);
        transform: translateY(-62.7%);

        &::before {
            padding-top: 35.31%;
        }
    }

    .cont {
        padding-top: ac(146px, 99px);
        padding-bottom: ac(229px, 75px);
    }

    &__head {
        margin-bottom: ac(38px, 28px);
    }
}
