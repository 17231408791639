.job-details-page {
    .hero-section {
        padding: ac(243px, 100px) 0 140px;
        .section-bg {
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: var(--color-dark-blue);
                z-index: 1;
                mix-blend-mode: multiply;
            }
            img {
                z-index: 0;
                opacity: 1;
            }
        }
    }

    .jobs {
        padding: 96px 0px 125px;


        &__cont h2 {
            margin-bottom: 34px;
        }

        &__decor {
            bottom: 0;
            clip-path: polygon(100% 62%, 0% 0%, 0 86%);
            transform: translateY(38%);
            min-height: ac(382px, 150px);
        }
    }

    .footer__map {
        margin-top: ac(95px, 0px);
    }
}

.hero-details {
    .section-bg {
        img {
            opacity: 0.07;
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
    }

    &__info {
        max-width: 522px;
        margin-right: ac(60px, 0px);
        margin-top: 14px;

        h1 {
            font-size: ac(52px, 30px);
            color: var(--color-white);
            letter-spacing: 0px;
        }

        .btn {
            color: var(--color-white);
        }
    }

    &__info-btns {
        position: relative;
        margin-top: 52px;
        padding-left: 65px;

        display: flex;

        .btn {
            max-height: 26px;
        }

        &::before {
            content: "";
            height: 1px;
            width: 267px;
            left: -213px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: #fff;
        }
    }

    &__info-box {
        margin-top: 9px;

        p {
            font-size: ac(24px, 18px);
            font-weight: 700;
            color: var(--color-light-blue);
            margin-bottom: ac(13px, 4px);
            letter-spacing: -0.7px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__descr {
        max-width: 365px;
        text-align: right;

        .hero-thumbnail {
            border-radius: 50%;
            overflow: hidden;
            width: 216px;
            max-width: 100%;
            position: relative;
            margin-bottom: 17px;
            margin-left: auto;

            &::before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        h5 {
            font-size: ac(30px, 20px);
            font-weight: 400;
            color: var(--color-white);
            margin-bottom: 13px;
            letter-spacing: 0.2px;
        }

        p {
            font-size: ac(19px, 16px);
            font-weight: 700;
            color: var(--color-light-blue);
            letter-spacing: 0px;
        }
    }

    &__descr-box {
        margin-top: 8px;

        a {
            @mixin max-line-length-one;
            display: block;

            color: var(--color-white);
            font-size: ac(19px, 16px);
            letter-spacing: -0.4px;
            margin-bottom: 5px;
            transition: 0.2s;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover {
                color: var(--color-light-blue);
            }
        }
    }

    &__descr-btns {
        margin-top: 14px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        .btn {
            margin-bottom: 7px;
            width: fit-content;
            color: var(--color-white);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.details-info {
    margin-top: ac(-125px, -70px);
    padding: ac(189px, 100px) 0 ac(150px, 70px);
    --y2: ac(57px, 35px);
    background: var(--color-white);
    clip-path: polygon(
        0 0,
        50% var(--y2),
        100% 0,
        100% calc(99% - var(--y2)),
        86% 100%,
        0 calc(106% - var(--y2))
    );
    background-color: #fff;
    padding-top: var(--y2);

    .cont {
        padding-top: ac(132px, 55px);
    }

    &__inner {
        max-width: 866px;

        /*h3 {
            font-weight: 500;
            font-size: ac(17px, 15px);
            color: var(--color-orange);
            border-bottom: 1px solid;
            padding-bottom: 8px;
        }*/

        h1 {
            font-size: ac(52px, 35px);
            border-bottom: 1px solid var(--color-orange);
            line-height: 1;
            letter-spacing: -2.6px;
            font-weight: 700;
            margin-bottom: 11px;
        }

        h2 {
            font-size: ac(44px, 31px);
            border-bottom: 1px solid var(--color-orange);
            letter-spacing: -1.6px;
            line-height: ac(53px, 35px);
            margin-bottom: 9px;
            padding-bottom: 9px;
        }

        h3 {
            font-size: ac(38px, 28px);
            color: var(--color-light-blue);
            border-bottom: 1px solid var(--color-light-blue);
            letter-spacing: -1px;
            line-height: 1.3;
            margin-bottom: 14px;
        }

        h4 {
            font-size: ac(30px, 25px);
            border-bottom: 1px solid var(--color-orange);
            letter-spacing: -0.7px;
            line-height: 1.5;
            margin-bottom: 14px;
            font-weight: 700;
        }

        h5 {
            font-size: ac(22px, 20px);
            color: var(--color-light-blue);
            border-bottom: 1px solid var(--color-light-blue);
            letter-spacing: -0.4px;
            line-height: 1.7;
            margin-bottom: 14px;
            font-weight: 700;
        }

        h6 {
            border-bottom: 1px solid var(--color-orange);
            font-size: ac(20px, 18px);
            font-weight: 700;
            letter-spacing: -0.6px;
            line-height: 1;
            line-height: min(max(calc(0.57143px + 2.52976vw), 20px), 37px);
            margin-bottom: 12px;
        }





        ul {
            margin-top: 21px;
            max-width: 800px;
            align-self: flex-start;
            li {
                &::before {
                    content: "";
                    position: absolute;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background-color: var(--color-light-blue);
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                }
            }
        }
        ol {
            align-self: flex-start;
            list-style: decimal;
            padding-left: 22px;
            margin-top: 21px;
            max-width: 800px;
            li {
                padding-left: 9px;
            }
        }

        li {
            position: relative;
            /*font-weight: 400;
            font-size: ac(17px, 15px);*/
            /*color: var(--color-dark-blue);*/
            line-height: 1.4;
            margin-bottom: 17px;
            padding-left: 32px;
            font-weight: 400;
            font-size: ac(17px, 15px);
            color: var(--color-dark-blue);
            letter-spacing: -0.5px;
        }
    }

    &__list-blocks {
        margin-top: ac(62px, 30px);
    }

    &__text-block {
        p {
            font-weight: 400;
            font-size: ac(17px, 15px);
            color: var(--color-dark-blue);
            margin-bottom: ac(18px, 9px);
            letter-spacing: -0.5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__list-blocks {
        margin-top: ac(65px, 30px);
        margin-bottom: ac(112px, 40px);
    }

    &__list-block {
        margin-bottom: ac(57px, 30px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .btn {
        margin-top: 66px;

        span {
            &::before {
                border: 1px solid var(--color-dark-blue);
            }
        }
    }

    &__social {
        margin-top: ac(69px, 30px);

        a {
            &:hover i {
                color: var(--color-blue);
            }

            i {
                color: var(--color-dark-blue);
            }
        }
    }
}

.social {
    p {
        color: var(--color-dark-blue);
        font-size: 15px;
        font-weight: 400;
    }

    &__box {
        margin-top: 17px;
        display: flex;

        a {
            margin-right: 28px;
            transition: all 0.3s;
            text-decoration: none !important;
            cursor: pointer;
            &::before {
                display: none !important;
            }

            &:last-child {
                margin-right: 0;
            }

            i {
                font-size: 24px;
                transition: all 0.3s;
            }
        }
    }
}

.job-details-page .placeholder-section {
    --y1: 0;
    --x2: ac(235px, 160px);
    --y2: 103px;
    min-height: 573px;

    .cont {
        padding: 152px 0 74px;
    }
}

@media (max-width: 600px) {
    .hero-details {
        height: 100%;

        .cont {
            padding-top: 20px;
        }

        &__inner {
            flex-direction: column;
        }

        &__info {
            max-width: 100%;
            text-align: center;
            margin-right: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;

        }

        &__info-btns {
            margin-top: 25px;
            padding-left: 0;

            &::before {
                display: none;
            }
        }

        &__descr {
            max-width: 100%;
            text-align: center;
            margin-top: 40px;

            .hero-thumbnail {
                margin-right: auto;
                max-width: 170px;
            }
        }

        &__descr-btns {
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            .btn {
                margin: 0 7px 15px !important;
            }
        }
    }
}

.job-closed-info {
    max-width: 350px;

    padding: 5px 10px;
    border: 1px solid var(--color-orange);

    color: var(--color-orange);
    background: rgba(255, 0, 0, 0.12);

    font-size: ac(14px, 12px);
    font-weight: 600;

    @mixin media 601 {
        max-width: unset;
    }

    a {
        color: var(--color-white) !important;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

