.insights-page {
    .gradient-layer {
        z-index: 1;
    }

    .footer__map {
        margin-top: ac(-45px, 0px);
    }
}


.insight {
    --y1: ac(53px, 35px);
    --y2: ac(53px, 35px);
    --ytop: var(--y1);
    --ybot: var(--y2);
    padding: ac(120px, 100px) 0 ac(60px, 30px);
    background-color: var(--color-white);
    margin-top: ac(-66px, -60px);
    clip-path: polygon(0 0, 50% var(--y1), 100% 0, 100% calc(100% - var(--y2)), 50% 100%, 0 calc(100% - var(--y2)));
    position: relative;
    z-index: 2;

    .not-found-title {
        color: var(--color-dark-blue);
    }

    &__inner {
        display: grid;
        grid-template-columns: repeat( 5, 1fr);
        grid-gap: 25px;
        flex-grow: 1;
        align-self: flex-start;
        @media (max-width: 1025px) {
            grid-gap: 36.45px;
        }
    }

    .cont {
        max-width: 100%;
        width: 1650px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        @media (max-width: 1800px) {
            width: 1375px;
            .insight__inner {
                grid-template-columns: repeat(4, 1fr);
            }
        }
        @media (max-width: 1500px) {
            width: 1067px;
            .insight__inner {
                grid-template-columns: 1fr 1fr 1fr;

            }
        }
        @media (max-width: 1200px) {
            width: 755px;
            .insight__inner {
                grid-template-columns: 1fr 1fr;

            }
        }
        @media (max-width: 850px) {
            flex-direction: column;

            width: 537px;
            .team-filters-aside {
                max-width: unset;
                width: 100%;
                margin-bottom: 36.45px;
                padding-right: 0;
            }
        }
        @media (max-width: 650px) {
            width: 100%;
            padding: 0 15px;
            .insight__inner {
                grid-template-columns: repeat(2, calc(50% - 7px));
                width: 100%;
                grid-gap: 15px;


                .insight-card {
                    width: 100%;
                    min-height: revert;
                    position: relative;
                    padding-bottom: 0;

                    &-cont{
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        padding: inherit;
                        padding-bottom: ac(10px, 2px, 320, 650);
                        height: 100%;
                    }
                    &__img{
                        flex-shrink: 0;
                        margin-bottom: 10px;
                        &::before{
                            padding-top: 42%;
                        }
                    }

                    h5{
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        font-size: ac(14px, 12px, 375, 650);
                        flex-grow: 0;
                        word-break: break-word;
                    }
                    data{
                        margin-top: auto;
                        font-size: ac(13px, 11px, 375, 650 );
                    }
                }
            }
        }

    }

    .page-pagination {
        grid-column: 1/-1;
    }
}

.insight {
    &.insight-salary {
        .cont {
            display: grid;
            place-content: center;
            width: 80.55%;

            @media (max-width: 650px){
                display: flex;
                flex-direction: column;
                padding: 0;
              }

            .insight__inner {
                grid-template-columns: repeat(4, 250px);
                grid-auto-rows: 250px;
                @media (min-width: 1800px) {
                    grid-template-columns: repeat(5, 250px);
                }
                @media (max-width: 1200px) {
                    grid-template-columns: repeat(3, 250px);
                }
                @media (max-width: 900px) {
                    grid-template-columns: repeat(2, 250px);
                    .insight-card {
                        min-height: revert;
                    }
                }
                @media (max-width: 650px){
                    grid-template-columns: repeat(2, calc(50% - 7px));
                    grid-auto-rows: revert;
                  }
            }
        }
    }
}

.page-pagination {
    display: flex;
    justify-content: space-between;
    padding: ac(80px, 35px) 0;
    width: 85px;
    margin: 0 auto;
}

.insight-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 250px;
    background: #fff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    padding: 0 0 ac(14px, 10px) 0;
    transition: all .3s;
    @media (max-width: 450px) {
        max-width: 350px;
    }

    &:hover {
        transform: scale(1.02);
    }

    h5 {
        font-size: 14px;
        letter-spacing: 0.1px;
        padding: 0 5px 0 ac(11px, 7px);
        flex-grow: 1;
        color: var(--color-dark-blue);
    }

    data {
        padding: 0 5px 0 ac(11px, 7px);
        font-size: 12px;
        letter-spacing: -0.3px;
        color: var(--color-dark-blue);
        margin-top: 10px;
    }

    &__img {
        position: relative;
        max-height: 150px;
        margin-bottom: 17px;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            padding-top: 57.69%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            background: var(--color-light-blue);
        }
    }

    .grey &__type {
        background-color: var(--color-grey);
        color: var(--color-white);

        p {
            color: var(--color-white);
        }
    }

    &__type {
        position: absolute;
        top: 9px;
        left: 8px;
        padding: 3px 12px 4px 11px;
        font-size: 12px;
        font-weight: 400;

        p {
            font-size: 12px;
            font-weight: 400;
        }
    }


    &__descr {
        padding: ac(17px, 10px) ac(9px, 7px) ac(14px, 10px) ac(11px, 7px);
    }
}

.search {

    background: linear-gradient(to right, #004d95 0%, #009ed0 100%);
    padding: 30px;

    &__inner {
        position: relative;

        h2 {
            font-size: 15px;
            color: var(--color-white);
        }

        form {
            margin-top: 7px;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            position: relative;
        }

        input {

            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid var(--color-white);
            padding: 2px 2px ac(10px, 5px) ac(21px, 10px);
            font-weight: 400;
            font-size: ac(19px, 16px);
            color: var(--color-white);
            padding-right: 25px;

            &::placeholder {
                color: rgba(255, 255, 255, 0.34);
                font-size: ac(19px, 16px);
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                transition: background-color 50000s ease-in-out 0s;
                -webkit-text-fill-color: #fff !important;
            }

            &:focus {
                border-color: var(--color-light-blue);
            }

        }

        button {
            margin-bottom: ac(-6px, -2px);
            margin-right: ac(-4px, 0px);
            transition: all .3s;
            padding-top: 2px;
            position: absolute;
            right: 0;
            top: 3px;

            &:hover i {

                color: var(--color-grey);
            }

            i {
                color: var(--color-white);
                font-size: ac(22px, 18px);
                transition: all .3s;
            }
        }
    }

    .search-filters-block {
        padding: 20px 0 0;
        background: transparent;
    }
}

.salary-page {
    .insight {
        .insight__inner {
            padding-bottom: ac(100px, 50px);
        }
    }

    .main:has(.insight + .contact) {
        .insight {
            --y2: 80px;
            --x1: ac(179px, 216px);
            -webkit-clip-path: polygon(0 0, 50% var(--y1), 100% 0, 100% calc(100% - var(--y2)), calc(100% - var(--x1)) 100%, 0 100%);
            clip-path: polygon(0 0, 50% var(--y1), 100% 0, 100% calc(100% - var(--y2)), calc(100% - var(--x1)) 100%, 0 100%);
            margin-bottom: -1px;
            padding-bottom: var(--y2);
        }
    }

    .contact.contact-dark {
        padding-bottom: ac(40px, 0px);
    }
}