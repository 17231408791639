.profile-page {

    .hero-section {
        height: unset;
        background-color: inherit;
        background-blend-mode: multiply;
        padding-top: ac(207px, 140px);

        .section-bg {

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: var(--color-dark-blue);
                mix-blend-mode: multiply;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                object-fit: cover;
                opacity: 1;
                @media (width: 1440px) {
                    object-position: 68% center;
                }

            }
        }

        .cont {
            position: relative;
            max-width: 1440px;
        }
    }

    .jobs {
        padding: 47px 0;

        .jobs__decor {
            display: none;
        }

    }

    .main:has(.jobs:last-of-type) {
        .jobs::after {
            display: none;
        }
    }


    .hero-info {

        position: relative;
        z-index: 0;

        .hero-title {
            text-align: left;

            position: relative;
            z-index: 1;
            margin-bottom: 25px;

        }

        .hero-position {
            color: var(--color-light-blue);
            padding-bottom: 40px;
            margin: 0;
            font-size: ac(30px, 25px);
            font-weight: 900;
        }

        .hero-content {
            color: var(--color-white);
            line-height: 140%;
            padding-top: 30px;

            p {
                color: var(--color-white);
                margin-bottom: 15px;
                letter-spacing: -0.45px;

            }


        }
    }

    .hero-profile-contact {

        .social {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 0;
            padding-top: 35px;
            padding-bottom: 24px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .icon-link {
                margin-right: 12px;
                transition: 0.2s;

                i {
                    color: var(--color-light-blue);
                    font-size: 24px;
                }

                &:hover {

                    color: var(--color-white);

                    i {
                        color: var(--color-white);
                    }
                }
            }

            .info-links {
                display: flex;
                flex-direction: column;
                padding: 10px 0;

                a {
                    display: inline-block;
                    color: var(--color-white);
                    font-size: ac(19px, 15px);
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    padding: 5px 0;
                    letter-spacing: -0.55px;
                    transition: 0.2s;

                    &:hover {
                        color: var(--color-light-blue);
                    }

                    /*span {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 2px;
                        background: var(--color-white);
                        height: 76%;
                    }*/

                    i {
                        color: var(--color-light-blue);
                        margin-right: 10px;
                        font-size: 22px;
                    }

                }
            }
        }

        .btn {
            color: var(--color-white);
            padding-right: 10px;
        }
    }


    .hero-content-wr {
        position: relative;
        z-index: 1;
        display: flex;
    }


    .hero-content {
        position: relative;
        flex-shrink: 0;
        padding-right: ac(0px, 10px);
        padding-top: 30px;
        max-width: 50%;
        z-index: 1;
        padding-bottom: 30px;


        &::before {
            --x1: ac(85px, 30px);
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 102%;
            height: 100%;
            box-sizing: content-box;
            padding-right: var(--x1);
            background: linear-gradient(to right, var(--color-dark-blue), var(--color-blue));
            z-index: -1;
            clip-path: polygon(0 0, calc(100% - var(--x1)) 0, 100% 50%, calc(100% - var(--x1)) 100%, 0 100%);
        }

        @media (max-width: 1100px) {
            padding: 20px 20px 30px 0;
            &::before {
                clip-path: none;
                padding: 0;
                width: 104%;
                height: 100%;
                transform: none;
            }
        }
    }

    .hero-info-desc {

        h1, h2, h3, h4, h5, h6, p, li {
            color: var(--color-white);
        }
    }

    .hero-thumbnail {
        overflow: hidden;
        width: 50%;
        height: calc(100% - 2px);
        z-index: 0;
        position: absolute;
        top: 1px;
        right: 0;

        &::before {
            content: '';
            display: block;
            padding-top: 80%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .insights.insights-dark {
        padding-top: 0;
        margin-top: 0;
        clip-path: none;
        padding-bottom: 0;

        .insights-cont-wr {
            background: var(--color-white);

            &::before {
                display: none;
            }
        }

        .insights-shape {
            --y1: ac(73px, 38px);
            top: auto;
            bottom: 0;
            clip-path: polygon(0 0, 100% var(--y1), 0 100%);
            transform: translateY(72%);

            &::before {
                padding-top: 35.611%;
            }
        }

        .cont {
            padding-top: ac(98px, 85px);
            padding-bottom: 10px;
        }
    }

    .footer__map {
        margin-top: ac(96px, 114px);
    }
}

@media (max-width: 1100px) {
    .profile-page {
        .hero-content-wr {
            flex-direction: column;
            padding-top: 50px;
        }

        .hero-content {
            max-width: unset;
        }

        .hero-thumbnail {
            position: relative;
            right: auto;
            top: auto;
            width: 80%;
            max-width: 450px;
            margin: 0 auto;
            margin-bottom: 75px;

            &::before {
                padding-top: 100%;
            }
        }


        .hero-info {
            .hero-title {
                width: 100%;
                max-width: unset;
                text-align: center;
            }

            .hero-position {
                text-align: center;
            }
        }
    }

}

@media (max-width: 1024px) {
    .profile-page {
        .insights .insights-cont-wr .insights__arrows {
            bottom: 40px;
        }
        .insights.insights-dark .insights-cont-wr{
            padding-bottom: 120px;
        }

    }
}