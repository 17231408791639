.testimonials-page {
    .hero-section {
        padding: ac(239px, 140px) 0 ac(240px, 120px);
    }

    @media (max-width: 1024px) {
        .footer__map {
            margin-top: 0;
        }
    }
}

.testimonials {
    padding: 0px 0 ac(200px, 80px);
    margin-top: ac(-210px, -80px);

    .cont {
        width: auto;
        max-width: 1076px;
        display: flex;
        justify-content: center;
        @media (min-width: 2100px) {
            max-width: 1437px;
        }
        @media (max-width: 1400px) {
            max-width: 1150px;

            .testi-card__block {
                min-height: auto;
            }
        }
        @media (max-width: 1200px) {
            max-width: 865px;
            .testimonials__grid {
                grid-template-columns: repeat(3, 280px);
            }

            .testi-card {
                height: 280px;
            }

            .testi-card__block {
                min-height: auto;

                .testi-card_content_wr .testi-card_content {
                   --line-count: 9;
                }
            }
        }
        @media (max-width: 990px) {
            max-width: 574px;
            .testimonials__grid {
                grid-template-columns: repeat(2, 280px);
            }
        }
        @media (max-width: 650px) {
            max-width: 90%;
            .testimonials__inner {
                max-width: 100%;
            }

            .testimonials__grid {
                grid-template-columns: repeat(2, 47.5%);
                grid-gap: 15px;

                .testi-card {
                    width: 100%;
                    height: auto;
                    margin: 0;

                    &__block {
                        .testi-card_content_wr {
                            
                            &::before {
                                left: -13px;
                                right: auto;
                                top: -5px;
                            }

                            &::after {
                                left: calc(100% + -16px);
                                bottom: -9px;
                            }
                        }
                    }

                }
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 350px);
        grid-gap: 31px;
        grid-auto-rows: 1fr;

        @media (max-width: 1000px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media (max-width: 651px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    &__card {
        width: 376px;
        max-width: 100%;
        margin: 0 auto;
    }

    &__pagination {
        margin: ac(49px, 40px) auto 0px;
    }
}

.testi-card {
    background: linear-gradient(to right, #009ed0 0%, #004d95 100%);
    padding: 20px 0 ac(36px, 25px) ac(38px, 29px);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 350px;
    min-height: 300px;
    padding-right: 10px;

    &__decor {
        p {
            color: var(--color-yellow);
            font-size: 37px;
            line-height: 0;
            font-weight: bold;
            letter-spacing: -0.6px;
            margin-left: 3px;
            margin-top: -5px;
        }
    }

    &__block {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        z-index: 3;
        position: relative;
        justify-content: space-between;
        flex-grow: 1;

        .testi-card_content_wr {
            position: relative;

            &::before,
            &::after {
                content: "“";
                color: var(--color-orange);
                display: block;
                font-family: Gotham;
                font-size: 37px;
                font-weight: 700;
                height: auto;
                line-height: 0;
                margin-bottom: 0;
                padding-bottom: 9px;
                padding-top: 18px;
                position: absolute;
            }

            &::before {
                right: calc(100% + 4px);
                padding-top: 15px;
            }

            &::after {
                content: "”";
                position: absolute;
                left: calc(100% - ac(34px, 25px, 650, 1920));
                bottom: -8px;
                /*transform: translate(ac(0px, -23px), ac(30px, 24px));*/
            }

            .testi-card_content {
                overflow-y: auto;
                padding-right: ac(36px, 29px);
                --line-count: 10;
                line-height: 1.2;
                max-height: calc(1.2 * var(--line-count) * 15px + 1px);
                font-size: 15px;
                @media (max-width: 1400px){
                    --line-count: 9;
                  }


                &::-webkit-scrollbar {
                    width: 4px;
                    height: 2px;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 3px;
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba(255, 255, 255, 0.5);
                    border-radius: 3px;
                    cursor: pointer;
                }
            }
        }

        p {
            color: var(--color-white);
            font-size: inherit;
            font-weight: 400;
            flex-grow: 1;
            letter-spacing: -0.5px;
            line-height: 1.2;
            text-align: justify;
        }
    }

    &__box {
        margin-top: 10px;

        h5 {
            color: var(--color-white);
            font-size: ac(22px, 20px);
            font-weight: 700;
            margin-bottom: 5px;
        }

        p {
            font-size: ac(16px, 14px);
            font-weight: 700;
        }
    }
}

.testimonials-page .contact {
    .insights-shape {
        clip-path: polygon(0 0, 100% 62.7%, 0 100%);
        transform: translateY(-62.7%);

        &::before {
            padding-top: 35.31%;
        }
    }

    .cont {
        padding-top: ac(146px, 99px);
        padding-bottom: ac(229px, 75px);
    }

    &__head {
        margin-bottom: ac(38px, 28px);
    }
}

@media (max-width: 650px) {
    .testimonials {
        .cont{
            max-width: 80.55556%;
            .testimonials__inner{
                width: 100%;
            }
            .testimonials__grid{
                grid-template-columns: 1fr;
                grid-auto-rows: 1fr;
                grid-gap: 25px;
                .testi-card {
                    padding: 0;
                    max-width: revert;
                    margin: 0 auto;
                    width: 100%;
                    height: auto;
                    min-height: 150px;
                    position: relative;
                    &__block{
                        width: 100%;
                        padding: 25px;
                        margin: 0;

                        .testi-card_content{
                            --line-count: 9;
                            line-height: 1.2;
                            max-height: calc(1.2 * var(--line-count) * 14px + 1px);
                            font-size: 14px;
                            padding-right: 16px;
                            @media (max-width: 500px){
                                --line-count:7;
                            }
                            @media (max-width: 370px){
                                --line-count:5;
                            }
                            p{
                                font-size: inherit;
                                line-height: inherit;
                            }
                            &_wr{
                                &::before, &::after{
                                    font-size: 22px;
                                }
                            }
                        }
                    }
                    &__box{
                        margin-top: 20px;
                        h5{
                            font-size: ac(13px, 12px, 320, 375);
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 450px){
    .testimonials .cont .testimonials__grid{
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
    }

  }