
input, textarea {
    border-radius: 0;

}

.checkbox-entry {
    position: relative;
    display: inline-block;
    cursor: pointer;


    &.radio {
        span {
            font-size: 15px;
            line-height: 20px;
            padding-left: 17px;

            &::before {
                border-radius: 50%;
                top: 4px;
            }
        }
    }

    input {
        display: none;

        &:checked + span {
            &::before {
                background-color: var(--color-light-blue);
            }
        }
    }

    span {
        position: relative;
        display: block;
        font-size: 12px;
        line-height: 1.4;
        color: var(--color-dark-blue);
        padding-left: 22px;
        transition: color 0.2s;

        &:hover {
            color: var(--color-light-blue);

            &::before {
                border-color: var(--color-light-blue);
            }
        }

        &::before {
            position: absolute;
            content: '';
            top: 2px;
            left: 0;
            width: 12px;
            height: 12px;
            border: 1px solid var(--color-dark-blue);
            background-color: var(--color-white);
            transition: all 0.3s;
        }

        a {
            position: relative;
            display: inline-block;
            font-weight: 500;
            color: var(--color-light-blue);

            &:hover {
                &::before {
                    right: auto;
                    left: 0;
                    width: 100%;
                }
            }

            &::before {
                position: absolute;
                content: '';
                bottom: 0;
                right: 0;
                width: 0;
                height: 1px;
                background-color: var(--color-light-blue);
                transition: all 0.3s;
            }
        }
    }

}

.form {
    position: relative;
    display: block;
    width: 100%;

    &__block {
        display: grid;
        grid-column-gap: ac(40px, 35px);
        grid-row-gap: 35px;
        margin-bottom: ac(38px, 24px);
        grid-template-columns: repeat(3, 1fr);
        @media (max-width: 1200px) {
            grid-template-columns:1fr;
        }
    }

    &__item {
        position: relative;
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        &.invalid {
            label {
                color: var(--color-orange) !important;
            }

            input, textarea {
                border-color: var(--color-orange) !important;
            }

            .error-message {
                display: block;
            }

            .select-label {
                color: var(--color-orange) !important;
            }

            .select2-container {
                border-color: var(--color-orange) !important;
            }
        }

        &:focus-within {
            outline: none;

            label {
                color: var(--color-dark-blue);
            }

            input {
                border-color: var(--color-light-blue);

                &::placeholder {
                    color: var(--color-black);
                }
            }
        }

        .select-wr {
            width: 100%;

            select {
                display: none;
            }
        }

        &.form__textarea {
            .error-message {
                margin-top: ac(-12px, -26px);
            }
        }


        label {
            display: block;
            width: 100%;
            font-size: 15px;
            line-height: 1.2em;
            font-weight: 700;
            letter-spacing: 0.2px;
            color: var(--color-dark-blue);
            margin-bottom: ac(10px, 11px);
            transition: all 0.3s;
        }

        input, textarea {
            border: none;
            outline: none;
            width: 100%;
            height: 40px;
            font-size: 19px;
            color: var(--color-black);
            background-color: transparent;
            padding: 0 20px 12px 0;
            border-bottom: 2px solid var(--color-dark-blue);
            border-radius: initial;
            transition: all 0.3s;
            font-family: var(--font-main);
            font-weight: 400;
            @mixin transparent-bg-input var(--color-black);

            &::placeholder {
                color: rgba(16, 22, 31, 0.5);
                transition: all 0.3s;
            }

            &:focus-visible {
                outline: none;
            }
        }

        textarea {
            resize: none;
            overflow: hidden;

            &:focus {
                border-color: var(--color-light-blue);

                &::placeholder {
                    color: rgba(16, 22, 31, 1);
                }
            }
        }

        .error-message {
            display: none;

        }

        &_fluid {
            grid-column: 1/-1;
        }
    }


    &__textarea {
        grid-column: 1/ -1;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 1024px) {
            flex-direction: column;
        }
    }

    &__bottom-left {
        display: flex;
        flex-direction: column;
        padding-right: 35px;
        @media (max-width: 1024px) {
            padding-right: 0;
        }

        .checkbox-entry {
            width: 100%;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                padding-left: ac(20px, 21px);
            }
        }

        & > label {
            font-size: 12px;
            line-height: 18px;

            a {
                color: var(--color-light-blue);
                text-decoration: underline;
                transition: color 0.3s;

                &:hover {
                    color: var(--color-orange);
                }
            }
        }
    }

    &__bottom-right {
        display: flex;

        img {
            width: ac(300px, 173px);
            height: auto;
            margin-right: 38px;
        }

        .btn {
            width: max-content;
            padding-right: ac(18px, 22px);
            align-self: flex-start;
        }
    }
}




.error-message {
    position: absolute;
    right: 0;
    top: 0;
    padding: ac(5px, 2px) 10px;
    box-shadow: 0 0 5px 0 rgba(255, 87, 66, 0.3);
    border-radius: 5px;
    background: var(--color-white);
    color: var(--color-dark-blue);
    @extend .slide-up;
    font-size: ac(14px, 12px);
}

.checkbox-entry {
    &.invalid {
        * {
            color: var(--color-orange) !important;
        }

        a::before {
            background: var(--color-orange);
        }
    }
}

.popup-form.apply-form {
    width: 100%;
    max-width: 500px;

    .pc-inner {
        grid-template-columns: 1fr;
    }
}

.form__item_fluid_soc {
    margin-top: 27px;

    .form__item {
        flex: 1;
    }
    .or-label {
        font-size: 20px;
        font-weight: 700;
        padding: 0 30px 8px;
        text-transform: uppercase;
    }

    @media (min-width: 1201px) {
        align-items: flex-end;
        display: flex;
        grid-column: 1/-1;


        .upload-btn{
            align-self: end;
            padding-bottom: 0;
            label{
                border-color: var(--color-dark-blue);
                margin-bottom: 0;
            }
        }
    }
    @media (max-width: 1200px){
        .or-label{
            font-size: 20px;
            font-weight: 700;
            padding: ac(40px, 25px) 0 0;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
        }
        .upload-btn{
            width: 100% !important;
            label{
                border-color: var(--color-dark-blue);
            }
        }

    }
}

.form__item{
    &.col-span-1, &.col-span-2{
        @media (max-width: 1200px){
            grid-column: 1/-1!important;
          }
    }
}