.btn {
    position: relative;
    display: inline-flex;
    align-items: center;

    min-height: 26px;
    font-size: ac(15px, 11px);
    color: var(--color-dark-blue);
    padding-right: 18px;
    cursor: pointer;
    letter-spacing: -0.5px;
    font-weight: normal;
    text-transform: uppercase;


    &::before, &::after {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: calc(100% - 13px);
        height: 100%;
        background-image: linear-gradient(to right, transparent, var(--color-light-blue));
        transition: all 0.3s;
    }

    &::after {
        opacity: 0;
        visibility: hidden;
        background-image: linear-gradient(to left, transparent, var(--color-light-blue));
    }

    &:hover {
        &::before {
            opacity: 0;
            visibility: hidden;
        }

        &::after {
            opacity: 1;
            visibility: visible;
        }
    }

     span {
         display: block;
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
        color: inherit;
        position: relative;
        z-index: 1;
        width: 100%;
        padding-left: 36px;
         * {
             padding: 0!important;
             margin: 0!important;
             color: inherit!important;
             display: inline!important;
             width: revert!important;
             max-width: unset!important;
         }

        &::before {
            position: absolute;
            z-index: 1;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            border: 1px solid var(--color-white);
            background-color: var(--color-orange);
        }
    }

    &.btn-blue {
        color: var(--color-white);

        &::before, &::after {
            background-image: linear-gradient(to right, transparent, var(--color-blue));
        }

        &::after {
            opacity: 0;
            visibility: hidden;
            background-image: linear-gradient(to left, transparent, var(--color-blue));
        }


        &:hover {
            &::before {
                opacity: 0;
                visibility: hidden;
            }

            &::after {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.btn-reverse {
        padding-right: 0 !important;
        justify-content: flex-end;

        &::before {
            right: 6px;
            background-image: linear-gradient(to left, transparent, var(--color-blue));
        }

        &::after {
            right: 6px;
            background-image: linear-gradient(to right, transparent, var(--color-blue));
        }

        span {
            padding-left: 20px;
            padding-right: 34px;

            &::before {
                left: auto;
                right: 0;
            }
        }
    }

    &.btn-black{
        color: var(--color-black)!important;
    }
}

.swiper-btn {
    width: 35px;
    height: 35px;
    border: 1px solid var(--color-black);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--color-black);
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s;

    i {
        color: inherit!important;
    }

    &.swiper-btn-white {
        border: 1px solid var(--color-white);
        color: var(--color-white);
        &:hover{
            background: var(--color-white);
            border-color: var(--color-black);
            color: var(--color-black) !important;
        }
    }

    &:hover {
        background: var(--color-light-blue);
        border-color: var(--color-light-blue));
        color: var(--color-light-blue));
    }
    &.swiper-button-disabled{

        opacity: 0.5;
        pointer-events: none;
    }
    &.swiper-button-lock{
        display: none;
    }
}

.swiper-buttons{
    display: flex;
    justify-content: space-between;
    width: 85px;
    margin: 0 auto;
    display: none!important;
}
