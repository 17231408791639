.job-search-page {


    .jobs__bottom {
        padding-right: ac(4px, 0px);
    }
}

.hero-search {
    padding-bottom: ac(150px, 100px);

    .hero-title {
        margin-bottom: 24px;
    }

    .hero-nums {
        text-align: center;
        color: var(--color-white);
        font-size: ac(52px, 25px);
        margin-bottom: ac(33px, 15px);
        font-weight: 900;
    }
}

.job-search-page {
    .hero-section {
        min-height: 50vh;
    }
}

.job-board {
    padding: ac(123px, 70px) 0 120px;
    position: relative;

    @mixin media 901 {
        padding-top: 0;

    }

    .cont {
        width: 90%;
        max-width: 1220px;
        @media (max-width: 901px) {
            width: 80.55556%;
        }
    }

    .decor {
        position: absolute;
        top: ac(-214px, -99px);
        left: 0;
        clip-path: polygon(100% 55%, 0 15%, 0 78%);
        max-width: ac(723px, 380px);
        min-height: ac(252.47px, 130px);

        img {
            object-fit: cover;
        }

        @mixin media 901 {
            display: none;
        }
    }

    &__cont {
        display: flex;
        justify-content: space-between;
    }


    .job-board__filter-label-word {
        position: relative;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding-left: 9px;
        align-self: flex-start;
        margin-bottom: 8px;

        .job-board__filter-word span:hover {
            color: var(--color-light-blue);
        }

        &.active:not(.open) {
            .job-board__filter-word {
                .filters-toggle {
                    &::before {
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background: var(--color-orange);
                    }

                    &::after {
                        opacity: 0;
                    }
                }
            }
        }

        &.open {
            .job-board__filter-word {
                .filters-toggle {
                    &::after {
                        opacity: 0;
                    }
                }
            }
        }

        &.open.active {
            .filters-toggle {
                &::before {
                    opacity: 0;
                }
            }

            .job-board__filter-word {
                &::before {
                    opacity: 1;
                }
            }
        }

        input:checked {
            .job-board__filter-word {
                .filters-toggle {
                    &::before {
                        background: var(--color-light-blue);
                    }
                }
            }
        }
    }

    .job-board__filter-word {
        position: relative;
        display: flex;
        width: 100%;
        color: var(--color-white);

        &:hover {
            color: var(--color-light-blue);
        }

        &::before {
            content: "";
            width: 7px;
            height: 7px;
            background: var(--color-orange);
            position: absolute;
            right: calc(100% + 10px);
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            opacity: 0;
            trasition: opacity 300ms;
        }

        span {
            color: inherit;
            transition: color 0.2s;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
        }

        i {
            /* color: var(--color-orange); */
            font-style: normal;
            display: block;
            flex-shrink: 0;
            padding-left: 5px;
            align-self: flex-start;
        }

        .filters-toggle {
            width: 27px;
            height: 100%;
            align-self: center;
            flex-shrink: 0;
            position: absolute;
            right: 100%;
            top: 0;

            &::before,
            &::after {
                content: "";
                display: block;
                width: 10px;
                height: 2px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: var(--color-white);
                transition: 0.2s;
            }

            &::after {
                width: 2px;
                height: 10px;
            }

            &:hover {
                &::after,
                &::before {
                    background: var(--color-light-blue);
                }
            }
        }
    }

    .job-board__filter-checkbox-word {
        position: absolute;
        z-index: -1;
        opacity: 0;
        clip: rect(0 0 0 0);
    }

    .job-board__filter-checkbox-word:checked ~ .job-board__filter-word {
        color: var(--color-light-blue);

        i {
            color: var(--color-orange);
        }

        &::before {
            opacity: 1;
        }
    }

    .job-board__filter-checkbox-word:checked ~ .job-board__filter-word:hover {
        opacity: 0.8;
    }

    .job-board__filter-label {
        position: relative;
        display: flex;
        cursor: pointer;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .job-board__filter-checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
        clip: rect(0 0 0 0);
    }

    .job-board__filter-checkbox ~ .job-board__filter-style::before {
        .job-board__filter-checkbox {
            opacity: 0;
            position: absolute;
        }
    }

    .job-board__filter-checkbox ~ .job-board__filter-style::before {
        content: "\2713";
        position: absolute;
        text-align: center;
        color: transparent;
        top: 4px;
        line-height: 1em;
        width: 1em;
        height: 1em;
        border: 2px inset var(--color-white);
        border-radius: 0.2em;
        margin-right: 10px;
        display: inline-block;
    }

    .job-board__filter-checkbox:checked ~ .job-board__filter-style::before {
        color: var(--color-white);
        border: 2px solid var(--color-white);
    }

    .job-board__filter-label-text {
        padding-left: 25px;
    }

    .beefup.is-open {
        span {
            position: relative;

            &::after {
                transform: rotate(0deg);
            }
        }
    }

    .beefup__head {
        padding-bottom: 6px;
        border-bottom: 2px solid;
        border-image-source: linear-gradient(to right, #002c5a, #ff5742);
        border-image-slice: 1;

        span {
            position: relative;
            transition: all 0.4s;
            font-size: 19px;
            font-weight: 700;

            &::before {
                content: "";
                position: absolute;
                width: 10px;
                height: 3px;
                background-color: #fff;
                right: -15px;
                top: 0;
                bottom: 0;
                margin: auto;
                transition: all 0.3s;
            }

            &::after {
                content: "";
                position: absolute;
                width: 10px;
                height: 3px;
                background-color: #fff;
                right: -15px;
                top: 0;
                bottom: 0;
                margin: auto;
                transform: rotate(90deg);
                transition: all 0.3s;
            }
        }
    }

    .beefup__head > button {
        border: none;
    }

    &__filters {
        max-width: 345px;
        width: 100%;
        flex-shrink: 0;
        font-family: Gotham;
        font-size: 15px;
        color: var(--color-white);
        margin-right: ac(30px, 15px);

        & > * {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }

    &__results {
        width: 77%;
        margin-right: ac(-15px, 0px);
    }

    &__results-grid {
        flex-shrink: 0;
        display: grid;
        grid-template-columns: repeat(3, 280px);
        grid-gap: 31px;
        justify-content: center;

        @media (max-width: 1350px) {
            grid-template-columns: repeat(2, 280px);
        }
        @media (max-width: 1010px) {
            grid-template-columns: 280px;
            margin: 0 auto;
            justify-content: center;
        }
        @media (max-width: 768px) {
            grid-template-columns: 230px 230px;
        }
        @media (max-width: 650px) {
            grid-template-columns: 375px;
            grid-gap: 15px;
        }
        @media (max-width: 420px) {
            grid-template-columns: 1fr;
        }

    }

    &__pagination {
        margin: 23px auto 0;
    }
}

.job-board + .subscribe {
    margin-top: ac(-100px, 0px);
    z-index: 1;
}

.pagination {
    display: flex;
    justify-content: space-between;
    max-width: 85px;

    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border: 1px solid #fff;
        border-radius: 50%;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.03);
        }

        i {
            color: #fff;
        }
    }
}

.beefup.job-board__filter-accordion {
    margin: 0;
    border: none;
    border-radius: 0;
    position: relative;

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    .beefup__head {
        padding: 0;

        &::after {
            display: none;
        }

        button {
            width: 100%;
            margin: 0;

            span {
                transition: color 0.2s 0s;

                &::before,
                &::after {
                    transition: background-color 0.2s 0s;
                }
            }

            &:hover {
                color: var(--color-light-blue);

                span {
                    &::before,
                    &::after {
                        background-color: var(--color-light-blue);
                    }
                }
            }
        }
    }

    .beefup__body {
        padding: 14px 0 0;
        display: none;
    }
}

.subscribe {
    --x1: ac(723px, 375px);
    --y1: ac(93px, 50px);
    --y2: ac(103px, 50px);
    --y3: ac(96px, 50px);
    clip-path: polygon(
            var(--x1) var(--y1),
            100% var(--y2),
            100% calc(100% - var(--y3)),
            50% 100%,
            0 calc(100% - var(--y3)),
            0 0
    );
    position: relative;
    background-color: var(--color-white);
    padding-top: var(--y1);
    padding-bottom: var(--y3);
    width: 100%;
    margin-top: -100px;

    .cont {
        padding-top: 159px;
        padding-bottom: 60px;
        position: relative;
        z-index: 1;
    }

    .decor {
        position: absolute;
        left: 0;
        top: 0;
        width: var(--x1);
        clip-path: polygon(0 0, 100% var(--y1), 0% 100%, 0 0);
        z-index: 0;

        &::before {
            content: "";
            display: block;
            padding-top: 45.81%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 0 2%;
        }
    }

    &__inner {
        max-width: 760px;
        margin: 0 auto;

        h3 {
            font-size: ac(40px, 25px);
            font-weight: 700;
            color: #002c5a;
            text-align: center;
        }
    }

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: ac(53px, 30px);
        margin-left: ac(7px, 0px);

        label {
            p {
                color: #002c5a;
                font-weight: 700;
                font-size: 15px;
            }
        }

        .btn {
            max-width: ac(163px, 130px);
            width: 100%;
            margin-left: auto;
            margin-top: 27px;
        }

        input {
            max-width: 100%;
            width: 100%;
            border-bottom: 1px solid #002c5a;
            padding: 2px 2px ac(13px, 7px) ac(21px, 12px);
            color: #002c5a;
            font-weight: 400;
            font-size: ac(19px, 16px);
            margin-top: 8px;

            &::placeholder {
                color: rgba(0, 44, 90, 0.37);
                font-weight: 400;
                font-size: ac(19px, 16px);
            }
        }
    }
}

@media (max-width: 900px) {
    .job-board {
        &__cont {
            flex-direction: column;
        }

        &__filters {
            max-width: 100%;
            margin-bottom: 50px;
        }

        &__results {
            width: 100%;
        }
    }
}
