.page-builder {
    .joint-team-content {
        padding: 135px 0 0;
    }

    .section-approach.section-approach--to-solution .section-approach-content {
        padding-top: ac(100px, 30px);
    }

    .team-swiper-section.team-swiper-section-white {
        --bg-color: #fff;
        background: var(--bg-color);
    }

    .section-bg-initialized {
        background-color: var(--bg-color);
    }

    .insight {
        margin-top: 0;
    }

    .join-team-section {
        --bg-color: var(--color-dark-blue);
        padding-bottom: 0 !important;

        .join-cont-wr {
            background-color: var(--bg-color);
            padding-bottom: var(--pb-initialized);
        }
    }

    .hero-section {
        /* padding-bottom: ac(100px, 80px); */
        padding-bottom: 20px;

        .section-bg {
            background: var(--bg-color);
        }
    }

    .hero-section + .contact {
        margin-top: ac(50px, 35px);
    }

    .hero-section + .sectors-section {
        --y1: ac(50px, 35px);
        --y2: 0px;
        --x1: 0px;
        padding-top: var(--y1);

        .swiper {
            padding-top: ac(80px, 50px);
            padding-bottom: 0;
        }

        .section-bg-initialized {
            clip-path: polygon(0 0, 50% var(--y1), 100% 0, 100% calc(100% - var(--y2)), calc(100% - var(--x1)) 100%, 0 100%);
        }

        &.last-section-initialized {
            --y2: 50px;
            --x1: 160px;
        }
    }


    .client-page {
        & + .sectors-section {
            padding-top: 0;
        }
    }

    /*h1 {
        margin-bottom: ac(35px, 25px) !important;
    }

    h2 {
        margin-bottom: ac(30px, 20px);
    }*/

    .hero-section {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        a,
        p,
        li {
            color: var(--color-white);
        }

        a:not([class]) {
            text-decoration: underline;
            transition: color 0.2s;

            &:hover {
                color: var(--color-light-blue);
            }
        }
    }

    .hero-motto {
        text-align: revert;
        margin-bottom: ac(50px, 35px);
    }

    .hero-title {
        text-align: revert;

        h1,
        h2,
        h3 {
            margin-bottom: 0 !important;
        }
    }

    .join-team-thumbnail {
        display: block;
    }

    .hero-blog-details {

        padding: ac(120px, 60px) 0;
        .hero-title{
            h1{
                font-size: inherit;
            }
        }

        &:first-child{
            min-height: 100vh;
            justify-content: flex-end;
            padding-bottom: 120px;
        }



        .hero-section {
            padding: 120px 0;
        }

        .hero-blog-details__box {
            p {
                font-size: 15px;
                color: var(--color-white);
                font-weight: normal;
                letter-spacing: -0.5px;
                line-height: 140%;
            }
        }

        .hero-blog-details__inner {
            .hero-title {
                margin-bottom: ac(48px, 38px);
            }
        }

    }

    .section.about {
        z-index: 2;
        overflow: visible;
        margin-bottom: ac(161px, 1px);
        background: var(--bg-color);
    }

    .section-approach {
        margin-bottom: 145px;
        --bgcolor: red;

        .approach-cont-wr {
            @media (max-width: 768px) {
                background: var(--bg-color);
                padding-bottom: var(--pb-initialized);
            }
        }
    }

    .jobs-section-white.one-to-one-initialized {
        .cont {
            padding-bottom: ac(100px, 30px);
        }
    }

    .join-team-section {
        img {
            object-position: center;
        }
    }

    .hiring-section {
        --bg-color: #fff;
        background-color: var(--bg-color);
    }

    .nutshell-section:not(.nutshell-section-dark) {
        margin-bottom: ac(148px, 80px);
        --bg-coolor: #fff;

        .cont {
            padding-bottom: 87px;
        }

        .section-clip-path {
            background-color: var(--bg-color);
        }
    }

    .sectors-section {
        padding: ac(89px, 50px) 0 ac(100px, 70px);
    }

    .sectors-section:last-of-type {
        z-index: 0;
        padding: ac(89px, 50px) 0 ac(100px, 70px);
        --y1: 0px;
        --y2: 50px;
        --x1: 0px;
        --ytop: var(--y1);
        --ybot: var(--y2);
        clip-path: polygon(
                0 var(--y1),
                calc(100% - var(--x1)) var(--y1),
                100% 0,
                100% calc(100% - var(--y2)),
                50% 100%,
                0 calc(100% - var(--y2))
        );
        padding-top: var(--y1);
        padding-bottom: var(--y2);
        background: var(--bg-color);

        .sectors-swiper,
        .sectors-rows {
            padding: ac(100px, 80px) 0 ac(100px, 50px);
        }
    }

    .hero-section + .sectors-section {
        &:last-of-type {
            z-index: 0;
            padding: ac(89px, 50px) 0 ac(100px, 70px);
            --y1: 50px;
            --y2: 50px;
            --x1: 210px;
            --ytop: var(--y1);
            --ybot: var(--y2);
            clip-path: polygon(
                    0 var(--y1),
                    calc(100% - var(--x1)) var(--y1),
                    100% 0,
                    100% calc(100% - var(--y2)),
                    50% 100%,
                    0 calc(100% - var(--y2))
            );
            padding-top: var(--y1);
            padding-bottom: var(--y2);
            background: var(--bg-color);

            .sectors-swiper,
            .sectors-rows {
                padding: ac(100px, 80px) 0 ac(100px, 50px);
            }
        }
    }

    .feedback__decor {
        display: none;
    }

    .feedback.different-section {
        --bg-color: #ffffff;
        background-color: var(--bg-color);
        margin: ac(50px, 35px) 0;

        .cont {
            padding-top: ac(53px, 20px);
            padding-bottom: ac(100px, 20px);
        }

        .feedback__cont {
            padding-top: 50px;
            padding-bottom: 24px;
        }
    }

    .feedback {
        padding: ac(60px, 35px) 0;

        .feedback__cont {
            padding-top: ac(7px, 0px);
        }

        .feedback__card-arrow {
            padding-bottom: ac(14px, 0px);
        }

        &.feedback-white {
            --bg-color: #ffffff;
            background: var(--bg-color);
        }
    }

    .case-cards-section {
        padding: ac(50px, 40px) 0 ac(57px, 30px);

        &:last-of-type {
            padding-bottom: ac(110px, 47px);
        }
    }

    .jobs {
        padding: ac(95px, 50px) 0 ac(116px, 50px);

        &::after {
            display: none;
        }

        &__cont {
            h2 {
                padding-right: 50px;
            }
        }
    }

    .main:has(.jobs + .team-swiper-section) {
        .jobs {
            @media (max-width: 550px) {
                &::after {
                    display: none;
                }
            }
        }

        .jobs + .team-swiper-section {
            margin-top: calc(var(--y1) * -1);
            @media (max-width: 550px) {
                margin-top: 0;
            }
        }
    }

    .insights.insights-dark {
        margin-bottom: ac(80px, 35px);
        z-index: 3;
    }

    .contact {
        margin-top: 0;
        padding-top: 0;

        .section-clip-path {
            --y1: 80px;
            -webkit-clip-path: polygon(
                    0 0,
                    calc(100% - 160px) 0,
                    100% var(--y1),
                    100% calc(100% - var(--y2)),
                    50% 100%,
                    0 calc(100% - var(--y2))
            ) !important;
            clip-path: polygon(
                    0 0,
                    calc(100% - 160px) 0,
                    100% var(--y1),
                    100% calc(100% - var(--y2)),
                    50% 100%,
                    0 calc(100% - var(--y2))
            ) !important;
            @media (max-width: 1024px) {
                --y1: 40px;
            }
            @media (max-width: 768px) {
                --y1: 0;
            }

            &::before {
                display: none;
            }
        }

        .contact-section-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            background: var(--bg-color);

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .partners {
        padding: 55px 0 50px;

        &__cont {
            color: var(--color-white);
        }
    }

    .partners.partners-white {
        margin: 50px 0;
        --bg-color: #ffffff;
        background-color: var(--bg-color);

        .cont {
            padding-top: ac(226px, 50px);
            padding-bottom: ac(160px, 50px);
        }
    }

    .total {
        margin: 50px 0;

        &:not(.total-dark) {
            --bg-color: #fff;
            background: var(--bg-color);
        }
    }

    .work-at-section {
        --y1: ac(56px, 35px);
        --y2: ac(74px, 40px);
        clip-path: polygon(
                0 0,
                50% var(--y1),
                100% 0,
                100% calc(100% - var(--y2)),
                50% 100%,
                0 calc(100% - var(--y2))
        );
        margin-bottom: ac(57px, 40px);

        &:not(.work-at-section-dark) {
            --bg-color: #fff;

            .section-clip-path {
                background: var(--bg-color);
            }
        }
    }

    .insights {
        margin: 94px 0 ac(100px, 0px);

        &:last-of-type {
            margin-bottom: 0;
        }

        .cont {
            padding-top: ac(100px, 110px);
        }

        &:not(.insights-dark-bg) {
            --bg-color: #fff;

            .insights-cont-wr {
                &::before {
                    background-color: var(--bg-color);
                }
            }
        }

        &.insights-dark {
            --y1: ac(236px, 50px);
            margin-top: 0;
        }
    }

    .nutshell-section {
        .insights-shape {
            --y1: ac(218px, 72.9px);

            &::before {
                padding-top: 42%;
            }

            img {
                object-position: left 20%;
            }
        }

        .nutshell-section-title {
            padding-bottom: 23px;
        }

        .nutshell-section-motto {
            max-width: 34%;

            & > div,
            p {
                font-size: 15px;
                line-height: 140%;
            }
        }

        &-list {
            grid-row-gap: ac(53px, 20px);
        }

        .nutshell-item-title {
            padding-bottom: 16px;
        }
    }

    .footer__map {
        margin-top: 0;
    }

    .section-approach:last-of-type {
        margin-bottom: 0;
    }
}

.sector-team-section {
    padding: 0;

    .cont {
        padding: ac(107px, 50px) 0;
    }

    h2 {
        padding-bottom: ac(43px, 35px);
        width: 100%;
        text-align: center;
        color: var(--color-white);
    }

    .team-list {
        padding: 0;
        margin: 0;

        .team-person-card {
            margin-right: 13px;
            margin-bottom: 14px;
        }

        &:nth-of-type(odd) {
            margin-right: 11.2%;
        }

        &:nth-of-type(even) {
            margin-left: 11.2%;
        }
    }

    .team-section-description {
        width: 49.2%;
        margin-left: auto;
        margin-right: 0;
        text-align: left;
        text-align: left;
        align-items: flex-start;
        padding-top: ac(48px, 38px);

        * {
            color: var(--color-white);
        }

        p {
            line-height: 140%;
            margin-bottom: 27px;
            letter-spacing: -0.45px;
        }
    }
}

.work-at-section.work-at-section-dark {
    padding: ac(50px, 40px) 0 ac(58px, 45px);
    background: transparent;
    margin-bottom: 0;
    clip-path: none;

    .cont {
        padding: 0;
    }

    .work-at-section-title {
        color: var(--color-white);
        color: var(--color-white);
    }

    .t-name {
        color: var(--color-white);
    }

    .work-at-card-content {
        * {
            color: var(--color-white);
        }
    }
}

.feedback.feedback-white {
    --x1: ac(700px, 375px);
    --y1: ac(77px, 35px);
    --y2: ac(125px, 60px);
    --ytop: var(--y1);
    --ybot: var(--y2);
    background: var(--color-white);
    margin: ac(60px, 30px) 0;
    -webkit-clip-path: polygon(
            var(--x1) var(--y1),
            100% var(--y1),
            100% calc(100% - var(--y2)),
            calc(100% - 165px) 100%,
            0 100%,
            0 0
    );
    clip-path: polygon(
            var(--x1) var(--y1),
            100% var(--y1),
            100% calc(100% - var(--y2)),
            calc(100% - 165px) 100%,
            0 100%,
            0 0
    );
    padding-top: var(--y1);
    padding-bottom: var(--y2);

    .cont {
        padding: ac(192px, 50px) 0 ac(46px, 35px);
    }

    .feedback__swiper:before {
        background: var(--color-white);
    }

    .feedback__cont {
        * {
            color: var(--color-dark-blue);
        }
    }
}

.total.total-dark {
    clip-path: none;
    background: transparent;
    padding: 55px 0;
    margin: 0;

    .cont {
        padding: 0;
    }

    .total__title {
        h2 {
            color: var(--color-white);
        }
    }

    .total__item {
        color: var(--color-white);
        font-size: ac(15px, 14px);
        line-height: 1.4;

        h4 {
            color: var(--color-white);
        }

        p {
            color: var(--color-white);
        }
    }
}

.team-swiper-section {
    padding: ac(64px, 124px) 0 ac(140px, 30px);

    h2 {
        color: var(--color-white);
        padding-bottom: ac(40px, 30px);
        margin-bottom: 0;
    }

    .team-swiper {
        overflow: visible;
    }

    .team-person-card {
        width: 100%;
        max-width: unset;
        margin-bottom: 0;
    }

    .teammate-list-wr {
        padding-top: 15px;
    }
}

.team-swiper-section.team-swiper-section-blue {
    color: var(--color-white);
}

.page-builder {
    .team-swiper-section + .insights {
        @media (max-width: 414px) {
            padding-top: 20px;
        }
    }
}

.team-swiper-section.team-swiper-section-white {
    --x1: ac(723px, 375px);
    --x2: ac(160px, 120px);
    --y1: ac(93px, 50px);
    --y2: ac(85px, 70px);
    --ytop: var(--y1);
    --ybot: var(--y2);
    background: var(--color-white);
    clip-path: polygon(
            0 0,
            var(--x1) var(--y1),
            100% var(--y1),
            100% calc(100% - var(--y2)),
            calc(100% - var(--x2)) 100%,
            0 100%
    );
    padding-top: var(--y1);
    padding-bottom: calc(var(--y2) - 13px);

    h1,
    h2 {
        color: var(--color-dark-blue);
    }

    .cont {
        position: relative;
        z-index: 1;
        padding: ac(66px, 139px) 0 ac(0px, 30px);
    }

    .insights-shape {
        background: var(--color-white);
        width: var(--x1);
        clip-path: polygon(0 0, 100% var(--y1), 0 100%);
        z-index: 0;
    }
}

.nutshell-section.nutshell-section-dark {
    .cont {
        padding: ac(142px, 75px) 0 50px;
    }

    .section-clip-path {
        background: transparent;
        clip-path: none;
        padding: 0;
    }

    .nutshell-section-list {
        color: var(--color-white);

        h1,
        h2,
        h3 {
            margin-bottom: ac(25px, 10px);
            color: var(--color-green);
        }
    }

    .nutshell-section-motto {
        p,
        h2,
        a,
        li,
        h3,
        & > div {
            color: var(--color-white);
        }

        a {
            transition: 0.2s;
            color: var(--color-light-blue);

            &:hover {
                color: var(--color-green);
            }
        }
    }

    .nutshell-item-info {
        color: var(--color-white);

        p {
            color: var(--color-white);
        }
    }
}

.insights.insights-dark-bg {
    background: transparent;
    clip-path: none;
    padding: ac(66px, 30px) 0 66px;
    margin-top: 0;
    margin-bottom: 0;
    clip-path: none;

    .insights-shape {
        display: none !important;
    }

    .cont {
        padding: 0;
    }

    .insights-cont-wr {
        padding: 0;
        @media (max-width: 1025px) {
            padding-bottom: 80px;
            .insights__arrows {
                bottom: 0;
            }
        }

        &::before {
            display: none;
        }
    }

    .insights__swiper {
        &::before {
            background: var(--color-dark-blue);
        }
    }

    .insights__cont {
        color: var(--color-white);

        p,
        h1,
        h2,
        h3,
        li,
        span,
        a {
            color: inherit;
        }
    }
}

@media (max-width: 768px) {
    .page-builder {
        .join-team-section .cont {
            padding-top: 20px;
            flex-direction: column;
            align-items: stretch;
        }

        .join-team-thumbnail {
            position: relative;
            left: auto;
            top: auto;
            bottom: auto;
            right: auto;
            width: 500px !important;
            max-width: 100% !important;
            margin: 0 auto !important;
            clip-path: none !important;

            &::before {
                content: "";
                display: block;
                padding-top: 70%;
            }
        }

        .joint-team-content {
            max-width: unset !important;
            width: 100% !important;
            padding: 30px 0 !important;
        }

        .section-approach + .insights-dark-bg {
            padding-top: 80px;
        }
    }

    .join-team-section-dark {
        .cont {
            padding: 0 !important;
        }
    }

    .join-team-section.join-team-section-page-builder.join-team-section-dark {
        padding-bottom: 0;
    }
}

@media (max-width: 650px) {
    .page-builder {
        .prev-hidden-initialized {
            .bottom-triangle {
                display: none;
                --ybot: 0px !important;
            }
        }

        .hidden-initialized + .team-swiper-section {
            margin-top: -52px;
        }
    }
}

/* page builder fix section connect */

.main:has(.section-approach + .section.about) {
    .section-approach {
        .insights-shape {
            display: none; /* hide shape if  .section.about is bellow than section-approach*/
        }
    }
}

.main:has(.section-approach + .join-team-section + .jobs-section-white) {
    .section-approach {
        z-index: 1;
        margin-bottom: 0;
        padding-bottom: 0 !important;
    }

    .join-team-section {
        z-index: 2;
        @media (max-width: 768px) {
            margin-bottom: 20px;
            margin-top: 0;
        }
    }

    .jobs-section-white {
        z-index: 3;
        margin-top: calc(var(--y1) * -1);

        &.clip-section {
            background: var(--bg-color);
        }
    }
}

.safari-12 .join-team-section {
    & + .jobs-section-white {
        margin-top: calc(var(--y1) * -1);
    }
}

.main:has(.section-approach + .join-team-section) {
    @media (min-width: 769px) {
        .section-approach {
            margin-bottom: 0;

            .insights-shape {
                display: none;
            }
        }

        .join-team-section {
            margin-top: calc(var(--y2) * -1);
            z-index: 1;
        }
    }
    @media (max-width: 768px) {
        .section-approach {
            margin-bottom: 74px;
        }

        .join-team-section {
            padding-top: 0;
            clip-path: none;

            .cont {
                padding-top: 0;
            }
        }
    }
}


.page-builder:has(.join-team-section + .team-swiper-section) {
    .team-swiper-section {
        @media (min-width: 769px) {
            margin-top: calc(var(--y1) * -1);
            z-index: 1;
        }
    }
}

.page-builder:has(.insights + .join-team-section) {
    @media (min-width: 769px) {
        .insights {
            margin-bottom: 0;
        }

        .join-team-section {
            margin-top: calc(var(--y2) * -1);
        }
    }
}

.main:has(.join-team-section:last-of-type) + .footer {
    @media (min-width: 1025px) {
        .footer__map {
            margin-top: 0;
        }
    }
}

.insights.insights-dark-bg + .contact {
    .section-clip-path {
        --y1: 80px;
        --x: 160px;
        -webkit-clip-path: polygon(
                0 0,
                calc(100% - var(--x)) 0,
                100% var(--y1),
                100% calc(100% - var(--y2)),
                50% 100%,
                0 calc(100% - var(--y2))
        );
    }
}

.page-builder {
    .client-page + .contact {
        .section-clip-path {
            --y1: 80px;
            --x: 160px;
            -webkit-clip-path: polygon(
                    0 0,
                    calc(100% - var(--x)) 0,
                    100% var(--y1),
                    100% calc(100% - var(--y2)),
                    50% 100%,
                    0 calc(100% - var(--y2))
            );
        }
    }

    .section-approach {
        .approach-con-bg {
            padding-bottom: var(--pb-initialized);
            background-color: var(--bg-color);
        }
    }

    .team-swiper-section {
        .cont {
            padding-bottom: var(--pb-initialized);
        }
    }

    .section-approach.section-approach--to-solution {
        @media (max-width: 768px) {
            margin-bottom: 0 !important;
        }

        .approach-cont-wr {
            @media (max-width: 768px) {
                padding-bottom: 50px !important;
                background: var(--bg-color);
            }
        }
    }
}

/* One to one section connection styles */
.one-to-one-initialized {
    &.section-approach {
        padding-bottom: 0 !important;

        .insights-shape {
            display: none !important;
        }

        @media (max-width: 768px) {
            padding-bottom: 0 !important;
            .section-approach-content {
                padding-bottom: 0;
            }
        }

        & + .join-team-section {
            .cont {
                padding-top: 40px !important;
            }
        }
    }

    &.insights {
        padding-bottom: 0 !important;

        .insights-cont-wr {
            padding-bottom: calc(var(--pb-initialized) * 2);
            @media (max-width: 1024px) {
                padding-bottom: 160px;
            }
        }
    }

    &.about {
        padding-bottom: 0 !important;

        .insights-shape {
            display: none !important;
        }

        .about__block {
            padding-bottom: var(--pb-initialized);
        }
    }

    &.contact {
        padding-bottom: 0 !important;

        .section-clip-path {
            padding-bottom: var(--bd-initialized);
        }
    }

    &.work-at-section {
        padding-bottom: 0 !important;

        .section-clip-path {
            padding-bottom: var(--pb-initialized);
        }
    }

    &.feedback {
        .cont {
            padding-bottom: var(--pb-initialized) !important;
        }

        @media (max-width: 768px) {
            .feedback__cont {
                padding-top: 0 !important;
            }
        }
    }

    &.join-team-section {
        .join-cont-wr {
            @media (max-width: 768px) {
                padding-top: 50px;
            }
        }
    }

    &.total:not(.total-dark) {
        .cont {
            padding-bottom: ac(50px, 0px);
        }
    }

    &.feedback.different-section {
        @media (min-width: 1026px) {
            padding-bottom: ac(50px, 40px) !important;
        }
    }

}

#site:not(.contractor-care-page) {
    .one-to-one-initialized.join-team-section {
        margin-bottom: 0 !important;
    }
}