.join-team-page {
    .jobs:after {
        display: none;
    }

    .hero-section {
        margin-bottom: calc(ac(90px, 35px) * -1);
        min-height: 78.6vh;
        padding-bottom: 225px;
    }

    .jobs {
        padding-top: 80px;
        padding-bottom: 70px;

        .jobs__decor {
            display: none;
        }
    }

    .contact {
        margin-top: -2px;
        z-index: 0;
    }

    .contact.contact-dark {
        .cont {
            padding-top: ac(0px, 50px);
            padding-bottom: ac(84px, 64px);
        }
    }

    .footer__map {
        margin-top: ac(103px, 0px);
    }
}

.hiring-section {
    --y1: ac(50px, 35px);
    --y2: ac(100px, 45px);
    --x: 138px;
    --ytop: var(--y1);
    --ybot: var(--y2);
    padding-top: var(--y1);
    padding-bottom: var(--y2);
    -webkit-clip-path: polygon(
            0 0,
            50% var(--y1),
            100% 0,
            100% calc(100% - var(--y2)),
            calc(100% - var(--x)) 100%,
            0 100%
    );
    clip-path: polygon(
            0 0,
            50% var(--y1),
            100% 0,
            100% calc(100% - var(--y2)),
            calc(100% - var(--x)) 100%,
            0 100%
    );
    background-image: url("../images/profile-bg.jpg");
    background-color: var(--color-white);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    @media (width: 1440px) {
        -webkit-background-size: 113% auto;
        background-size: 113% auto;
        background-position: 28% 44%;
    }

    .cont {
        padding-top: 56px;
    }

    &-title {
        padding-bottom: 22px;
        width: 100%;
        text-align: center;

        font-size: ac(36px, 28px);
        line-height: 1.2em;
        font-weight: 700;
        letter-spacing: -0.3px;
        max-width: 572px;
        margin: 0 auto;
        @media (max-width: 768px) {
            padding-bottom: 32px;
        }

    }

    &-content {
        max-width: 572px;
        margin: 0 auto;
        padding-bottom: ac(100px, 50px);

        p {
            padding-bottom: 16px;
            text-align: center;
            letter-spacing: -0.45px;

            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
}

.hiring-swiper-clip-path-section {
    --y1: ac(80px, 50px);
    --y2: ac(80px, 50px);
    --x1: ac(217px, 160px);
    background: var(--color-dark-blue);
    clip-path: polygon(
            0 var(--y1),
            calc(100% - var(--x1)) var(--y1),
            100% 0,
            100% calc(100% - var(--y2)),
            50% 100%,
            0 calc(100% - var(--y2))
    );
    padding-top: var(--y1);
    padding-bottom: var(--y2);

    .hiring-swiper {
        padding: ac(100px, 50px) 0;
    }
}

.hiring-swiper {
    overflow: visible;

    &:not(.swiper-initialized) {
        .swiper-wrapper {
            justify-content: center;
        }
    }

    .swiper-slide {
        @media (max-width: 1199px) {
            max-width: 230px;
        }
    }
}

.hiring-card {
    width: 100%;
    position: relative;
    box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.16);

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
        padding-top: 100%;
    }

    img,
    video,
    .plyr {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* cursor: pointer; */
    }

    .plyr {
        &:hover {
            .plyr__control {
                background: var(--color-light-blue);
            }
        }
    }

    .plyr__control {
        pointer-events: none;
        border: 1px solid var(--color-white);
        width: 72px;
        height: 72px;

        svg {
            width: 35px;
            height: 24px;
            left: 6px;
        }

        &:hover {
            background: var(--color-light-blue);
        }
    }
}

.work-at-section {
    position: relative;
    --y1: ac(50px, 35px);
    --y2: ac(176px, 53px);
    --ytop: var(--y1);
    --ybot: var(--y2);
    margin-bottom: 0;

    .section-clip-path {
        --ytop: var(--y1);
        --ybot: var(--y2);
        background: var(--color-white);
        background-position: 10% 10%;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-clip-path: polygon(
                0 0,
                50% var(--y1),
                100% 0,
                100% calc(100% - var(--y2)),
                50% 100%,
                0 100%
        );
        clip-path: polygon(
                0 0,
                50% var(--y1),
                100% 0,
                100% calc(100% - var(--y2)),
                50% 100%,
                0 100%
        );
        padding-bottom: var(--y2);
        padding-top: var(--y1);
    }

    .insights-shape {
        width: 50%;
        top: auto;
        bottom: 0;
        clip-path: polygon(0 0, 100% 28%, 0 100%);
        z-index: 1;
        background: var(--color-dark-blue);

        transform: translateY(71.9%);
        @media (max-width: 1024px) {
        }

        &::before {
            padding-top: 34%;
        }

        img {
            object-position: 0 center;
        }
    }

    .cont {
        padding: ac(117px, 40px) 0 ac(70px, 30px);
        position: relative;
        z-index: 2;
    }

    .swiper-buttons {
        width: 85px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding-top: 30px;
    }

    &-title {
        text-align: center;
        max-width: 470px;
        margin: 0 auto;
        padding-bottom: 33px;

        font-weight: 700;
        letter-spacing: -0.3px;
        font-size: ac(36px, 28px);
        line-height: 1.2em;

        h1,
        h2,
        h3 {
            &:not([class]) {
                margin: 0 !important;
            }
        }
    }
}

.main:has(.work-at-section:last-of-type) {
    .work-at-section {
        .insights-shape {
            display: none;
        }

        .section-clip-path {
            -webkit-clip-path: polygon(
                    0 0,
                    50% var(--y1),
                    100% 0,
                    100% calc(100% - var(--y1)),
                    50% 100%,
                    0 calc(100% - var(--y1))
            );
            clip-path: polygon(
                    0 0,
                    50% var(--y1),
                    100% 0,
                    100% calc(100% - var(--y1)),
                    50% 100%,
                    0 calc(100% - var(--y1))
            );
        }
    }
}

.main:has(.work-at-section + .nutshell-section) {
    .work-at-section {
        .insights-shape {
            display: none;
        }

        .section-clip-path {
            --y2: 0px;
        }
    }

    .nutshell-section {
        .section-clip-path {
            --y1: 0px;
            clip-path: none;
        }
    }
}

.work-at-card {
    display: flex;
    flex-wrap: wrap;
    max-width: 964px;
    margin: 0 auto;

    &-thumbnail {
        flex: 1;
        max-width: 280px;
        margin-right: ac(20px, 15px);
        align-self: flex-start;

        .img-thumb {
            width: 100%;
            position: relative;
            margin-right: ac(20px, 10px);
            flex-shrink: 0;

            &::before {
                content: "";
                display: block;
                padding-top: 100%;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .t-name {
            padding-bottom: 10px;
            font-size: ac(28px, 23px);
            line-height: 130%;
        }

        .t-position {
            font-size: ac(19px, 17px);
            color: var(--color-orange);
            letter-spacing: normal;
        }
    }

    &-content {
        flex: 1;
        min-width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: ac(32px, 10px);

        p {
            margin-bottom: 15px;
            letter-spacing: -0.45px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.nutshell-section {
    --x1: ac(723px, 240px);

    .section-clip-path {
        --y1: ac(112px, 35px);
        --ytop: var(--y1);
        --ybot: 0;
        padding-top: var(--y1);
        clip-path: polygon(
                0 0,
                var(--x1) var(--y1),
                100% var(--y1),
                100% 100%,
                0 100%
        );
        background: var(--color-white);
        position: relative;
        z-index: 1;
    }

    .insights-shape {
        z-index: 2;
        --y1: ac(239px, 81px);
        width: var(--x1);
        top: auto;
        bottom: 0;
        transform: translateY(28.2%);
        clip-path: polygon(0 0, 100% var(--y1), 0 100%);
        background: var(--color-dark-blue);

        &::before {
            padding-top: 46%;
        }

        img {
            object-position: 0 33%;
            transform: rotateY(180deg) translateZ(-1px);
        }
    }

    .cont {
        padding: 69px 0;
        display: flex;
        justify-content: space-between;
    }

    &-title {
        padding-bottom: 26px;
        font-size: ac(36px, 28px);
        line-height: 1.2em;
        font-weight: 700;
        letter-spacing: -0.3px;
    }

    &-motto {
        width: 474px;
        max-width: 44%;
        padding-right: 20px;
        padding-bottom: ac(135px, 80px);

        p {
            margin-bottom: 15px;
            letter-spacing: -0.45px;
        }

        ul,
        ol {
            list-style: circle;
            padding: 10px 0 10px 25px;

            li {
            }
        }

        a {
            color: var(--color-light-blue);
            transition: 0.2s;
            display: inline;

            &:hover {
                color: var(--color-green);
            }
        }

        ol {
            list-style: decimal;
        }
    }

    &-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 800px;
        max-width: 55%;
        grid-column-gap: ac(32px, 15px);
        grid-row-gap: ac(38px, 20px);
        margin-top: ac(-3px, 0px);
        align-self: flex-start;
    }
}

.nutshell-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    &-title,
    h2,
    h3 {
        color: var(--color-green);
        font-size: ac(34px, 20px);
        padding-bottom: ac(31px, 20px);
        line-height: 1.2;
        font-weight: 700;
        word-break: break-word;
    }

    h2 {
        font-size: ac(50px, 30px);
    }

    &-info {
        font-size: ac(19px, 16px);
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 0;

        * {
            font-size: inherit;
        }
    }
}

@media (max-width: 768px) {

    .work-at-section{
        z-index: 2;
        margin-bottom: -1px;
        .section-clip-path{
            --y2:0px;
            padding-bottom: 50px;
        }
    }
    .nutshell-section{
        z-index: 1;
        .section-clip-path{
            --y1:0px;
        }
    }




    .work-at-card {
        flex-direction: column;
        position: relative;
        padding-bottom: 50px;

        &-thumbnail {
            justify-content: center;
            max-width: unset;
            width: 100%;
            text-align: center;

            .img-thumb {
                max-width: 270px;
                margin: 0 auto 15px;
            }
        }

        &-content {
            max-width: unset;
            width: 100%;
        }

        .swiper-buttons {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 5px;
        }
    }
}

@media (max-width: 700px) {
    .nutshell-section .cont {
        flex-direction: column;
    }

    .nutshell-section {
        &-motto {
            max-width: unset !important;
            width: 100%;
            padding-bottom: 30px;
            padding-right: 0 !important;
        }

        &-title {
            max-width: unset;
            width: 100%;
        }

        &-list {
            max-width: unset;
            width: 100%;
            padding-bottom: 15px;
        }
    }
}

@media (max-width: 450px){
    .hiring-swiper{
        width: 80.55555555555556%;
        margin: 0 auto;
    }
}

@media (max-width: 420px) {
    .work-at-card {
        &-thumbnail {
            flex-direction: column;
            align-items: center;

            .img-thumb {
                margin-bottom: 20px;
            }
        }

        &-content {
            padding-top: 20px;
        }
    }

    .nutshell-section-list {
        grid-template-columns: 1fr;
    }
}

.join-team-page {
    .main:has(.hiring-section + .different-section) {
        .hiring-section {
            -webkit-clip-path: polygon(
                    0 0,
                    50% var(--y1),
                    100% 0,
                    100% 100%,
                    0 100%
            );
            clip-path: polygon(0 0, 50% var(--y1), 100% 0, 100% 100%, 0 100%);
            margin-bottom: calc(var(--y2) * -1);
            @media (max-width: 1024px) {
                margin-bottom: -85px;
            }
        }
    }
}

.main:has(.hiring-section + .work-at-section) {
    .hiring-section {
        --y2: 0px;
        padding-bottom: 45px;
    }

    .work-at-section {
        --y1: 0px;
        margin-top: -1px;

        .cont {
            padding-top: ac(50px, 10px);
        }
    }
}

.main:has(.work-at-section + .contact) {
    .work-at-section {
        .insights-shape {
            transform: translateY(20%);
            clip-path: polygon(0 0, 100% 80%, 0 100%);
            -webkit-clip-path: polygon(0 0, 100% 80%, 0 100%);
        }
    }
}
