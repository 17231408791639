html::-webkit-scrollbar,
html *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

html::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
}

html::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
    background-color: var(--color-orange);
}

html {
    scrollbar-width: thin;
    scrollbar-color: var(--color-orange) var(--color-dark-blue);
}

.fp-watermark {
    display: none;
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;
    background-color: var(--color-dark-blue);
    font-family: var(--font-main);

    &.lock {
        overflow: hidden;
    }
}

section {
    position: relative;
    z-index: 2;
}

.mb-hidden {
    @media (max-width: 650px) {
        display: none !important;
    }
}

.lg-hidden {
    @media (min-width: 651px) {
        display: none !important;
    }
}

.swiper-wrapper {
    left: 0 !important;
}

#site {
    position: relative;
    overflow: hidden;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

#site.class-404,
body,
html {
    height: 100%;
}

.main {
    flex-grow: 1;
}

.cont {
    margin: 0 auto;
    max-width: 1160px;
    width: perc(1160);
}

.swiper-arrows {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 85px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid var(--color-dark-blue);
        transition: all 0.3s;

        &:hover {
            background-color: var(--color-light-blue);

            i {
                color: var(--color-white);
            }
        }

        i {
            display: flex;
            font-size: 12px;
            color: var(--color-dark-blue);
            transition: all 0.3s;
        }

        &.swiper-button-disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &.swiper-button-lock {
            display: none;
        }
    }
}

.gradient-layer {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #002c5a, color-mod(#002c5a a(0%)));
    width: 100%;
    padding-top: 17.91%;
    z-index: 1;
    pointer-events: none;

    &.gradient-layer-to-top {
        background: linear-gradient(
                to top,
                #002c5a,
                color-mod(#002c5a a(0%))
        ) !important;
        top: auto;
        bottom: 0;
    }
}

.hero-section {
    position: relative;
    z-index: 0;
    /* min-height: calc(78.6vh - ac(90px, 35px)); */
    padding: ac(239px, 100px) 0 ac(100px, 80px);
    display: flex;
    flex-direction: column;

    .gradient-layer {
        z-index: 0;
    }

    .cont {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
    }

    a:not(.btn) {
        color: var(--color-light-blue);
    }

    ul {
        margin-top: 15px;

        li {
            &::before {
                content: "";
                position: absolute;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background-color: var(--color-light-blue);
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }
        }
    }

    ol {
        list-style: decimal;
        padding-left: 22px;
        margin-top: 15px;

        li {
            padding-left: 9px;
        }
    }

    li {
        position: relative;
        font-weight: 400;
        font-size: ac(17px, 15px);
        color: var(--color-dark-blue);
        line-height: ac(19px, 16px);
        letter-spacing: 0.3px;
        margin-bottom: 17px;
        padding-left: 32px;
    }

    h2,
    h3 {
        margin-bottom: 10px;
    }
}

.client-page {
    .hero-section {
        ul,
        ol {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.hero-title {
    font-size: ac(52px, 35px);
    color: var(--color-white);
    /* text-align: center; */
    margin: 0 auto 44px;

    width: 100%;
    max-width: 800px;
    font-weight: 700;
    letter-spacing: -0.3px;
    line-height: 1.1730769230769231em;
    font-family: var(--font-main);
}

.hero-motto {
    font-weight: normal;
    font-size: 15px;
    color: var(--color-white);
    max-width: 800px;
    margin: 0 auto;
    letter-spacing: -0.4px;
    line-height: 1.2;
    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
    }
}

.section-bg {
    background: var(--color-dark-blue);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.4;
    }
}

.feedback.case-studies-section {
    padding-top: ac(142px, 172px);
    padding-bottom: ac(126px, 40px);

    .feedback__cont {
        padding-top: ac(62px, 0px);
        width: 42.2%;
        @media (max-width: 1025px) {
            width: 100%;
        }

        h2 {
            margin-bottom: ac(30px, 10px);
        }

        p {
            width: 78%;
            margin-bottom: ac(32px, 18px);
        }
    }
}

.case-studies-shape {
}

.case-studies__swiper {
    align-self: flex-start;
    position: relative;
    width: ac(1052px, 908px, 1440, 1920);
    flex-shrink: 0;
    overflow: hidden;
    @media (max-width: 1025px) {
        overflow: visible;
        width: 100%;
    }

    .swiper-slide {
        max-width: 278px;
        @media (min-width: 1920px) {
            max-width: unset;
            .case-card {
                width: 100%;
            }
        }
        @media (max-width: 768px) {
            max-width: 230px;
        }
    }
}

.feedback__block.case-studies-block {
    flex-wrap: nowrap;

    .feedback__cont {
        flex-shrink: 0;
    }
}

.case-card {
    display: block;
    background-image: linear-gradient(
            125deg,
            var(--color-light-blue),
            var(--color-blue)
    );
    position: relative;
    cursor: pointer;
    width: 278px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    &-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        padding: ac(17px, 15px) ac(13px, 5px) ac(17px, 15px) ac(23px, 15px);

        &.studies {
            .case-card-info {
                * {
                    font-size: ac(19px, 16px);
                    @media (max-width: 650px) {
                        font-size: ac(16px, 11px, 320, 650);
                    }
                }
            }
        }
    }

    &-logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 0.2s;
        padding: 0 20px;

        img {
            max-height: 55px;
        }
    }

    &-info {
        opacity: 0;
        transition: opacity 0.2s;
        height: 90%;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        & > div {
            overflow-y: auto;
            max-height: 100%;
            padding-right: 10px;

            &::-webkit-scrollbar {
                width: 4px;
                height: 2px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.5);
                border-radius: 3px;
                cursor: pointer;
            }
        }

        * {
            font-size: ac(19px, 16px);
        }

        p {
            color: var(--color-white);
            font-size: ac(19px, 16px);
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &:hover {
        .case-card-logo {
            opacity: 0;
            pointer-events: none;
        }

        .case-card-info {
            opacity: 1;
        }
    }
}

.sectors-section {
    color: var(--color-white);
    padding: ac(89px, 50px) 0 ac(183px, 85px);

    &.white {
        color: var(--color-blue);
        background: var(--color-white);

        .sectors-card-title {
            color: var(--color-white);
        }

        .sectors-card .btn {
            color: var(--color-white);
        }
    }

    .cont {
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;

        .swiper-btn {
            margin-top: 8px;
        }

        .swiper-btn:not([aria-label]) {
            display: none;
        }

        .swiper-arrows {
            align-self: flex-end;
            display: none;
        }
    }

    .sectors-title-wr {
        padding-bottom: 25px;

        h1,
        h2,
        h3,
        p {
            margin-bottom: 15px;
            color: var(--color-white);
        }

        .sectors-title {
            margin-bottom: 15px;
            color: var(--color-white);
            font-size: ac(36px, 28px);
            line-height: 1.2em;
            font-weight: 700;
            letter-spacing: -0.3px;
        }
    }

    .sectors-title {
        margin-bottom: ac(31px, 20px);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;
    }

    .sectors-title {
        color: var(--color-dark-blue);
        margin-bottom: ac(31px, 20px);
        font-size: ac(36px, 28px);
        line-height: 1.2em;
        font-weight: 700;
        letter-spacing: -0.3px;
    }

    .btn {
        color: inherit;
    }

    .swiper-btn.swiper-button-disabled {
        opacity: 1;
        pointer-events: all;
    }
}

.sectors-section {
    &.client-candidate-white-clip-path {
        --y1: 0px;
        --y2: ac(89px, 60px);
        --x1: ac(160px, 120px);
        --ytop: var(--y1);
        --ybot: var(--y2);
        clip-path: polygon(
                0 var(--y1),
                100% 0,
                100% calc(100% - var(--y2)),
                calc(100% - var(--x1)) 100%,
                0 100%
        );
    }

    .sectors-swiper {
        padding-bottom: ac(54px, 75px);
    }
}

.client-page {
    .sectors-section {
        &.client-candidate-white-clip-path {
            --y1: ac(89px, 60px);
            --y2: ac(89px, 60px);
            --x1: ac(160px, 120px);
            --ytop: var(--y1);
            --ybot: var(--y2);
            clip-path: polygon(
                    0 var(--y1),
                    calc(100% - var(--x1)) var(--y1),
                    100% 0,
                    100% calc(100% - var(--y2)),
                    calc(100% - var(--x1)) 100%,
                    0 100%
            );
            margin-top: calc(var(--y1) * -1);

            .cont {
                padding-top: ac(100px, 40px);
            }
        }
    }
}

.sectors-swiper {
    overflow: visible;
    margin: 0 auto;
    max-width: 1160px;
    width: 80.55555555555556%;

    .swiper-slide {
        width: 278px;
        @media (max-width: 768px) {
            width: 230px;
        }
    }

    @media (min-width: 1700px) {
        max-width: 1920px;
        width: 100%;
    }
}

.sectors-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    background: var(--color-dark-blue);

    &::before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &-thumbnail {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.6;
        overflow: hidden;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
        }
    }

    &:hover {
        .sectors-card-thumbnail {
            img {
                transform: scale(1.1);
            }
        }
    }

    &-content {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        padding: 17px 13px;

        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        max-height: 100%;

        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            cursor: pointer;
        }
    }

    &-title {
        font-size: ac(28px, 20px);
        font-weight: 900;
        letter-spacing: -0.85px;
        margin-bottom: ac(42px, 30px);
        line-height: 120%;
    }

    .btn {
        padding-right: 8px;
        @media (max-width: 370px) {
            font-size: 9px;
            padding-right: 4px;
            margin-top: 5px;
        }
    }
}

.contact.contact-dark {
    --bg-color: transparent;
    background: transparent;
    clip-path: none;
    padding-top: ac(100px, 50px);
    color: var(--color-white);

    .section-clip-path {
        clip-path: none !important;
        background: var(--bg-color);

        &::before {
            display: none;
        }
    }

    .cont {
        padding-bottom: ac(185px, 75px);
        padding-top: ac(101px, 50px);
    }

    input,
    textarea {
        color: var(--color-white);
        border-color: var(--color-white);

        &::placeholder {
            color: inherit;
            opacity: 0.5;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label,
    p {
        color: var(--white);
    }

    .contact__head-title {
        color: var(--white);
    }

    span {
        color: inherit;
    }

    &::before {
        display: none;
    }

    .checkbox-entry {
        span {
            &::before {
                background: transparent;
                border-color: var(--color-white);
            }
        }

        input {
            &:checked + {
                span {
                    &::before {
                        background-color: var(--color-light-blue);
                    }
                }
            }
        }
    }

    .form__block {
        .form__item:nth-of-type(4) {
            grid-column: 1 / -1;
        }
    }

    .form__item {
        &:focus-within {
            input {
                &::placeholder {
                    color: var(--color-white);
                }
            }

            label {
                color: var(--color-white);
                transform: translateY(-3px);
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 50000s ease-in-out 0s;
            -webkit-text-fill-color: var(--color-white) !important;
            font-size: inherit;
        }
    }

    .select-wr .select2-container {
        border-color: var(--color-white);
    }

    .select2-container--default
    .select2-selection--single
    .select2-selection__placeholder {
        color: var(--color-white);
    }

    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
        color: var(--color-white);
    }

    .form.form-upload-cv {
        .upload-btn {
            label {
                border-color: var(--color-white);

                &:hover {
                    border-color: var(--color-light-blue);
                }
            }
        }

        .btn {
            color: var(--color-white) !important;
        }
    }

    .btn {
        color: var(--color-white);
    }
}

.candidate-page .contact.contact-dark .form.form-upload-cv {
    .btn {
        color: var(--color-dark-blue) !important;
    }
}

.submit-label {
    input {
        display: none;
    }
}

.insights.insights-dark {
    --y1: ac(236px, 50px);
    --x1: ac(723px, 375px);
    --ytop: var(--y1);
    --ybot: 0px;
    margin-top: -94px;
    z-index: 1;
    padding-bottom: 0;
    background: transparent;

    padding-top: var(--y1);

    .insights-cont-wr {
        &::before {
            clip-path: polygon(
                    0 0,
                    100% 0,
                    100% calc(100% - var(--y2)),
                    calc(100% - var(--x2)) 100%,
                    0 100%
            );
        }
    }

    .clip-path-section {
        background: var(--color-white);
    }

    .cont {
        padding-top: ac(100px, 130px);
        padding-bottom: 0;
    }

    @media (max-width: 375px) {
        clip-path: polygon(
                0 0,
                var(--x1) var(--y1),
                100% var(--y1),
                100% calc(100% - var(--y2)),
                calc(100% - var(--x2)) calc(100% - var(--y3)),
                0 100%
        );
        padding-bottom: 20px;
    }

    .insights-shape {
        z-index: 2;
        background: var(--color-dark-blue);

        img {
            transform: rotateY(180deg) translateZ(-1px);
            object-position: left top;
        }

        @media (max-width: 1200px) {
            img {
                transform: rotateY(180deg) translateZ(-1px);
            }

            &::before {
                padding-top: 37%;
            }
        }
    }
}

.insights-shape {
    width: ac(723px, 375px);
    position: absolute;
    left: 0;
    top: 0;
    clip-path: polygon(0 0, 100% 0, 100% var(--top-clip), 0 100%);
    background: var(--color-dark-blue);
    z-index: 0;
    pointer-events: none;

    &::before {
        content: "";
        display: block;
        padding-top: 45.211%;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.single-page-404 {
    padding: 100px 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    min-height: 100vh;

    .title-404 {
        font-size: ac(60px, 35px);
        color: var(--color-white);
        padding-bottom: 20px;
    }

    .text-404 {
        font-size: ac(24px, 20px);
        color: var(--color-white);
        padding-bottom: 20px;
        text-align: center;
    }

    .btn {
        color: inherit;
    }
}

.contact {
    z-index: 2;
    --y1: 80px;
    --y2: ac(100px, 40px);
    --x1: ac(179px, 160px);
    --ytop: var(--y1);
    --ybot: var(--y2);

    .section-clip-path {
        clip-path: polygon(
                0 0,
                calc(100% - var(--x1)) 0,
                100% var(--y1),
                100% calc(100% - var(--y2)),
                50% 100%,
                0 calc(100% - var(--y2))
        );

        padding-top: 0;

        background-color: var(--color-white);
        position: relative;
        z-index: 0;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/profile-bg.svg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: right center;
            transform-origin: right;
        }
    }

    .insights-shape {
        width: 50%;
        min-width: 320px;
        display: block;
        z-index: 1;
        padding: 0;
        top: 0;
        transform: translateY(-42%);
        clip-path: polygon(0 0, 100% 41.7%, 0 100%);

        &::before {
            padding-top: 21.31%;
        }

        img {
            object-position: 0px 25%;
        }
    }

    .cont {
        position: relative;
        z-index: 1;
        padding: ac(158px, 99px) 0 ac(220px, 75px);
    }

    @media (max-width: 1024px) {
        .insights-shape {
            display: block !important;

            &::before {
                padding-top: 38% !important;
            }

            img {
                object-position: 0 31%;
            }
        }
    }

    &__head {
        position: relative;
        width: 100%;
        margin-bottom: ac(35px, 28px);

        h2 {
            color: var(--color-dark-blue);
            letter-spacing: -0.25px;
            margin-bottom: ac(41px, 38px);
        }

        .contact__head-title {
            color: var(--color-dark-blue);
            letter-spacing: -0.25px;
            margin-bottom: ac(41px, 38px);
            font-size: min(max(calc(18.57143px + 1.4881vw), 30px), 40px);
            line-height: 1.2em;
            font-weight: 700;
        }

        .contact__head-title {
            color: var(--color-dark-blue);
            letter-spacing: -0.25px;
            margin-bottom: ac(41px, 38px);
            line-height: 1.2em;
            font-size: ac(36px, 28px);
            font-weight: 700;
        }
    }

    &__cont {
        display: flex;
        align-items: center;
        width: 100%;
        @media (max-width: 360px) {
            display: block;
            h4 {
                margin-bottom: 15px;
            }
        }

        h4 {
            display: inline-block;
            color: var(--color-dark-blue);
            margin-right: ac(22px, 21px);
        }
    }

    &__radios {
        .radio {
            margin-right: ac(15px, 16px);

            &:last-child {
                margin-right: 0;
            }

            span {
                letter-spacing: 0.2px;
            }
        }
    }
}

.home-page,
.interior-page:not(.contact-us-page, .upload-cv-page, .referral-page, .salary-page, .brief-page) {
    @media (max-width: 768px) {
        .contact {
            display: none !important;
        }
    }
}

.interior-page {
    .contact {
        .section-clip-path {
            @media (max-width: 768px) {
                --y1: 0;
            }
        }
    }
}

.terms-page {
    .footer__map {
        .gradient-layer {
            transform: translateX(-50%);
        }
    }
}

.terms-section {
    padding-top: ac(200px, 180px);
    padding-bottom: ac(120px, 50px);
    position: relative;
    z-index: 1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 15px;
    }

    a {
        display: inline;
        color: var(--color-light-blue);

        &:hover {
            text-decoration: underline;
        }
    }

    * {
        color: var(--color-white);
    }

    ul,
    ol {
        padding: 10px 0 10px 20px;
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    p {
        margin-bottom: 10px;
        line-height: 140%;
    }
}

.map-info-popup.footer__map {
    position: fixed !important;
    z-index: 3 !important;
    width: 100% !important;
    height: 100% !important;
    display: none;

    .info-box-bg,
    .info-box {
        display: block !important;
    }
}

.not-found-title {
    color: var(--color-white);
    width: 100%;
    grid-column: 1/-1;
}

.grecaptcha-badge {
    z-index: 4;
}

@media (min-width: 420px) {
    #site:has(.download-guid-title) {
        .popup {
            max-width: fit-content;
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}

.main:has(.section-approach + .contact) {
    @media (min-width: 670px) {
        .section-approach {
            margin-bottom: 0;
            z-index: 3;
        }

        .contact {
            clip-path: polygon(
                    100% 0,
                    calc(100% - 161px) 0,
                    100% 80px,
                    100% calc(100% - var(--y)),
                    50% 100%,
                    0 calc(100% - var(--y)),
                    0 0
            );
        }
    }
}

.sectors-section.sectors-section-rows {
    .sectors-rows {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 16px;
        @media (max-width: 1400px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 650px) {

            .sectors-card {
                width: 100%;
                max-width: 278px;
                margin: 0 auto;
            }
        }
    }

    .sectors-card {
        width: 100%;
        flex-shrink: 0;
    }
}

.insight.insight-grid {
    clip-path: none;
    padding: ac(120px, 50px) 0;
    background: transparent;

    .cont {
        .insight__inner {
            display: flex;
            flex-wrap: wrap;

            .insights__card {
                width: 310px;
                min-height: 310px;
            }
        }

        @media (max-width: 1800px) {
            width: 1315px;
        }
        @media (max-width: 1500px) {
            width: 1397px;
        }
        @media (max-width: 1440px) {
            width: 1040px;
        }
        @media (max-width: 1100px) {
            width: 657px;
        }
        @media (max-width: 700px) {
            width: 602px;
            padding: 0 20px;
            .insight__inner {
                justify-content: center;

                .insights__card {
                    width: 260px;
                    min-height: 260px;
                }
            }
        }
    }
}

.insight-grid-cont.cont {
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    padding-bottom: 35px !important;
}

.teammate-list-wr {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 11px;
    @media (max-width: 1100px) {
        grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 700px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.multi-select-item {
    input {
        position: relative;
        border-radius: 0;
        width: 100%;
        resize: none;
        color: #161626;
        font-size: 14px;
        border: 1px solid #161626;
        background: #ffffff;
        font-family: var(--font-main);

        &::placeholder {
            text-transform: uppercase;
            color: var(--black);
            font-size: 14px;
            font-weight: 600;
        }
    }

    .comboTreeInputBox,
    .comboTreeDropDownContainer input {
        padding: 10px 20px;
        display: none;
    }

    .comboTreeDropDownContainer {
        display: block !important;
        border-radius: 0 0 5px 5px;
        position: relative;
        overflow: hidden;
        min-height: auto;
        background: transparent;
        border: none;

        ul {
            color: var(--color-white);
            background: transparent;
        }

        li {
            position: relative;
        }

        .comboTreeItemTitle {
            position: relative;
            padding-left: 20px;

            input {
                position: absolute;
                top: 50%;
                left: 4px;
                transform: translateY(-50%);
                opacity: 0;
                cursor: pointer;
            }

            &::before {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                border: 1px solid var(--color-white);
                left: 4px;
                top: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                color: #ffffff;
            }

            &.comboTreeItemHover {
                background-color: transparent !important;
                border-radius: 0 !important;
                color: var(--color-light-blue);
                cursor: pointer;
            }

            &.selected {
                &::before {
                    content: "";
                    font-size: 0 !important;
                    background: var(--color-light-blue);
                    border: 1px solid var(--color-white);
                }
            }
        }
    }

    .comboTreeInputWrapper input {
        border: none;
    }

    .comboTreeInputBox {
        border-radius: 5px 5px 0 0;

        &:focus {
            border: none !important;

            & + .comboTreeArrowBtn {
                border: none !important;
            }
        }
    }

    .comboTreeParentPlus {
        cursor: pointer;
        top: 10px;
        left: 5px;
        position: absolute;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;

        span {
            &::before {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                background: url("../images/arrow.svg") no-repeat center /
                    contain;
            }
        }
    }

    .comboTreeArrowBtn {
        width: 12px;
        height: 12px;
        top: 50%;
        right: 45px;
        transform: translateY(-50%);
        border-radius: 0;
        border: none;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        display: none;

        &::after {
            content: "\2716";
            color: #161626;
            font-size: 15px;
        }

        &.show {
            display: flex;
        }
    }

    .comboTreeDropDownContainer li:hover ul,
    .comboTreeDropDownContainer li:hover {
        background: transparent;
    }
}

.floral {
    ul {
        display: block;

        li {
            display: flex;
            flex-direction: column;
            padding: 0 0 0 10px;
        }
    }

    & > ul > li {
        padding: 0 0 0 15px;
    }

    & > ul ul {
        display: none;
    }

    .type-filters-list {
        padding-left: 20px;

        li {
            padding-left: 0 !important;
        }

        .job-board__filter-label-word {
            padding-left: 0 !important;
        }
    }

    label {
        cursor: pointer;
    }

    input:checked {
        & + .checkbox__text {
            color: var(--color-yellow);
        }
    }

    .checkbox__text {
        color: var(--color-white);
        font-size: 15px;
        display: block;
        margin-bottom: 8px;
    }

    & > ul {
        /*max-height: 320px;*/
        overflow-y: auto;
        width: 100%;
        word-break: break-word;
        padding-right: 5px;
        @media (max-width: 768px) {
            max-height: 167px;
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            cursor: pointer;
        }

        scrollbar-width: 4px;
        scrollbar-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.5);
    }
}

#form-type {
    &.invalid {
        .checkbox-entry {
            span {
                color: var(--color-orange);
            }
        }
    }
}

.partnerships-box + .contact {
    .section-clip-path {
        --y12: 80px;
        --x: 160px;
        -webkit-clip-path: polygon(
                0 0,
                calc(100% - var(--x)) 0,
                100% var(--y12),
                100% calc(100% - var(--y1)),
                50% 100%,
                0 calc(100% - var(--y1))
        );
    }
}

body:has(.main .jobs:last-of-type) {
    .jobs {
        &::after {
            display: none;
        }
    }
}

section {
    .section-bg-initialized {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        z-index: -1;
        background: var(--bgcolor);
    }
}

.clip-section {
}

.main:has(.insight + .contact) {
    .insight {
        --y2: ac(100px, 40px);
        --x1: ac(179px, 160px);
        clip-path: polygon(
                0 0,
                50% var(--y1),
                100% 0,
                100% calc(100% - var(--y2)),
                calc(100% - var(--x1)) 100%,
                0 100%
        );
        margin-bottom: -1px;
    }
}

.main:has(.contact:last-of-type) {
    .contact-dark {
        padding-bottom: ac(40px, 0px);
    }
}

.main:has(.hero-section + .jobs-section-white) {
    .hero-section {
        min-height: 0px;
        padding-bottom: 50px;
    }
}

.form-referral {
    .form__block {
        @media (min-width: 1201px) {
            .form__item {
                &:nth-of-type(3),
                &:nth-of-type(4),
                &:nth-of-type(5) {
                    grid-row: 2/2;
                }
            }
        }
    }
}

#form-building-604 {
    @media (min-width: 1201px) {
        .form__item:nth-of-type(5) {
            grid-column: span 2;
        }
    }
}

.popup-form.upload-popup-form {
    .inputs-wr {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .form__item_fluid {
            grid-column: 1/-1;
            display: flex;
            align-items: flex-end;

            .form__item {
                flex: 1;
                align-self: flex-end !important;
            }

            .or-label {
                font-size: 20px;
                font-weight: 700;
                padding: 0 25px 8px;
                text-transform: uppercase;
            }

            .upload-btn {
                padding-bottom: 0;

                label {
                    margin-bottom: 0;
                    border-bottom: 2px solid var(--color-black);

                    &:hover {
                        border-color: var(--color-light-blue);
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .inputs-wr {
            grid-template-columns: 1fr;
        }
    }
    @media (max-width: 992px) {
        .inputs-wr {
            .form__item_fluid {
                flex-direction: column;
                order: 7;

                .form__item {
                    order: 0 !important;
                }

                .upload-btn {
                    align-self: stretch !important;
                }

                .or-label {
                    width: 100%;
                    text-align: center;
                    padding-top: 25px;
                    padding-bottom: 0;
                }
            }
        }
    }
}

.reload-btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    height: 20px;
    transition: all 0.3s;
    cursor: pointer;
    font-size: 13px;
    padding-right: ac(30px, 23px);

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        z-index: 1;

        display: block;

        height: ac(20px, 16px);
        width: ac(20px, 16px);

        padding-right: 25px;

        transition: all 0.3s;
        background: url(../images/reload-btn.svg) no-repeat 50% / contain;
    }

    &:hover {
        &::after {
            transform: translateY(-50%) rotate(-45deg);
        }
    }

    &.active {
        opacity: 1;
        pointer-events: all;
    }
}

.unsubscribe-section {
    padding: 250px 20px 150px;
    text-align: center;
    color: var(--color-white);
}

.js-text {
    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .js-text-short {
        display: none;

        margin-bottom: 17px;

        @mixin media 769 {
            display: block;
        }

        *:last-child {
            margin-bottom: 0 !important;
        }
    }

    .js-text-full {
        @mixin media 769 {
            display: none;
            p {
                @extend .fade-in;
                animation-duration: 300ms;
            }
        }
    }

    .js-text-btn {
        display: none;

        @mixin media 769 {
            display: block;
        }
    }
}

.unsubscribe-link {
    text-decoration: underline;
    transition: color 0.3s;

    &:hover {
        color: var(--color-light-blue);
    }
}

.thanks-title,
.subscribe__inner .thanks-title {
    line-height: 170% !important;
    font-size: ac(24px, 21px) !important;
    color: var(--color-dark-blue) !important;
}

.contact.contact-dark {
    .thanks-title {
        color: var(--color-white) !important;
    }
}

@media (max-width: 768px) {
    h2 {
        line-height: 1 !important;
    }
}

.simplebar-scrollbar {
    &::before {
        background-color: rgba(255, 255, 255, 0.5);
    }
}

.simplebar-track {
    background-color: rgba(255, 255, 255, 0.1);
}

.jobs-section-white {
    .simplebar-scrollbar {
        &::before {
            background-color: #0a9fd1;
        }
    }

    .simplebar-track {
        background-color: rgb(195, 227, 252);
    }
}

.contractor-care-page {
    .mb-hidden {
        display: block !important;

        .js-text-short {
            display: none;
        }
    }
}

@media (max-width: 650px) {
    .grecaptcha-badge {
        display: none !important;
    }

    .sectors-swiper, .case-studies__swiper, .team-swiper, .case-cards-swiper {
        max-width: 100%;

        .swiper-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 15px;
            transform: none!important;

            .swiper-slide {
                width: 100% !important;
                max-width: revert !important;
                display: none;

                &:nth-of-type(-n + 8) {
                    display: block;
                }

                .case-card {
                    width: 100%;


                    &-info {
                        p {
                            font-size: ac(13px, 11px, 320, 650);
                        }
                    }
                }
            }
        }
    }
    .case-studies__swiper{
        .swiper-wrapper {
            .swiper-slide {
                .case-card {

                }
            }
        }
    }

    .case-cards-swiper {
        .swiper-wrapper {
            .swiper-slide {
                .case-card {
                    &-info p {
                        font-size: ac(16px, 11px, 320, 650);
                    }
                }
            }
        }
    }

    .feedback__swiper {
        max-width: unset;
        width: 100%;

        .swiper-wrapper {
            display: grid;
            grid-gap: 25px;
            grid-template-columns: 1fr;
            grid-auto-rows: 1fr;
            justify-content: center;
            transform: none!important;


            .swiper-slide {
                width: 100% !important;
                display: none;
                position: relative;
                max-width: unset;


                &:nth-of-type(-n + 2) {
                    display: flex;
                }

                .feedback__card {
                    padding: 25px;
                    padding-right: 17px;
                    width: 100%;
                    max-width: unset;
                    margin: 0 auto;
                    height: auto;

                    &-text-content {
                        text-align: justify;
                        padding-right: 16px;
                        word-break: break-word;
                        --line-count: 20;
                        line-height: 1.2;
                        max-height: calc(1.2 * var(--line-count) * 14px);
                        font-size: 14px;
                        @media (max-width: 500px){
                            --line-count:7;
                        }
                        @media (max-width: 370px){
                            --line-count:6;
                        }


                        p {
                            font-size: inherit;
                        }
                    }
                    .feedback__info{
                        margin-top: 20px;
                    }

                    h5 {
                        font-size: ac(13px, 12px, 320, 375);
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    & > div:not([class]) {
                        &::before, &::after {
                            font-size: 22px;
                        }

                        &::before {
                            right: calc(100% + 2px);
                            top: 0;
                        }

                        &::after {
                            left: calc(100% + -16px);
                            bottom: -10px;
                        }
                    }
                }
            }
        }
    }

    .team-person-card {
        &-info {
            background: #000;

            .team-person-card-name {
                font-size: ac(18px, 14px, 320, 650);
                line-height: 1.2;
            }

            .team-person-card-position {
                font-size: ac(16px, 13px, 320, 650);
                line-height: 1.2;
            }
        }
    }


    .sectors-card-title {
        font-size: ac(16px, 12px, 320, 650);
        margin-bottom: ac(15px, 0px, 320, 650);
    }

}

@media (max-width: 450px){
    .feedback__swiper .swiper-wrapper{
        grid-template-columns: 1fr;
    }
  }