.meet-the-team-page {
    .hero-section {
        height: auto;
        padding-bottom: ac(204px, 160px);
        min-height: 78.6vh;
    }

    .join-team-section {
        --y2: 0;
        padding-top: ac(0px, 30px);

            @media (max-width: 450px){
                .joint-team-content{
                    padding-bottom: 74px;
                }
            }

    }

    .joint-team-content {
        padding-top: ac(118px, 50px);
        padding-bottom: ac(118px, 140px);
    }

    .join-team-thumbnail {
        clip-path: polygon(0 0, 100% var(--y2), calc(100% - 137px) 55.5%, calc(100% - 20px) 100%, 0 100%);
        width: 52.11%;
        top: -1px;
        height: calc(100% + 2px);
    }


    .footer__map {
        @media (min-width: 1025px) {
            margin-top: -5px;
        }
    }

}

.team-list-section {
    --y: ac(59px, 35px);
    --y2: ac(80px, 50px);
    clip-path: polygon(0 0, 50% var(--y), 100% 0, 100% calc(100% - var(--y2)), calc(100% - 160px) 100%, 0 100%);
    padding-top: var(--y);
    background: var(--color-white);
    padding-bottom: ac(209px, 80px);
    margin-top: -145px;

    .cont {
        display: flex;
        padding-top: ac(95px, 50px);
        width: 85.26%;
        max-width: 1420px;
    }


    .team-list {
        flex-grow: 1;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 10.4px;
        grid-row-gap: 10.3px;
        align-self: flex-start;


        .team-person-card {
            margin: 0;
            width: auto;
        }

        @media (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 370px) {
            width: 75%;
            margin: 0 auto;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    @media (min-width: 1700px) {
        .team-list {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}

.team-filters-aside {
    width: 300px;
    max-width: 25%;
    min-width: 230px;
    flex-shrink: 0;
    padding-right: 25px;
}


.search-filters-block {
    padding: 0 14px;
    background: linear-gradient(90deg, #004d95, #009ed0);

    form {
        max-width: 1059px;
        margin: 0 auto;

        input {
            visibility: hidden;
            display: none;

            &:checked {
                & + span {
                    opacity: 1;
                }
            }
        }

        label {
            color: var(--color-white);
            font-size: ac(16px, 12px);
            font-family: var(--font-main);
            letter-spacing: -0.4px;
            cursor: pointer;
            padding: 0;
            box-sizing: border-box;

            &:hover {
                span {
                    opacity: 1;
                }
            }

            span {
                display: block;
                padding: 15px 0;
                opacity: 0.5;
                transition: opacity 0.2s;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background: linear-gradient(to right, transparent, rgba(16, 22, 31, 0.25) 15%, var(--color-orange), rgba(16, 22, 31, 0.25) 85%, transparent);
                }
            }

            &:last-of-type {
                span {
                    &::after {
                        display: none;
                    }
                }
            }

            .inside-checkbox-group {
                display: none;
            }

            & > input:checked {
                & + .inside-checkbox-group {
                    display: block;
                }
            }
        }

        @media (max-width: 1200px) {
            grid-template-columns: repeat(5, 1fr);
        }
        @media (max-width: 1024px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 650px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 490px) {
            grid-template-columns: 1fr;
        }
    }
}



@media (max-width: 1024px) {
    .meet-the-team-page {
        .contact .insights-shape {
            img {
                object-position: 0 11%;
            }
        }
    }
}

@media (max-width: 850px) {
    .team-list-section {
        .cont {
            display: block;
        }

        .team-filters-aside {
            max-width: unset;
            min-width: unset;
            width: 100%;
            padding: 0 0 50px;
        }
    }

    .search-filters-block {
        form {
            grid-column-gap: 15px;
            padding: 0 0 15px;

            label {
                &:last-of-type {
                    span {
                        &::after {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .search-filters-block form {
        grid-template-columns: 1fr;
    }
}

.search-filters-toggle {
    color: var(--color-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.2s;
    @media (min-width: 851px) {
        display: none;
    }
    @media (max-width: 850px) {
        & + form {
            display: none;
        }
    }

    &-icon {
        position: relative;
        flex-shrink: 0;
        width: 10px;
        height: 10px;

        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            background: var(--color-white);
            transition: opacity 0.2s;
        }

        &::before {
            left: 50%;
            top: 0;
            width: 2px;
            height: 100%;
            transform: translateX(-50%);
        }

        &::after {
            height: 2px;
            width: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

    }

    &.active {
        .search-filters-toggle-icon {
            &::before {
                opacity: 0;
            }
        }
    }

    &:hover {
        opacity: 0.8;
    }
}