.home-page {
    .jobs {
        .jobs-decor-shape {
            transform: translateY(9%);
            clip-path: polygon(0 0, 100% 91%, 0 100%, 0 0);

            &::before {
                padding-top: 36%;
            }

            img {
                object-position: center 13%;
            }
        }
    }
}

.banner {
    position: relative;
    width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: ac(180px, 65px);

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media (max-width: 650px) {
            opacity: 0.5;
        }

        img {
            display: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            &.desk {
                display: block;
            }
        }
    }

    .cont {
        display: flex;
        align-items: center;
        flex-grow: 1;

        @media (max-width: 550px) {
            align-items: flex-end;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-grow: 1;
    }

    &__cont {
        position: relative;
        right: ac(-6px, 0px);
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 520px;
        margin-left: auto;
        padding: 30px 0;

        h1 {
            color: var(--color-white);
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(8, auto);
        /* grid-template-columns: repeat(auto-fill, 1fr); */
        justify-content: center;

        width: 100%;
        padding: ac(37px, 22px) 0;
        overflow: hidden;
        margin: 0 auto;

        @mixin media 951 {
            grid-template-columns: repeat(4, 1fr);
            width: 80.55555555555556%;
        }

        .swiper-slide {
            width: auto;
            height: unset;

            &:last-child {
                .banner__slide {
                    &::before {
                        display: none;
                    }
                }
            }

            @mixin media 951 {
                &:nth-child(4n) {
                    .banner__slide {
                        &::before {
                            display: none;
                        }
                    }
                }
            }

            @mixin media 601 {
                &:nth-child(2n) {
                    .banner__slide {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &__slide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 110px;
        padding: 0 ac(17.3px, 10px);

        @mixin media 951 {
            width: 100%;
            height: 75px;
        }

        @mixin media 501 {
            height: 65px;
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: -1.1px;
            width: 2px;
            height: 100%;
            background-image: linear-gradient(
                    to bottom,
                    transparent,
                    var(--color-orange),
                    transparent
            );
        }

        h3 {
            /* width: 100%; */
            width: fit-content;
            text-align: center;
            font-size: ac(20px, 16px);
            color: var(--color-white);
            font-weight: 400;

            @mixin media 951 {
                width: 100%;
            }
        }

        a {
            transition: 0.2s;
            transform-origin: center center;
            display: inline-block;

            * {
                transition: 0.2s;
            }

            &:hover {
                color: var(--color-light-blue);
                transform: scale(1.05);

                * {
                    color: var(--color-light-blue);
                }
            }
        }
    }
}

.about {
    --y1: 0px;
    --y2: 0px;
    --ytop: var(--y1);
    --ybot: var(--y2);
    position: relative;
    display: flex;
    width: 100%;
    background-color: var(--color-white);

    .insights-shape {
        bottom: 0;
        clip-path: polygon(0 0, 100% 29.3%, 0 100%);
        max-width: 100%;
        top: auto;
        transform: translateY(71%);
        width: ac(723px, 375px);
        z-index: 3;
    }

    &__decor {
        --x: 137px;
        position: absolute;
        top: 0;
        left: 0;
        /*width: 62.5%;*/
        width: 50.8%;
        height: 100%;
        clip-path: polygon(
                0 0,
                100% 0,
                calc(100% - var(--x)) 49.1%,
                calc(100% - 19px) 100%,
                0 100%
        );
        background: #ccc;
        z-index: 1;
        max-width: 1080px;

        div {
            position: absolute;
            left: 0;
            width: 100%;
            height: 50%;

            &:first-child {
                --y: 41px;
                top: 0;
                height: 49.1%;
                z-index: 1;
                clip-path: polygon(
                        0 0,
                        100% 0,
                        calc(100% - var(--x)) 100%,
                        0 calc(100% - var(--y))
                );
            }

            &:last-child {
                bottom: 0;
                height: 61%;
                width: 100%;
                z-index: 0;
                @media (width: 1440px) {
                    width: 98%;
                    height: 57.2%;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: calc(100% + 1px);
                object-fit: cover;
            }
        }
    }

    &__block {
        position: relative;
        width: 47%;
        background-color: var(--color-white);
        margin-left: auto;
        z-index: 0;
        display: flex;

        /*&::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/about_block_decor.jpg");
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: right bottom;
        }*/

        &-section-bg {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: var(--bg-color);

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: right bottom;
            }
        }

        .bottom-triangle {
            position: absolute;
            right: 0;
            bottom: -1px;
            background: var(--color-dark-blue);
            -webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%);
            clip-path: polygon(0 100%, 100% 0, 100% 100%);
            content: "";
            display: block;
            height: 89px;
            width: 160px;
            @media (max-width: 530px) {
                display: none;
            }
        }
    }

    &__wrapper {
        position: relative;
        width: 100%;
        max-width: 540px;
        padding: ac(55px, 79px) 0 ac(80px, 118px) 0;
    }

    &__cart {
        position: relative;
        left: ac(6px, 0px);
        margin: 0 0 100px auto;

        /*max-width: 67%;
        @media (min-width: 1800px) {
            max-width: 80%;
        }*/
        @media (max-width: 901px) {
            max-width: unset;
            width: 100%;
        }

        &:last-child {
            margin-bottom: 0;
        }

        h2 {
            color: var(--color-dark-blue);
            margin-bottom: ac(18px, 12px);
        }

        & > div,
        p {
            font-family: var(--font-main);
            color: var(--color-dark-blue);
            margin-bottom: 15px;
            letter-spacing: -0.4px;

            &:last-of-type {
                margin-bottom: ac(42px, 15px);
            }
        }

        .btn-wr {
            display: flex;
            flex-wrap: wrap;

            .btn {
                margin: 0 20px 20px 0;
                @media (max-width: 370px) {
                    margin-right: 10px;
                }
            }

            h3:last-of-type {
                .btn {
                    margin-right: 0;
                }
            }
        }
    }

    .bottom-triangle {
        @media (max-width: 550px) {
            display: none;
        }
    }
}

/* fix section connecting*/

.about + .insights {
    --y1: 0px;

    .cont {
        padding-top: ac(210px, 135px);
    }

    .insights-shape {
        display: none;
    }
}

.feedback {
    position: relative;
    width: 100%;
    padding: ac(112px, 145px) 0 ac(110px, 61px);

    .swiper-slide {
        height: auto;
    }

    &.white-bg {
        background: var(--color-white);

        .feedback__cont {
            h2,
            p,
            .btn {
                color: var(--color-dark-blue);
            }
        }
    }

    &__decor {
        img {
            position: absolute;
            z-index: 3;
            top: -93px;
            left: 0;
            width: auto;
            height: auto;
            transform-origin: top left;
        }
    }

    &__block {
        position: relative;
        display: flex;
        width: 100%;
        @media (max-width: 1025px) {
            display: block;
        }
    }

    &__cont {
        position: relative;
        z-index: 3;
        width: 44%;
        padding-right: 30px;
        flex-shrink: 0;
        color: var(--color-white);
        word-break: break-word;

        h2,
        p,
        .btn {
            color: var(--color-white);
        }

        div:not([class]) {

            letter-spacing: -0.4px;
            margin-bottom: ac(31px, 14px);
        }

        h2 {
            letter-spacing: -0.2px;
            margin-bottom: 20px;
            line-height: 114%;
        }

        p {
            letter-spacing: -0.4px;
            margin-bottom: ac(31px, 14px);
        }
    }

    &__swiper {
        position: relative;
        width: 68%;
        padding-top: ac(2px, 0px);
        margin-left: ac(2px, 0px);
        flex-shrink: 0;
        overflow: hidden;

        @media (min-width: 1441px) {
            width: 1440px;
        }

        @media (min-width: 1921px) {
            width: 1360px;
        }
        @media (min-width: 2600px) {
            width: 1540px;
        }

        .swiper-slide {
            width: max-content;
        }
    }

    /*.swiper-slide:nth-child(odd) {
        .feedback__card {
            background-image: linear-gradient(
                229deg,
                var(--color-light-blue) -14%,
                var(--color-white) 40%
            );

            p,
            h4,
            h5 {
                color: var(--color-dark-blue);
            }
        }
    }*/

    &__card {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 350px;
        height: 350px;
        justify-content: space-between;
        background-image: linear-gradient(
                125deg,
                var(--color-light-blue),
                var(--color-blue)
        );
        padding: ac(25px, 17px) ac(32px, 23px) ac(39px, 25px) ac(40px, 24px);

        p,
        h4,
        h5 {
            color: var(--color-white);
        }

        & > div:not([class]) {
            position: relative;
            display: flex;
            flex-direction: column;
            /*padding-bottom: ac(35px, 12px);*/

            &::before,
            &::after {
                content: "“";
                color: var(--color-orange);
                display: block;
                font-family: Gotham;
                font-size: 37px;
                font-weight: 700;
                height: auto;
                line-height: 20px;
                margin-bottom: 0;
                /*padding-bottom: min(
                    max(calc(-10.28571px + 1.33929vw), 0px),
                    9px
                );
                padding-top: 18px;*/
                position: absolute;
            }

            &::before {
                right: calc(100% + 4px);
                top: 5px;
            }

            &::after {
                content: "”";
                left: calc(100% + 4px);
                bottom: -8px;
                /*transform: translate(-100%, 16px);*/
            }
        }

        &-text-content {
            --line-count: 13;
            line-height: 1.23;
            max-height: calc(1.23 * var(--line-count) * 15px);
            flex-grow: 1;
            position: relative;
            z-index: 1;
            font-size: 15px;
            overflow-y: auto;
            padding-right: 10px;
            color: var(--color-white);

            &::-webkit-scrollbar {
                width: 4px;
                height: 2px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(255, 255, 255, 0.5);
                border-radius: 3px;
                cursor: pointer;
            }

            scrollbar-width: 4px;
            scrollbar-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.5);
        }

        p {
            position: relative;
            line-height: inherit;
            font-size: inherit;
            margin-top: 0;
        }

        h5 {
            letter-spacing: -0.5px;
            margin-bottom: ac(3px, 2px);
            font-size: ac(22px, 20px);
        }
    }

    &__info {
        position: relative;
        width: 100%;
        margin-top: 20px;
    }

    &__card-decor {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            width: auto;
            height: auto;
            bottom: 21px;
            left: 21px;
            transform-origin: bottom left;
        }
    }

    &__card-arrow {
        position: relative;
        display: block;
        width: 100%;
        font-size: 37px;
        font-weight: 700;
        font-family: "Gotham";
        color: var(--color-yellow);
        height: auto;
        padding-top: 18px;
        padding-bottom: ac(9px, 0px);
        line-height: 20px;
        margin-bottom: 0;
    }

    &__arrows {
        position: absolute;
        z-index: 3;
        bottom: 0;
        right: 35px;
        display: none;
    }
}

.total {
    --x1: ac(160px, 120px);
    --y1: ac(88px, 77px);
    --y2: ac(94px, 24px);
    --ytop: var(--y1);
    --ybot: var(--y2);
    position: relative;
    width: 100%;
    background-color: var(--color-white);
    padding: ac(108px, 117px) 0 ac(213px, 60px) 0;
    -webkit-clip-path: polygon(
            0 0,
            calc(100% - var(--x1)) 0,
            100% var(--y1),
            100% calc(100% - var(--y2)),
            50% 100%,
            0 calc(100% - var(--y2))
    );
    clip-path: polygon(
            0 0,
            calc(100% - var(--x1)) 0,
            100% var(--y1),
            100% calc(100% - var(--y2)),
            50% 100%,
            0 calc(100% - var(--y2))
    );

    &__title {
        margin-bottom: 21px;

        h2 {
            color: var(--color-dark-blue);
            font-weight: 900;
        }

        b,
        strong,
        span {
            color: var(--color-blue);
            font-weight: inherit;
        }
    }

    .swiper-wrapper {
        justify-content: space-between;
        display: flex;

        .swiper-slide {
            max-width: 29.6%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            height: auto;
        }
    }

    &__swiper {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    &__item {
        position: relative;
        width: 100%;
        text-align: right;
        margin-bottom: ac(48px, 46px);
        letter-spacing: -0.45px;

        &:last-child {
            margin-bottom: 0;
        }

        h4 {
            letter-spacing: 0.1px;
            color: var(--color-dark-blue);
            margin-bottom: 8px;
            font-family: 700;
        }

        p {
            letter-spacing: -0.45px;
            color: var(--color-dark-blue);
        }
    }

    &__number {
        position: relative;
        font-size: ac(40px, 35px);
        font-weight: 900;
        line-height: 1.2em;
        color: var(--color-orange);
        margin-bottom: ac(8px, 12px);
    }

    &__line {
        position: relative;
        width: 100%;
        height: 26px;
        margin-bottom: ac(10px, 8px);

        span {
            position: absolute;
            top: 0;
            right: ac(0px, 2px);
            width: 0;
            height: 100%;
            background-image: linear-gradient(
                    to right,
                    var(--color-orange),
                    transparent
            );
            clip-path: polygon(6px 0, 100% 0, 100% 100%, 6px 100%, 0 50%);
            transition: all 1s;
        }
    }

    &__arrows {
        display: none;
    }
}

.partners {
    position: relative;
    width: 100%;
    padding: ac(111px, 117px) 0 ac(109px, 59px) 0;

    &__block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1024px) {
            flex-direction: column;
            .partners__cont {
                padding-right: 0;
                max-width: unset;
                width: 100%;
            }

            .partners__items {
                max-width: unset;
                width: 100%;
                padding-top: 30px;
            }
        }
    }

    &__cont {
        position: relative;
        width: 35%;
        padding-right: ac(50px, 20px);

        h2,
        p,
        .btn {
            color: var(--color-white);
        }

        .btn {
            margin-top: 15px;
        }

        h2 {
            max-width: 85%;
            margin-bottom: ac(14px, 6px);
        }

        p {
            letter-spacing: -0.5px;
            line-height: 1.4em;
            margin-bottom: ac(22px, 15px);
        }
    }

    &__items {
        position: relative;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 20px 0;
        grid-gap: 16px;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        min-width: 172px;
        transition: all 0.3s ease;
        position: relative;
        background-image: linear-gradient(
                125deg,
                var(--color-blue),
                var(--color-light-blue)
        );

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 50%;
            max-height: 80%;
            object-fit: contain;
            transform: translate(-50%, -50%);
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}

.insights {
    --x1: ac(723px, 385px);
    --y1: ac(93px, 49px);
    --y2: ac(89px, 99px);
    --pb: ac(89px, 99px);
    --y3: ac(0px, 18px);
    --x2: 160px;
    --ytop: var(--y1);
    --ybot: var(--y2);
    position: relative !important;
    width: 100%;
    padding-top: var(--y1);

    .insights-cont-wr {
        position: relative;
        padding-bottom: var(--pb);

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: var(--color-white);
            clip-path: polygon(
                    0 0,
                    100% 0,
                    100% calc(100% - var(--y2)),
                    calc(100% - var(--x2)) 100%,
                    0 100%
            );
            @media (max-width: 375px) {
                clip-path: polygon(
                        0 0,
                        100% 0,
                        100% calc(100% - var(--y2)),
                        calc(100% - var(--x2)) calc(100% - var(--y3)),
                        0 100%
                );
                padding-bottom: 82px;
            }
        }
    }

    .cont {
        padding: ac(95px, 200px) 0 ac(8px, 0px);
        position: static;
        position: relative;
        z-index: 2;
    }

    .insights-shape {
        width: var(--x1);
        top: 0;
        clip-path: polygon(0 0, 100% var(--y1), 0 100%);
        background: var(--color-white);
        z-index: 1;
        filter: drop-shadow(0px -2px 1px var(--color-dark-blue));

        &::before {
            content: "";
            display: block;
            padding-top: 43.711%;
        }
    }

    &__decor {
        img {
            position: absolute;
            z-index: 3;
            top: 0;
            left: -4px;
            width: 723px;
            height: 332px;
            transform-origin: top left;
        }
    }

    &__block {
        display: flex;
        width: 100%;
        @media (max-width: 1025px) {
            display: block;
        }
    }

    &__cont {
        position: relative;
        z-index: 3;
        width: 38%;
        flex-shrink: 0;
        padding-top: ac(14px, 0px);
        padding-bottom: 53px;
        padding-right: ac(30px, 0px);

        h2 {
            color: var(--color-dark-blue);
            margin-bottom: 14px;
        }

        & > div {
            margin-bottom: ac(30px, 13px);
        }

        p {
            color: var(--color-dark-blue);
            letter-spacing: -0.45px;
            margin-bottom: ac(30px, 13px);
        }
    }

    &__arrows {
        position: absolute;
        right: 20px;
        bottom: 10px;
        display: none !important;
    }

    &__swiper {
        flex-shrink: 0;
        width: 74%;
        z-index: 1;
        overflow: hidden;
        padding: 15px 15px 15px 15px;
        align-self: center;

        .swiper-slide {
            width: 330px;
            height: 330px;
            display: flex;
        }

        @media (max-width: 650px) {
            max-width: 100%;
            height: 800px;
            .swiper-wrapper {
                justify-content: revert !important;
            }

            .swiper-slide {
                width: 230px;
                height: 230px;
            }
        }
        @media (max-width: 420px) {
            .swiper-slide {
                height: 150px;
            }

            .insights__img {
                padding-bottom: 40%;
            }
        }

        @media (max-width: 379px) {
            overflow: hidden;
            .insights__img {
                padding-bottom: 46%;
            }

            .insights__info {
                padding: 10px;

                div {
                    margin-bottom: 0;
                }
            }
        }
        @media (max-width: 360px) {
            .swiper-slide {
                height: 130px;
            }
        }

        @media (min-width: 1441px) {
            width: ac(1146px, 858px, 1440, 1920);
        }
        @media (min-width: 1921px) {
            width: 1480px;
        }
        @media (min-width: 2600px) {
            width: 1490px;
        }
    }

    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--color-white);
        box-shadow: 0 3px 10px rgba(112, 112, 112, 0.2);

        &:hover {
            .insights__img {
                img {
                    transform: scale(1.05);
                }
            }

            .insights__info {
                div {
                    color: var(--color-light-blue);
                }
            }
        }
    }

    &__img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 59%;
        background-color: var(--color-dark-blue);
        overflow: hidden;

        span {
            position: absolute;
            z-index: 1;
            top: 10px;
            left: 10px;
            display: flex;
            align-items: center;
            height: ac(22px, 19px);
            font-size: ac(12px, 10px);
            letter-spacing: -0.3px;
            color: var(--color-white);
            padding: 0 ac(10px, 12px);
            background-color: #a5b4c1;
            display: none;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.7s;
        }
    }

    &__info {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 18px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div {
            width: 100%;
            font-size: ac(15px, 13px);
            line-height: 1.3em;
            letter-spacing: -0.45px;
            font-weight: 700;
            margin-bottom: 10px;
            transition: all 0.3s;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        span {
            position: relative;
            width: 100%;
            font-size: ac(12px, 10px);
            line-height: 1.4em;
            letter-spacing: -0.3px;
            color: var(--color-dark-blue);
            margin-top: auto;
        }
    }
}

.jobs {
    position: relative;
    width: 100%;
    padding: ac(81px, 40px) 0 ac(88px, 150px) 0;

    &::after {
        /* content: "";
         display: block;
         width: 160px;
         height: 89px;
         position: absolute;
         right: 0;
         bottom: -1px;
         background: var(--color-white);
         clip-path: polygon(0 100%, 100% 0, 100% 100%);
         z-index: 1;*/
    }

    &__decor {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: ac(723px, 200px);
        clip-path: polygon(0 0, 100% 100%, 0 100%);

        &::before {
            content: "";
            display: block;
            padding-top: 33.1%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left top;
        }
    }

    .cont {
        position: relative;
        z-index: 2;
    }

    &__block {
        display: flex;
        width: 100%;
    }

    &__cont {
        width: 33%;
        padding-right: 20px;
        padding-top: 23px;
        flex-shrink: 0;
        max-width: 450px;
        position: relative;
        @media (min-width: 1700px) {
            width: 40%;
        }


        .jobs__cont-title {
            display: flex;
            margin-bottom: ac(14px, 5px);
            color: var(--color-white);
            font-size: ac(40px, 30px);
            line-height: 1.2em;
            font-weight: 700;
            letter-spacing: -0.3px;
        }

        h2,
        p,
        .btn {
            color: var(--color-white);
        }

        h2 {
            margin-bottom: ac(14px, 5px);

            span {
                font-weight: 900;
                color: var(--color-orange);
                padding-right: 10px;
            }
        }

        p {
            max-width: 84%;
            letter-spacing: -0.45px;
            line-height: 1.42em;
        }

        .btn {
            margin-top: ac(20px, 14px);
        }
    }

    &__items {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 280px);
        grid-gap: 31px;
        padding-left: ac(3px, 0px);
        margin: 0 auto;
        @media (min-width: 1700px) {
            grid-template-columns: repeat(3, 280px);
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        transition: all 0.3s;
        cursor: pointer;
        position: relative;

        &::before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        &-cont {
            display: flex;
            flex-direction: column;
            transition: all 0.3s;
            cursor: pointer;
            padding: 23px 20px;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &:hover {
            background-color: rgba(165, 180, 193, 0.4);
        }

        h4 {
            margin-bottom: ac(6px, 10px);
        }

        .btn {
            margin-right: ac(-5px, 0px);
        }

        h4,
        .btn {
            color: var(--color-white);
        }

        a:not(.btn) {
            transition: 0.2s;
            color: var(--color-white);

            * {
                color: inherit;
            }

            &:hover {
                color: var(--color-light-blue);
            }
        }

        .btn {
            padding-right: 9px;
        }

        h4 {
            letter-spacing: -0.4px;
            margin-bottom: ac(12px, 14px);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        @media (max-width: 768px) {
            .jobs__bottom {
                margin-top: 5px;
            }
        }
    }

    &__info {
        width: 100%;
        margin-bottom: 10px;
        max-height: ac(115px, 124px);
        padding-right: 20px;
        overflow-y: auto;

        scrollbar-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.1);
        scrollbar-width: 4px;

        &::-webkit-scrollbar-track {
            border-radius: 3px;
            background: rgba(255, 255, 255, 0.1);
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            cursor: pointer;
        }

        p {
            font-size: 15px;
            letter-spacing: -0.45px;
            font-weight: 700;
            color: var(--color-light-blue);
            line-height: 135%;
            margin-bottom: 6px;

            &:last-of-type {
                margin-bottom: 0;
            }

            &:nth-of-type(2) {
                text-transform: capitalize;
            }
        }
    }

    &__bottom {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: auto;

        &::before {
            position: absolute;
            content: "";
            left: 0;
            width: calc(100% - 136px);
            height: 1px;
            background-color: var(--color-white);
        }
    }
}

.candidate-page {
    .jobs {
        &::after {
            content: "";
            display: block !important;
            position: absolute;
            right: 0;
            bottom: -2px;
            width: ac(160px, 120px);
            height: ac(89px, 60px);
            background: var(--color-white);
            clip-path: polygon(0 100%, 100% 0, 100% 100%);
        }
    }
}

.home-page {
    .contact {
        &::before {
            background-position: right 101%;
        }

        .cont {
            padding-top: ac(147px, 99px);
            padding-bottom: ac(231px, 105px);
        }
    }

    .contact .insights-shape {
        display: none !important;
    }
}

@mixin desk-xl {
    .banner {
        height: 1080px;
    }

    .about {
        &__block {
            width: 44%;
        }
    }
}

@mixin desk-md {
    .about {
        &__block {
            width: 47%;
        }
    }
}

@media (max-width: 1279px) {
    .about {
        /*&::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url("../images/about_block_decor.jpg");
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: right bottom;
            @media (max-width: 370px) {
                background-position: right bottom;
            }
        }*/

        &__block-section-bg {
            img {
                object-fit: cover;
            }
        }

        &__decor {
            display: none;
        }

        &__block {
            width: perc(1160);
            clip-path: none;
            background-color: transparent;
            margin: 0 auto;
            position: static;

            &::before {
                display: none;
            }
        }

        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: none;
        }

        &__cart {
            max-width: unset;
            width: 100%;
        }
    }

    /* .banner {
        &__cont {
            margin-right: 50px;
        }

        &__wrapper {
            width: calc(100% - 54px);
            max-width: initial;
            margin: 0 auto;

            .swiper-slide {
                &:first-child {
                    .banner__slide {
                        h3 {
                            text-align: center;
                        }
                    }
                }

                &:last-child {
                    .banner__slide {
                        &::before {
                            display: block;
                        }

                        h3 {
                            text-align: center;
                        }
                    }
                }

                &.swiper-slide-active {
                    &::after {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: 2px;
                        height: 100%;
                        background-image: linear-gradient(
                            to bottom,
                            transparent,
                            var(--color-orange),
                            transparent
                        );
                    }
                }
            }
        }
    } */
    .total {
        &__swiper {
            padding-bottom: 5px;
        }

        .swiper-wrapper {
        }

        &__arrows {
            display: flex;
            margin: 45px auto 0 auto;
        }
    }

    .partners {
        &__cont {
            width: 45%;

            h2 {
                max-width: 75%;
            }
        }

        &__items {
            grid-template-columns: repeat(2, 1fr);
            width: 55%;
        }
    }

    .insights {
    }

    .jobs {
        &__decor {
            img {
                bottom: -84px;
                width: 650px;
            }
        }
    }

    .contact {
        &::before {
            transform: scale(1.1);
        }
    }

    .form {
        &__textarea {
            grid-column: 1 / -1;
        }

        &__bottom-left {
            width: 100%;
            margin-bottom: ac(34px, 17px);
        }
    }
}

@media (max-width: 1025px) {
    .banner {
        &__cont {
            margin-right: 0;
        }
    }

    .feedback {
        &__decor {
            img {
                top: -79px;
                transform: scale(0.85);
            }
        }

        &__block {
            position: relative;
            padding-bottom: 60px;
        }

        &__cont {
            width: 100%;
            padding-right: 0;
            margin-bottom: 40px;
            position: static;

            p {
                width: 100%;
                line-height: 140%;
            }
        }

        &__swiper {
            width: 100%;
            overflow: visible;

            &::before {
                display: none;
            }

            .swiper-slide {
            }
        }

        &__arrows {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }
    }

    .insights {
        &__cont {
            width: 100%;
            margin-bottom: 10px;
            padding-bottom: 37px;

            p {
                font-size: 14px;
                max-width: 100%;
                line-height: 140%;
            }
        }

        &__info {
            div {
                letter-spacing: -0.4px;
            }
        }

        &__swiper {
            width: 100%;
            height: 100%;
            padding: 0;
            overflow: visible;

            &::before {
                display: none;
            }
        }

        &__arrows {
            display: flex;
            margin: 27px auto 0 auto;
        }
    }

    .jobs {
        &__decor {
            img {
                bottom: -64px;
                width: 500px;
            }
        }

        &__block {
            flex-direction: column;
        }

        &__cont {
            width: 100%;
            padding: 0;
            margin-bottom: ac(74px, 78px);
        }

        &__items {
            margin: 0 auto;
        }
    }

    .jobs__info p {
        line-height: 140%;
    }
}

@mixin tab-md {
    .about {
        &__wrapper {
            display: block;
            max-width: none;
        }

        &__cart {
            margin: 0 0 67px 0;
            @media (max-width: 650px) {
                margin-bottom: 37px;
            }
        }
    }

    .partners {
        &__cont {
            width: 50%;
        }

        &__items {
            grid-template-columns: repeat(1, 1fr);
            width: 50%;
        }
    }

    .contact {
        &::before {
            transform: scale(1.2);
        }
    }
}

@mixin tab-sm {
    .banner {
        &__cont {
            max-width: 400px;
        }
    }

    .feedback {
        &__decor {
            img {
                top: -70px;
                height: 250px;
                transform: none;
            }
        }

        &__card-decor {
            img {
                bottom: auto;
                top: 44px;
            }
        }

        &__arrows {
            margin-top: -7px;
        }
    }

    .jobs {
        &__decor {
            img {
                bottom: -58px;
                width: 450px;
            }
        }

        &__cont {
            p {
                max-width: none;
            }
        }

        &__items {
            grid-row-gap: 39.5px;
        }
    }

    .form {
        &__block {
        }

        &__textarea {
            grid-column: 1 / -1;
        }
    }
}

@media (max-width: 651px) {
    .banner {
        &__bg {
            img {
                &.desk {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }
        }

        &__cont {
            max-width: initial;
            padding-bottom: 5px;

            h1 {
                line-height: 114%;
                letter-spacing: -0.2px;
                font-size: 30px;
                padding-bottom: 10px;
            }
        }
    }

    .about {
        &__cart {
            p {
                line-height: 1.43em;
                letter-spacing: -0.4px;
            }
        }
    }

    .feedback {
        &__decor {
            img {
                top: -61px;
                height: 220px;
            }
        }
    }

    .total {
        &__title {
            max-width: initial;

            h2 {
                letter-spacing: normal;
                line-height: 1.15em;
            }
        }

        &__item {
            h4 {
                font-size: 16px;
                padding-right: 2px;

                & + div {
                    display: none;
                }
            }

            p {
                font-size: 15px;
                letter-spacing: -0.41px;
                padding-right: 11px;
                line-height: 140%;
            }
        }
    }

    .partners {
        &__cont,
        &__items {
            width: 100%;
        }

        &__cont {
            padding: 0;
            margin-bottom: 42px;

            p {
                letter-spacing: -0.4px;
                line-height: 1.42em;
            }
        }

        &__items {
            padding-bottom: 52px;
        }

        &__item {
            justify-content: flex-start;
        }
    }

    .jobs {
        &__bottom {
            .btn {
                padding-right: 20px;
            }
        }
    }
}

@media (max-width: 551px) {
    .home-page {
        .about__wrapper {
            padding-bottom: 90px;
        }
    }

    .feedback {
        &__decor {
            img {
                top: -51px;
                width: 100%;
                height: 180px;
            }
        }

        &__card {
            p {
                letter-spacing: normal;
            }
        }
    }

    .insights {
    }

    .jobs {
        &__decor {
            img {
                bottom: -49px;
                width: 100%;
                height: 175px;
            }
        }
    }

    .contact {
        .section-clip-path {
            &::before {
                background-image: url("../images/contact_bg_mobile.jpg");
                transform: none;
                background-position: left bottom !important;
                @media (max-width: 375px) {
                    -webkit-background-size: cover;
                    background-size: cover;
                }
            }
        }

        &__head {
            h2 {
                letter-spacing: -0.2px;
            }
        }

        &__cont {
            h4 {
                letter-spacing: -0.35px;
            }
        }
    }

    .form {
        &__block {
            grid-template-columns: 1fr;
        }

        &__item {
            /* &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 3;
            }

            &:nth-child(3) {
                order: 5;
            }

            &:nth-child(4) {
                order: 5;
            }

            &:nth-child(5) {
                order: 2;
            }

            &:nth-child(6) {
                order: 4;
            }

            &:nth-child(7) {
                order: 7;
            }*/

            input,
            textarea {
                letter-spacing: 0.25px;
            }

            textarea {
                height: 96px;
            }
        }

        &__textarea {
            grid-column: 1 / 2;
        }

        &__bottom-left {
            .checkbox-entry {
                span {
                    font-size: 11px;
                    letter-spacing: 0.32px;
                    margin-bottom: 5px;

                    a {
                        font-weight: 400;
                    }

                    &:last-of-type {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        &__bottom-right {
            flex-direction: column;

            img {
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
    }
}

@mixin mob-sm {
    .banner {
        &__slide {
            h3 {
                font-size: 15px;
            }
        }
    }
}

.bold-logo-wr {
    display: flex;
    justify-content: center;
}

#bold-credits {
    width: ac(150px, 120px);
    margin: ac(30px, 20px) 0;
    transition: all 0.3s;

    &:hover {
        transform: scale(1.14);
    }
}

.upload-btn {
    padding: 10px 0 25px;
    display: flex;

    label {
        cursor: pointer;
        color: inherit;
        position: relative;
        transition: 0.2s;
        font-size: 19px;
        font-family: var(--font-main);
        padding: 0 20px 12px 0;
        border-bottom: 2px solid var(--color-white);

        &:hover {
            color: var(--color-light-blue);
            border-color: var(--color-light-blue);

            * {
                color: inherit;
            }
        }

        input {
            display: none;
        }
    }

    &.form__item {
        display: inline-flex;
        width: auto;
        align-self: flex-start;

        label {
            font-weight: normal;
        }

        .error-message {
            margin-top: ac(-24px, -14px);
        }
    }

    &.invalid {
        label {
            border-color: var(--color-orange) !important;

            &:hover {
                color: var(--color-orange);
            }
        }
    }
}

.form__item input.linkedin-input {
    &::placeholder {
        color: inherit !important;
        font-size: inherit !important;
        font-family: var(--font-main);
        opacity: 1 !important;
    }
}

.upload-status {
    word-break: break-word;
}

.select-wr {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > div {
        width: 100%;
    }

    label {
        width: 100%;
        display: block;
    }

    select {
        display: none;
    }

    .select2-container {
        padding: 0 20px 11px 0;
        border-bottom: 2px solid var(--color-dark-blue);
        font-size: 19px;
        font-family: var(--font-main);
    }
}

@media (max-width: 1025px) {
    .insights .insights-cont-wr {
        position: relative;
        padding-bottom: 146px;

        .cont,
        .insights__block,
        .insights__cont {
            position: static;
        }

        .insights__arrows {
            right: 50%;
            transform: translateX(50%);
            bottom: 10px;
        }
    }

    .home-page {
        .insights.insights-dark-bg {
            padding-bottom: 0;
        }
    }
}

@media (max-width: 992px) {
    .jobs__block {
        flex-direction: column;
    }

    .jobs__items {
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .feedback__swiper .swiper-slide {
        max-width: 280px;

        .feedback__card {
            width: 100%;
            height: 280px;
        }
    }

    .feedback__card-text-content {
        --line-count: 10;
    }

    .total {
        .swiper-wrapper .swiper-slide {
            max-width: 31.3%;
        }
    }

    .total__number {
        font-size: ac(24px, 21px, 320, 768);
    }

    .total__item {
        margin-bottom: 20px;

        h4 {
            font-size: ac(16px, 15px, 375, 768);

            & + div {
                display: none;
            }
        }
    }

    .jobs__item {
        .jobs__bottom {
            margin-top: auto;
        }
    }

    .jobs__info {
        max-height: 104px;
    }
}

@media (max-width: 650px) {
    .jobs__items {
        grid-template-columns: 375px;
        justify-content: center;
        grid-gap: 15px;
        width: 100%;

        .jobs__item {
            display: none;

            &:nth-of-type(-n + 2) {
                display: flex;
            }
        }
    }

    .jobs__item {
        padding: 0;

        h4 {
            margin-bottom: 6px;
        }

        &::before {
            display: none;
        }

        .jobs__bottom {
            margin-top: 20px;
        }
    }

    .jobs__info {
        max-height: revert;

        p {
            line-height: 1.2;
            margin-bottom: 5px;
        }
    }

    .jobs__item-cont {
        position: relative;
    }

    .total {
        display: none;
    }

    .about-us-page {
        .total {
            display: block;
        }
    }

    /*hide insight slider on this pages*/
    .home-page,
    .page-builder,
    .interior-page {
        .insights {
            display: none !important;
        }
    }

    .insights__swiper {
        .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 15px;
            width: 100%;

            .swiper-slide {
                height: auto;
                width: 100% !important;
                display: none;
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }

                &:nth-of-type(-n + 8) {
                    display: block;
                }
            }

            .insights__card {
                position: absolute;
                inset: 0;
                z-index: 1;
                height: auto;
                min-height: auto;
            }

            .insights__img {
                padding-bottom: 45%;
            }
        }
    }

    .banner__wrapper {
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        padding: 20px 0 28px;
        grid-row-gap: 25px;

        h3 {
            font-size: ac(13px, 12px, 320, 450);
        }

        .swiper-slide {
            &:nth-of-type(1) {
                .banner__slide a:before {
                    content: "\e912";
                }
            }

            &:nth-of-type(2) {
                .banner__slide a:before {
                    content: "\e90e";
                }
            }

            &:nth-of-type(3) {
                .banner__slide a:before {
                    content: "\e90f";
                }
            }

            &:nth-of-type(4) {
                .banner__slide a:before {
                    content: "\e914";
                }
            }

            &:nth-of-type(5) {
                .banner__slide a:before {
                    content: "\e910";
                }
            }

            &:nth-of-type(6) {
                .banner__slide a:before {
                    content: "\e913";
                }
            }

            &:nth-of-type(7) {
                .banner__slide a:before {
                    content: "\e90d";
                }
            }

            &:nth-of-type(8) {
                .banner__slide a:before {
                    content: "\e911";
                }
            }

            &:nth-of-type(odd) {
                .banner__slide {
                    &::before {
                        display: block;
                    }
                }
            }
        }
    }

    .banner__slide {
        display: flex;
        padding: 0;
        justify-content: center;
        height: revert;
        max-height: revert;

        h3 {
            width: fit-content;
            letter-spacing: 0.4px;
        }

        &::before {
            display: none;
        }

        a {
            color: var(--color-white);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-shrink: 0;
            align-items: center;
            text-align: center;
            align-self: center;

            &::before {
                content: "\e90b";
                font-family: icomoon;
                width: 40px;
                height: 40px;
                position: static;
                display: flex !important;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                transition: all 0.3s;
                background: linear-gradient(
                        140deg,
                        rgba(1, 139, 194, 0),
                        rgba(1, 108, 172, 0)
                );
                margin-bottom: 8px;
                color: var(--color-white);
            }

            &:hover {
                color: inherit;
                transform: none;

                &::before {
                    background: linear-gradient(140deg, #018bc2, #016cac);
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .jobs__item-cont {
        word-break: break-word;
    }
}

@media (max-width: 450px) {
    .total__swiper {
        width: calc(100% + 20px);
        margin-left: -20px;
    }

    .jobs__info {
        max-height: 120px;
    }
}

@media (max-width: 420px) {
    .jobs__items {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 375px) {
    .jobs__info {
        max-height: 96px;
    }
}

@media (max-width: 370px) {
    .jobs__info {
        max-height: 51px;
    }
}

.home-page {
    .main:has(.total + .insights) {
        .insights.insights-dark {
            margin-top: calc((var(--y1) + 2px) * -1);
        }

        .insights.insights-dark-bg {
            padding-top: ac(130px, 80px);
        }
    }

    .main:has(.jobs + .contact) {
        .jobs {
            margin-bottom: -1px;
        }
    }
}

.jobs:last-of-type {
    .jobs__decor {
        display: none;
    }
}
