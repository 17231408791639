.hero-contact-us {
    padding: 120px 0 20px;
    min-height: ac(378px, 300px);

    .cont {
        padding-top: ac(152px, 100px);
    }
}

.contact-us-page {
    @media (min-width: 1025px) {
        .footer__map {
            &::before {
                top: auto;
                bottom: 100%;
                transform: translate(-50%, -10%);
            }

            .gradient-layer:not(.gradient-layer-to-top) {
                transform: translate(-50%, -40%);
            }
        }
    }
}

.request-call-page {
    .hero-contact-us {
        padding: 120px 0 60px;
    }
}

.contact-us {
    margin-top: -19px;
    --y1: ac(51px, 30px);
    --y2: ac(90px, 30px);
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: var(--color-white);
    padding: var(--y1) 0 var(--y2);
    clip-path: polygon(0% 0%, 50% var(--y1), 100% 0%, 100% calc(100% - var(--y2)), 50% 100%, 0 calc(100% - var(--y2)));

    &::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/profile-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        transform-origin: right;
    }

    .cont {
        padding: ac(98px, 60px) 0 ac(140px, 80px);
        position: relative;
        z-index: 2;
    }
}


.upload-cv, .request {
    --y1: ac(42px, 30px);
    margin-top: -53px;

    .cont {
        padding-top: ac(92px, 60px);
        padding-bottom: ac(94px, 80px);
    }

}

.request {
    .form__block {
        @media (min-width: 1201px) {
            .form__item {
                &:nth-of-type(4) {
                    grid-column: 1 / -1;
                }
            }
        }
        @media (max-width: 1200px) {
            .form__item {
                &:nth-of-type(4) {
                    grid-row: 5/5;
                }

                &:nth-of-type(5) {
                    grid-row: 4/4;
                }
            }

        }
    }
}


.request-call-page .footer {
    margin-top: -7px;
}


.upload-cv-page .footer {
    margin-top: -4px;
}

.form.form-upload-cv {
    .form__block {
        @media (min-width: 1201px) {
            grid-template-columns: repeat(6, 1fr);
            .form__item {
                grid-column: span 2;
            }

            .form__item_fluid {
                grid-column: 1 /-1;
                display: flex;
                align-items: flex-end;

                .form__item, .upload-btn {
                    flex: 1;
                }

                .or-label {
                    padding: 0 30px 8px;
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            .upload-btn {
                padding-bottom: 0;
                align-self: end;

                label {
                    margin-bottom: 0;
                }
            }
        }
        @media (max-width: 1200px) {
            .form__item_fluid {
                grid-row-start: 4;

                .or-label {
                    width: 100%;
                    text-align: center;
                    padding: ac(40px, 25px, 375, 1200) 0 0;
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            .upload-btn {
                width: 100%;
            }
        }
    }

    .upload-btn {
        label {
            border-color: var(--color-dark-blue);
            @media (max-width: 1200px) {
                width: 100%;
            }
        }
    }
}

.form.form-upload-cv.form-get-in-touch{
    .select-wr{
        margin-bottom: 28px;
    }
    @media (max-width: 1200px){
        .form__item_fluid{
            grid-row-start: auto;
        }

      }
}
