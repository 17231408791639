.job-card {
  width: 100%;

  padding: ac(40px, 20px);
  border: solid 2px var(--color-black);
  border-radius: ac(20px, 10px);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h4 {
    margin-bottom: 5px;
  }

  .description {
    @mixin max-line-length 4;
    margin-bottom: 20px;
  }

  &_small {
    padding: 20px;

    .btn {
      width: 100%;
    }

    .btn-wrap {
      .btn {
        &:not(:last-of-type) {
          margin-right: 0;
        }
      }
    }
  }
}

.jobs-swiper-section {
  margin: ac(200px, 100px) 0;
}

.job-recommendation {
  margin: ac(200px, 100px) 0;

  h3 {
    margin-bottom: ac(40px, 20px);
  }
}

.job-page {
  &.first-section {
    .info {
      display: grid;
      grid-template-columns: max-content auto;
      align-items: center;

      h6 {
        width: ac(110px, 90px);
        margin-right: ac(20px, 10px);
        flex-shrink: 0;
      }

      h5 {
        margin-bottom: 0;
      }
    }
  }
}

.job-content {
  .sticky-wrap {
    display: flex;
    justify-content: space-between;

    @mixin media 651 {
      flex-direction: column-reverse;
    }

    &_related-jobs {
      @mixin media 1024 {
        flex-direction: column;

        .main-part {
          width: 100%;
        }

        .sidebar {
          width: 100%;
        }
      }
    }
  }

  .main-part {
    width: 70%;
  }

  .sidebar {
    width: 20%;

    @mixin media 901 {
      width: 25%;
    }
  }

  @mixin media 651 {
    .main-part,
    .sidebar {
      width: 100%;
    }

    .team-member-card {
      display: flex;
      margin-bottom: 50px;

      &.square-photo {
        .photo {
          width: ac(200px, 120px, 375, 650);
          padding-bottom: ac(200px, 120px, 375, 650);
          margin-right: 30px;
        }
      }
    }
  }

  @mixin media 420 {
    .sidebar {
      width: 80%;
      margin: 0 auto;
    }

    .team-member-card {
      flex-direction: column;

      &.square-photo {
        .photo {
          width: 100%;
          padding-bottom: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

.job-result {
  margin-top: ac(120px, 60px);

  .cont {
    display: flex;
    justify-content: space-between;
  }

  .filters {
    width: 22%;
    max-width: initial;
    min-width: 200px;

    margin: 0;
    margin-right: 20px;
  }

  .grid-two-wrapper {
    width: 75%;
  }

  @mixin media 650 {
    .cont {
      flex-direction: column;
    }

    .filters,
    .grid-two-wrapper {
      width: 100%;
    }

    .filters.sidebar {
      .main-filters {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
      margin-bottom: 35px;
    }
  }

  @mixin media 420 {
    .filters.sidebar {
      .main-filters {
        grid-template-columns: 1fr;
      }
    }
  }
}

.job-page-related {
  .slick-list {
    margin: 0 -15px;
  }

  .slick-slide {
    margin: 0 15px;
  }
}
