/*@font-face {
    font-family: "Gotham";
    src: url("../fonts/GothamBold.woff2") format("woff2"),
        url("../fonts/GothamBold.woff") format("woff"),
        url("../fonts/GothamBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-Medium.woff2") format("woff2"),
        url("../fonts/Gotham-Medium.woff") format("woff"),
        url("../fonts/Gotham-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/Gotham-Book.woff2") format("woff2"),
        url("../fonts/Gotham-Book.woff") format("woff"),
        url("../fonts/Gotham-Book.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../fonts/GothamBlack.woff2") format("woff2"),
        url("../fonts/GothamBlack.woff") format("woff"),
        url("../fonts/GothamBlack.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}*/

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
    url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Book.woff2') format('woff2'),
    url('../fonts/Gotham-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.woff2') format('woff2'),
    url('../fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
    url('../fonts/Gotham-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

body {
    color: var(--color-dark-blue);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-family: var(--font-main);
}

h1 {
    font-size: ac(52px, 35px);
    font-weight: 700;
    line-height: 1.1730769230769231 !important;
    letter-spacing: -0.3px;
}

h2 {
    font-size: ac(36px, 28px);
    font-weight: 700;
    line-height: 1 !important;
    letter-spacing: -0.3px;
}

h3 {
    font-size: ac(22px, 20px);
    font-weight: 700;
    line-height: 1.125 !important;
    letter-spacing: -0.3px;
}

h4 {
    font-size: 19px;
    font-weight: 700;
    line-height: 1.2 !important;
    letter-spacing: -0.3px;
}

h5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2 !important;
    letter-spacing: -0.3px;
}

p {
    font-size: ac(15px, 14px);
    /*line-height: 108%;*/
    line-height: 1.4;
    letter-spacing: -0.3px;
    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 16px;
    }
}
u {
    * {
        text-decoration: underline;
    }
}

.content-element {
    margin: ac(70px, 35px) 0;

    * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    h1,
    h2,
    h3 {
        font-weight: 400;
        margin-bottom: 19px;

        &:not(:first-child) {
            margin-top: ac(50px, 30px);
        }
    }

    h1 {
        font-size: ac(42px, 36px);
        font-weight: 700;
    }

    h2 {
        font-size: ac(36px, 30px);
        font-weight: 600;
    }

    h3 {
        font-size: ac(30px, 26px);
        font-weight: 500;
    }

    p {
        padding: 0;
        margin-bottom: ac(60px, 40px);
    }

    ul,
    ol,
    li {
        margin-bottom: 35px;
    }

    ul {
        list-style: none;

        li {
            position: relative;
            display: flex;

            &::before {
                content: "";
                width: ac(20px, 16px);
                height: ac(20px, 16px);
                display: block;
                flex-shrink: 0;
                margin-right: 5px;
                margin-top: ac(12px, 3px);
            }
        }
    }

    ol {
        list-style-type: decimal;
        list-style-position: inside;
    }

    li,
    blockquote {
        font-weight: 500;
        font-size: ac(24px, 14px);
        line-height: 200%;

        margin-bottom: 0;

        @mixin media 650 {
            line-height: 175%;
        }
    }

    blockquote {
        font-style: italic;
        position: relative;
        padding-left: ac(25px, 14px);
        margin-bottom: 40px;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: ac(4px, 2px);
            border-radius: 10px;
        }
    }

    img {
        border-radius: ac(40px, 20px);
        margin-top: ac(20px, 10px);
        margin-bottom: ac(76px, 35px);
    }
}
