.header {
    position: fixed;
    @media (min-width: 1025px) {
        position: absolute;
        .header__right {
            position: fixed;
            height: auto;
            right: 0;
            top: 0;
        }
    }
    z-index: 4;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: all 0.3s;
    pointer-events: none;

    &.scrolled {
        & + .header__layer {
            top: var(--header-height-scrolled);
            height: calc(100% - var(--header-height-scrolled));
        }

        .header__menu {
            top: var(--header-height-scrolled);
            height: calc(100% - var(--header-height-scrolled));
        }

        .header__right:before {
            opacity: 0.9;
        }
    }

    &.open-menu {
        background-color: var(--color-dark-blue);

        .header__burger {
            &::before {
                top: 12px;
                transform: rotate(45deg);
            }

            &::after {
                bottom: 12px;
                transform: rotate(-45deg);
            }

            span {
                left: 15px;
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__logo {
        position: fixed;
        left: 0;
        top: 0;
        display: inline-block;
        height: auto;
        padding: ac(30px, 20px) ac(50px, 30px);
        transition: all 0.3s;
        pointer-events: all;

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        img {
            width: ac(300px, 146px);
            height: auto;
            will-change: transform;
            transition: all 0.7s;

            &:nth-of-type(1) {
                display: block;
            }

            &:nth-of-type(2) {
                display: none;
            }
        }
    }

    &__right {
        position: relative;
        display: block;
        width: 100%;
        max-width: 696px;
        height: 100%;
        margin-left: auto;
        pointer-events: all;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
                    45deg,
                    var(--color-black),
                    var(--color-dark-blue)
            );
            clip-path: polygon(
                    0 0,
                    100% 0,
                    100% 100%,
                    60px 100%,
                    0 calc(100% - 69px)
            );
            opacity: 0.5;
            transition: opacity 0.3s;
            @media (max-width: 1280px) {
                opacity: 0.8;
            }
        }

        &.active {
            .header__head {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    &__head {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 60px;
        padding: 0 21px;

        &::before {
            position: absolute;
            content: "";
            bottom: 0;
            right: 0;
            width: 100%;
            height: 2px;
            background-image: linear-gradient(
                    to right,
                    var(--color-black),
                    var(--color-orange)
            );
            transition: all 0.4s;
        }
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        font-size: ac(15px, 14px);
        line-height: 1.3333333333333333em;
        letter-spacing: -0.3px;
        color: var(--color-white);
        padding-top: 7px;
        margin-right: 29px;
        transition: all 0.3s;

        &:hover {
            color: var(--color-light-blue);

            i {
                color: var(--color-light-blue);
            }
        }

        i {
            display: flex;
            font-size: 20px;
            color: var(--color-white);
            margin-right: ac(4px, 6px);
            transition: all 0.3s;
        }
    }

    &__lang {
        position: relative;
        width: 44px;
        height: 100%;
      /*  display: none;*/

        &.active {
            .header__lang-list {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
            }
        }
    }

    &__lang-active {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 10px 8px 0 8px;
        cursor: pointer;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__lang-list {
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 15px 0;
        opacity: 0;
        visibility: hidden;
        background-color: var(--color-dark-blue);
        transform: translateY(-5px);
        transition: all 0.3s;

        li {
            position: relative;
            width: 100%;
            padding: 0 8px;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            position: relative;
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &__hide {
        position: relative;
        width: 100%;
        /*display: none;*/
    }

    &__items {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 25px 25px 23px 80px;
    }

    &__item {
        position: relative;
        width: 24.5%;

        h4 {
            color: var(--color-white);
            position: relative;
            cursor: default;

            a,
            span {
                color: inherit;
                transition: 0.2s;
            }
        }

        &:hover {
            .header__item-list {
                opacity: 1;
            }

            h4 {
                color: var(--color-light-blue);
            }
        }
    }

    &__item-list {
        display: none;
        padding-top: 32px;
        padding-bottom: 25px;
        opacity: 0.5;
        transition: opacity 0.3s;

        li {
            position: relative;
            width: 100%;
            margin-bottom: 7px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                display: inline-block;
                font-size: ac(15px, 14px);
                line-height: 1.3333333333333333em;
                letter-spacing: -0.3px;
                color: var(--color-white);
                transition: all 0.3s;
                /*white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
*/

                &::first-letter {
                    text-transform: capitalize;
                }

                &:hover {
                    color: var(--color-light-blue);
                }
            }
        }
    }

    &__right-mobile {
        position: relative;
        display: none;
        align-items: center;
        pointer-events: all;
    }

    &__burger {
        position: relative;
        display: flex;
        align-items: center;
        width: 45px;
        height: 24px;
        margin-left: ac(40px, 26px);
        cursor: pointer;

        &::before,
        &::after,
        span {
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--color-white);
            transition: all 0.3s;
        }

        &::before {
            content: "";
            top: 0;
        }

        &::after {
            content: "";
            bottom: 0;
        }
    }

    &__layer {
        position: fixed;
        z-index: 3;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: calc(100% - var(--header-height));
        background-color: rgba(0, 44, 90, 0.65);
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    &__menu {
        position: fixed;
        top: var(--header-height);
        right: 0;
        display: block;
        width: 50%;
        height: calc(100% - var(--header-height));
        background-color: var(--color-dark-blue);
        overflow: auto;
        padding: 43px 36px;
        opacity: 0;
        visibility: hidden;
        transform: translateX(50%);
        transition: all 0.5s;
        pointer-events: all;

        &.active {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
}

.de-lang{
    .header__right{
        max-width: 850px;
    }
}
.interior-page {
    .header__logo {
        img {
            width: ac(84px, 52px);

            &:nth-of-type(1) {
                display: none;
            }

            &:nth-of-type(2) {
                display: block;
            }
        }
    }
}

.footer {
    position: relative;
    width: 100%;
    padding: 0;
    z-index: auto;

    &__map {
        position: relative;
        width: 65%;
        max-width: 1600px;
        margin: 0 auto;
        @media (max-width: 1024px) {
            width: 100%;
        }

        &::after {
            content: "";
            display: block;
            padding-top: 53%;
            z-index: -1;
            position: relative;
        }

        &::before {
            display: block;
            content: "";
            display: block;
            width: 100vw;
            height: 500px;
            position: absolute;
            top: 2px;
            left: 50%;
            transform: translate(-50%, -100%);
            background: var(--color-dark-blue);
            z-index: -1;
        }

        .img-map-wr {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
            opacity: 0.5;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            max-width: unset;
            width: 213.4736111%;
            height: auto;
            transform: translate(-11.4%, -18.4%);
        }

        .gradient-layer {
            width: 100%;
            left: 0;
            transform: translateY(ac(-100px, -58px));
            z-index: -1;
            background: linear-gradient(
                    to bottom,
                    var(--color-dark-blue) 0%,
                    var(--color-dark-blue) 25%,
                    color-mod(var(--color-dark-blue) a(0%)) 100%
            );
            min-height: 120px;
            @media (min-width: 1025px) {
                width: 100vw;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .gradient-layer-to-top {
            display: none;
            min-height: unset;
        }

        .label-box {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .label-item {
            position: absolute;

            &.active-index {
                z-index: 8;
            }

            @media (max-width: 1024px) {
                z-index: 4;
            }

            .btn {
                font-size: 9px;
                height: 18.9px;
                line-height: normal;
                padding-right: 16px;
                min-height: 0;

                span {
                    padding-left: 32px;

                    &::before {
                        width: 18.9px;
                        height: 18.9px;
                    }
                }
            }

            /*if LA - start 1*/

            &:nth-of-type(7) {
                left: 5.3%;
                top: 61.4%;

                .info-box {
                    right: auto;
                    left: 8px;
                }
            }

            &:nth-of-type(1) {
                left: 30.6%;
                top: 52.2%;
            }

            &:nth-of-type(2) {
                left: 66.2%;
                top: 33%;
                @media (max-width: 1500px) {
                    left: 63.2%;
                }
                @media (max-width: 1280px) {
                    left: 62%;
                }

                @media (max-width: 1100px) {
                    left: 62.4%;
                }

                @media (max-width: 851px) {
                    left: 61.4%;
                }

                .info-box {
                    right: 6px;
                }
            }

            &:nth-of-type(3) {
                left: 75.3%;
                top: 32.9%;
                @media (max-width: 768px) {
                    top: 32%;
                }
            }

            &:nth-of-type(4) {
                left: 75.3%;
                top: 41.1%;
                @media (max-width: 768px) {
                    top: 43.1%;
                }
            }

            &:nth-of-type(5) {
                top: 36.2%;
                left: 81.4%;
                @media (max-width: 768px) {
                    top: 37.4%;
                }
            }
        }

        .info-box {
            color: var(--color-white);
            background: rgba(0, 44, 90, 0.8);
            padding: 38px 27px 47px;
            width: 292px;
            min-height: 292px;
            display: none;
            position: absolute;
            bottom: 8px;
            right: calc(100% - 8px);
            z-index: 2;
            font-size: 13px;

            p {
                font-size: inherit;
            }

            .close-info {
                position: absolute;
                right: 16px;
                top: 16px;
                cursor: pointer;
                color: var(--color-white);
                transition: color 0.2s;
                font-size: 12px;

                &:hover {
                    color: var(--color-orange);
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &-title {
                color: var(--color-orange);
                margin-bottom: 36px;
            }

            ul {
                margin-bottom: 15px;

                a {
                    color: var(--color-white);

                    transition: 0.3s;

                    &:hover {
                        color: var(--color-light-blue);
                    }
                }
            }

            &-address {
                font-size: 13px;
                width: 137px;
                color: var(--color-white);
            }
        }
    }

    &__wrapper {
        position: relative;
        width: 93.72%;
        margin: ac(-100px, 0px) auto 0 auto;
        z-index: 2;
        max-width: 1390px;
    }

    &:not(:has(.footer__map)) {
        .footer__wrapper {
            margin-top: 0;
            padding-top: ac(80px, 50px);
        }
    }

    &__top {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-bottom: ac(0px, 10px);
        padding-right: ac(12px, 0px);
    }

    &__cont,
    &__items {
        position: relative;
        width: 37%;
    }

    &__cont {
        padding-top: ac(2px, 0px);

        .footer__cont_motto {
            max-width: 355px;
            letter-spacing: -0.45px;
            color: var(--color-white);
            margin-bottom: ac(31px, 17px);
            font-size: ac(15px, 14px);
            font-weight: normal;

            p {
                color: inherit;
                letter-spacing: inherit;
            }
        }
    }

    &__logo {
        position: relative;
        display: inline-block;
        margin-bottom: ac(24px, 19px);

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        img {
            width: ac(300px, 224px);
            height: auto;
            will-change: transform;
            transition: all 0.7s;
        }
    }

    &__socials {
        position: relative;
        display: flex;
        width: 100%;

        a {
            position: relative;
            display: inline-block;
            font-size: ac(24px, 20px);
            color: var(--color-white);
            margin-right: ac(28px, 24px);
            transition: all 0.3s;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: var(--color-light-blue);
            }

            &:active {
                transform: translateY(5px);
            }
        }
    }

    &__items {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 40px;
        grid-column-gap: 40px;
        width: 60%;
        max-width: 150px;
        @media (max-width: 901px) {
            margin: 0 auto;
            max-width: 320px;
        }
    }

    &__copyright {
        display: flex;
        flex-wrap: wrap;
        grid-column: 1/-1;
        padding-bottom: 20px;

        a {
            color: var(--color-white);
            padding-right: 10px;
            transition: 0.2s;

            &:not(:last-of-type) {
                &::after {
                    content: " |";
                    display: inline;
                    padding-left: 7px;
                    color: var(--color-white);
                }
            }

            &:hover {
                color: var(--color-light-blue);
            }
        }
    }

    &__item {
        position: relative;
        width: 100%;
        color: var(--color-white);

        h4 {
            color: var(--color-white);
            margin-bottom: 16px;

            a {
                color: inherit;
                transition: 0.2s;

                &:hover {
                    color: var(--color-light-blue);
                }
            }
        }
    }

    &__links {
        position: relative;
        width: 100%;

        li {
            width: 100%;
            margin-bottom: 7px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            position: relative;
            display: inline-block;
            font-size: ac(15px, 14px);
            line-height: 1.3333333333333333em;
            letter-spacing: -0.3px;
            color: var(--color-white);
            transition: all 0.3s;

            &:hover {
                color: var(--color-light-blue);
            }
        }
    }

    &__swiper {
        position: relative;
        width: 100%;
        padding-bottom: ac(67px, 32px);
        overflow: hidden;
        max-width: 1160px;
        margin: 0 auto 17px;

        .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-column-gap: 34px;
            grid-row-gap: 50px;
        }
    }

    &__slide {
        position: relative;

        h4 {
            color: var(--color-white);
            margin-bottom: ac(16px, 0px);
            padding-bottom: 6px;
            position: relative;

            @mixin media 551 {
                font-size: 16px;
            }
        }
    }

    &__slide-links {
        position: relative;
        width: 100%;
        margin-bottom: ac(16px, 5px);
        line-height: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            position: relative;
            display: inline;
            font-size: 12px;
            letter-spacing: -0.35px;
            line-height: 1.28em;
            color: var(--color-white);
            transition: all 0.3s;

            &:hover {
                color: var(--color-light-blue);
            }
        }
    }

    &__slide-cont {
        position: relative;
        width: 100%;
        font-size: 12px;
        letter-spacing: -0.35px;
        line-height: 1.28em;
        color: var(--color-white);
        max-width: 118px;
        @media (max-width: 768px) {
            display: none;
        }

        div:not([class]),
        p {
            font-size: inherit;
            letter-spacing: inherit;
            line-height: inherit;
            color: inherit;
        }
    }

    &__arrows {
        display: none;
        margin: 32px auto 0 auto;

        a {
            border-color: var(--color-white);

            &:hover {
                background-color: var(--color-white);

                i {
                    color: var(--color-dark-blue);
                }
            }

            i {
                color: var(--color-white);
            }
        }
    }

    &__bottom {
        position: relative;
        width: 100%;

        p {
            font-size: 12px;
            letter-spacing: -0.35px;
            color: var(--color-white);
            padding-top: 22px;
        }
    }
}

@mixin desk-sm {
    .footer {
        &__cont {
        }

        &__items {
        }

        &__swiper {
            .swiper-wrapper {
                display: flex;
                grid-template-columns: none;
                grid-column-gap: 0;
                grid-row-gap: 0;
            }
        }
    }
}
.info-box-bg {
    display: none;
}

.footer {
    &__map.footer__map_interior {
        margin-bottom: ac(-200px, -90px);

        &::before {
            display: none;
        }

        width: 43%;

        img {
            left: 50%;
            transform: translate(-50%, -18.4%);
        }

        .gradient-layer {
            width: 110vw;
            left: 50%;
            transform: translate(-50%, ac(-110px, -58px));

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: calc(100% - 2px);
                width: 100%;
                height: 100%;
                background: var(--color-dark-blue);
                pointer-events: none;
            }
        }

        @media (max-width: 1024px) {
            width: 30%;
            max-width: 650px;
            min-width: 170px;
            margin-bottom: -90px;
        }
    }
}

@media (max-width: 768px) {
    .footer {
        &__map:not(.footer__map_interior) {
            overflow-y: hidden;
            overflow-x: auto;
            top: auto;
            bottom: 0;
            margin-top: -72px;
            padding-top: 0;
            height: 400px;
            --map-inner-width: 768px;
            z-index: 0;

            .gradient-layer {
                transform: none;
            }

            .gradient-layer-to-top {
                display: block;
            }

            .gradient-layer-to-top,
            .gradient-layer {
                width: var(--map-inner-width);
            }

            .img-map-wr {
                width: var(--map-inner-width);
                overflow: hidden;
            }

            .label-box {
                width: var(--map-inner-width);
                top: 9px;
            }

            .info-box-bg {
                position: fixed;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 3;
                background: var(--color-dark-blue);
                opacity: 0.4;
            }

            .info-box {
                position: fixed;
                right: revert !important;
                bottom: revert !important;
                left: 50% !important;
                top: 50% !important;
                transform: translate(-50%, -50%) !important;
                z-index: 4;
            }
        }

        &__wrapper {
            margin-top: 37px;
        }
    }
}

@media (max-width: 1025px) {
    .footer__arrows {
        display: flex;
    }
}

@mixin tab {
    .header {
        display: flex;
        align-items: center;
        height: var(--header-height);
        padding: 0 36px;

        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            width: 105px;
            height: 82px;
            background-image: linear-gradient(
                    to right,
                    var(--color-black),
                    var(--color-dark-blue)
            );
            clip-path: polygon(100% 0, 100% 100%, 14% 100%, 0 88%, 0 0);
            transition: all 0.3s;
        }

        &.scrolled {
            height: var(--header-height-scrolled);
            background-color: var(--color-dark-blue);

            &::before {
                height: 100%;
            }

            .header__logo {
                padding: 0;
                background-color: transparent;
            }
        }

        &__logo {
            padding: 0;
            height: auto;
            position: relative;
        }

        &__right {
            display: none;
        }

        &__right-mobile {
            display: flex;
        }

        &__lang {
            height: auto;
        }

        &__lang-active {
            height: auto;
            padding: 8px;
        }

        &__menu-links {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding-bottom: 57px;
            @media (max-width: 450px) {
                padding-bottom: 25px;
            }

            &::before {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-image: linear-gradient(
                        to right,
                        var(--color-black),
                        var(--color-orange)
                );
            }
        }

        &__link {
            padding-top: 0;
            margin-right: 18px;
            letter-spacing: normal;

            &:first-child {
                span {
                    display: none;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__items {
            flex-wrap: wrap;
            padding: 70px 0 0 0;
            @media (max-width: 450px) {
                padding-top: 25px;
            }
        }

        &__item {
            width: 100%;
            margin-bottom: 27px;
            @media (max-width: 450px) {
                margin-bottom: 22px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            h4 {
                margin-bottom: 0;

                a {
                    color: rgba(255, 255, 255, 0.4);

                    &.active {
                        color: var(--color-white);
                    }
                }
            }
        }

        &__item-list {
            display: none;
            padding: 25px 0;
            @media (max-width: 450px) {
                padding: 12px 0 0;
            }

            &.active {
                display: block;
            }

            li {
                a {
                    letter-spacing: normal;
                }
            }
        }
    }

    .footer {
        &__cont {
            width: 35%;
        }

        &__items {
            width: 63%;
        }
    }

}

@media (max-width: 901px) {
    .header {
        &__menu {
            width: 65%;
            transform: translateX(65%);
        }
    }

    .footer {
        &__cont {
            width: 100%;
            margin-bottom: ac(67px, 41px);
            @media (min-width: 500px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                .footer__socials {
                    width: auto;
                }

                .footer__bottom {
                    width: auto;
                }
            }
        }

        &__items {
            width: 100%;
            padding-left: 0;
            text-align: center;
        }

        &__item:not(:has(*)) {
            display: none;
        }
    }
}

@mixin tab-sm {
    .header {
        &__menu {
            width: 100%;
            transform: translateX(100%);
        }
    }
}

@mixin mob-xl {
    .footer {
        &__wrapper {
            margin-top: 77px;
            width: 90%;
        }

        &__cont {
            margin-bottom: 0;

            p {
                max-width: 95%;
                letter-spacing: 0;
                line-height: 1.45em;
            }
        }

        &__items {
            /* display: none; */
            text-align: left;
            margin: unset;
            margin-top: 20px;
        }
    }
}
@media (max-width: 370px) {
    .footer__slide h4 {
        font-size: 15px;
    }

    .header__link {
        margin-right: 12px;

        i {
            font-size: 15px;
        }
    }

    .home-page {
        .header {
            padding: 0 25px;

            &::before {
                width: 88px;
            }
        }

        .header__burger {
            width: 36px;
        }
    }
}

.footer-swiper-line {
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(
            to right,
            var(--color-orange),
            var(--color-black) 90%,
            transparent 100%
    );

    @mixin media 1025 {
        display: none;
    }
}

.footer__offices {
    display: flex;
    justify-content: space-between;
    max-width: 1160px;
    margin: 0 auto 17px;
    padding-bottom: ac(67px, 32px);


}

.footer__slide {
    display: flex;
    justify-content: center;
    padding: 0 10px;
}

.footer__slide-inner {
    width: fit-content;
}

@media (max-width: 1024px) {
    .footer__offices {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 19px 10px;
    }

    .footer__slide {
        width: revert;
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 650px) {
    .footer__offices {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 450px) {
    .home-page {
        .header__logo img {
            width: 185px;
        }
    }

    .de-lang{
        .header__link{
            margin-right: ac(10px, 6px, 320, 450);
            i{
                font-size: ac(16px, 12px,320, 450);
            }
        }
        .header__menu{
            padding-left: 30px;
            padding-right: 30px;
        }
    }

}