.client-page {
    .placeholder-section {
        margin-top: ac(-67px, -17px);
    }

    .contact.contact-dark {
        clip-path: none !important;
        margin-top: 0;
        z-index: 1;
        padding-top: 0;
    }

    .insights.insights-dark {
        margin-top: ac(-121px, -30px);
        & + .sectors-section{
            @media (max-width: 650px){
                --x1: ac(723px, 375px);
                --x2: ac(179px, 160px);
                --y1: ac(93px, 50px);
                --y2: ac(137px, 57px);
                background: #fff;
                padding: 100px 0;
                .sectors-title{
                    color: var(--color-navy) !important;
                }
                clip-path: polygon(var(--x1) var(--y1),100% var(--y1),100% calc(100% - var(--y2)),calc(100% - var(--x2)) 100%,0 100%,0 0);
              }
        }
    }
    .feedback.case-studies-section {
        padding-top: ac(172px, 90px);
    }

    .contact.contact-dark .form__block {
        @media (min-width: 1201px) {
            .form__item {

            }
        }
        @media (max-width: 1200px) {

        }
    }
    .total{
        margin-top: -1px;
        &.total-dark{
            padding: ac(120px, 60px) 0;
            .total__title{
                margin-bottom: 30px;
                b, strong{
                    color: var(--color-orange);
                }
            }
        }
    }
    .sectors-section{
        padding: ac(90px, 50px) 0;
        @media (max-width: 650px){
            .swiper{
                padding-bottom: 0;
            }
          }
    }
}
.bottom-triangle {
    position: absolute;
    right: 0;
    bottom: -1px;
    background: var(--color-dark-blue);
    -webkit-clip-path: polygon(0 100%, 100% 0, 100% 100%);
    clip-path: polygon(0 100%, 100% 0, 100% 100%);
    content: "";
    display: block;
    height: ac(89px, 40px);
    width: ac(160px, 70px);
}

.section-approach {
    --y1: ac(49px, 35px);
    --ytop: var(--y1);
    --ybot: 0px;
    position: relative;

    &.section-approach--to-solution {
        .section-approach-content {
            padding-right: 0;
            padding-top: ac(60px, 30px);
            padding-bottom: ac(80px, 35px);
            padding-left: ac(170px, 150px);

            @media (max-width: 768px) {
                padding-left: 0;
            }
        }
    }

    .bottom-triangle {
        @media (max-width: 530px) {

        }
    }

    .section-approach-title {
        margin-bottom: ac(18px, 12px);
        color: var(--color-dark-blue);
        line-height: 1;
        font-size: ac(36px, 28px);
        font-weight: 700;
        letter-spacing: -0.3px;
    }

    h1,
    h2,
    h3,
    p {
        color: var(--color-dark-blue);
    }
    h1,
    h2 {
        margin-bottom: ac(18px, 12px);
    }
    ul {
        padding-left: 23px;
        list-style: circle;
    }
    ol {
        padding-left: 20px;
        list-style: decimal;
    }

    .approach-cont-wr {
        padding-top: var(--y1);
        @media (max-width: 768px) {
            clip-path: polygon(
                52% var(--y1),
                100% var(--y1),
                100% 100%,
                0 100%,
                0 0
            );
            background: var(--color-white);
        }
    }

    .approach-con-bg {
        background: var(--color-white);
    }

    &-thumbnail {
        position: absolute;
        width: 50.8%;
        height: calc(100% + 1px);
        left: 0;
        top: 0;
        --x1: ac(135px, 65px);
        clip-path: polygon(
            0 0,
            100% var(--y1),
            calc(100% - var(--x1)) 57.1%,
            100% 100%,
            0 100%
        );
        z-index: 1;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 55% center;
            @media (max-width: 1300px) {
                object-position: 99% center;
            }
        }
    }

    .cont {
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 0;
    }

    &-content {
        width: 60.5%;
        padding: ac(183px, 30px) 0 ac(189px, 50px) ac(170px, 150px);
        a:not(.btn) {
            transition: 0.3s;
            color: var(--color-light-blue);
            &:hover {
                * {
                    text-decoration: underline;
                }
            }
        }
    }

    &-title {
        line-height: 1;
        margin-bottom: ac(3px, 15px);
    }

    &-description {
        font-size: 15px;

        p {
            margin-bottom: 15px;
            letter-spacing: -0.45px;
            @media (min-width: 768px) and (max-width: 1024px) {
                font-size: 16px;
            }

            &:last-of-type {
                margin-bottom: 20px;
            }
        }
        h3 {
            display: inline-block;

            margin-right: 10px;
        }

        .btn {
            margin-top: 20px;
        }
    }

    .insights-shape {
        top: auto;
        bottom: 0;
        transform: translateY(59.5%);
        clip-path: polygon(0 0, 100% 41%, 0 100%);
        width: 50.8%;
        min-width: 200px;
        z-index: 1;

        &::before {
            padding-top: 32.211%;
        }
    }

    .placeholder-section {
        margin-top: calc(var(--clip-h) * -1);
    }
}

.jobs-section-white {
    --x1: ac(723px, 375px);
    --x2: ac(179px, 160px);
    --y1: ac(93px, 50px);
    --y2: ac(137px, 57px);
    --ytop: var(--y1);
    --ybot: var(--y2);
    --bg-color: var(--color-white);

    position: relative;
    background: var(--bg-color);
    clip-path: polygon(
        var(--x1) var(--y1),
        100% var(--y1),
        100% calc(100% - var(--y2)),
        calc(100% - var(--x2)) 100%,
        0 100%,
        0 0
    );
    padding-top: var(--y1);
    --diff: ac(38px, 0px);
    padding-bottom: calc(var(--y2) - var(--diff));

    .cont {
        padding-top: ac(100px, 140px);
        padding-bottom: ac(0px, 20px);
    }

    width: 100%;

    h1,
    h2,
    h2,
    p,
    .btn {
        color: var(--color-dark-blue);
    }

    .insights__decor {
        img {
            z-index: 0;
            width: var(--clip-w);
        }
    }

    .jobs__item {
        h4 {
            color: var(--color-dark-blue);
        }

        p {
            color: var(--color-light-blue);
        }

        .btn {
            color: var(--color-black);
            padding-right: 11px;
        }
    }
}

.job-shape {
    position: absolute;
    left: 0;
    top: 0;
    width: var(--x1);
    clip-path: polygon(0 0, 100% var(--y1), 0% 100%, 0 0);

    &::before {
        content: "";
        display: block;
        padding-top: 45.81%;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 2%;
    }
}

.placeholder-section {
    background: var(--color-white);
    --x1: ac(723px, 375px);
    --y1: ac(65px, 50px);
    --x2: ac(160px, 200px);
    --y2: ac(99px, 100px);
    clip-path: polygon(
        0 0,
        var(--x1) var(--y1),
        100% var(--y1),
        100% calc(100% - var(--y2)),
        calc(100% - var(--x2)) 100%,
        0 100%
    );

    padding-top: var(--y1);
    padding-bottom: var(--y2);
    position: relative;

    * {
        color: var(--color-dark-blue);
    }

    .cont {
        padding: 74px 0;
    }
}

@media (max-width: 1024px) {
    .section-approach-content {
        width: 63%;
    }
}

@media (max-width: 992px) {
    .feedback__block.case-studies-block {
        display: block;
        position: relative;
        padding-bottom: ac(90px, 72px);

        .feedback__cont {
            position: static;
            width: 100%;
        }

        .swiper-arrows {
            right: 50%;
        }
    }

    .case-studies__swiper {
        overflow: visible;

        .swiper-slide {
        }
    }
}

@media (max-width: 768px) {
    .section-approach-thumbnail {
        position: relative;
        left: auto;
        bottom: auto;
        width: 500px;
        max-width: 80.555555%;
        margin: 30px auto 0;
        clip-path: none;

        &::before {
            content: "";
            display: block;
            padding-top: 70%;
        }
    }

    .section-approach-content {
        width: 100% !important;
        clip-path: none;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 100px;
    }
}

.main:has(.section-approach + .insights-dark) {
    .section-approach {
        margin-bottom: 0;
        .bottom-triangle {
            display: none;
        }
        .insights-shape {
            display: none;
        }
    }
    .insights-dark {
        margin-top: calc(var(--y1) * -1 - 1px);
    }
}
