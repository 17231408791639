.blog-details-page {
    /*overflow: visible!important;
    .header, .footer, section{
        overflow: hidden;
    }*/
    .footer__map {
        margin-top: ac(10px, 0px);
    }

}

.hero-blog-details {
    &__inner {
        position: relative;
        max-width: 685px;
        padding-bottom: 65px;
        z-index: 3;

        &::before {
            content: "";
            position: absolute;
            height: 1px;
            width: calc(100% + 2000px);
            background-color: var(--color-white);
            bottom: 0;
            right: 0;
            z-index: 2;
        }

        .hero-title {
            margin-left: 0;
            text-align: initial;
            letter-spacing: 0.1px;
            margin-bottom: 20px;
            padding-right: 30px;
            font-size: ac(52px, 35px);
        }
    }

    &__box {
        .article-author {
            &-thumbnail {
                width: 140px;
                position: relative;
                margin-bottom: 15px;
                &::before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        p {
            color: var(--color-light-blue);
            font-size: ac(24px, 19px);
            font-weight: 700;
            margin-bottom: ac(13px, 7px);
            letter-spacing: -0.7px;
            a {
                transition: color 0.2s;
                &:hover {
                    color: var(--color-white);
                    text-decoration: underline;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
/*
<h1>Lorem ipsum.</h1>
    <h2>Lorem ipsum.</h2>
    <h3>Lorem ipsum.</h3><h4>Lorem ipsum.</h4><h5>Lorem ipsum.</h5><h6>Lorem ipsum.</h6>
    */

.blog {
    padding: 0 0 91px;
    overflow: visible!important;
    &__main{
        display: flex;
        gap: ac(50px, 30px, 650, 1440);
        @media (max-width: 650px){
            flex-direction: column;
            .blog-aside{
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
    &-aside{
        flex-shrink: 0;
        width: 304px;
        max-width: 30%;
        &-thumbnail{
            width: 100%;
            max-width: 304px;
            margin: 0 auto ac(40px, 25px);
            position: relative;
            overflow: hidden;
            &::before{
                content: '';
                display:block;
                padding-top: 75%;
            }
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .btn-wr{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 20px;
        }
    }
    &__inner {
        max-width: 866px;

        h1 {
            font-size: ac(52px, 35px);
            color: var(--white);
            border-bottom: 1px solid var(--color-orange);
            line-height: 1;
            letter-spacing: -2.6px;
            font-weight: 700;
            margin-bottom: 11px;
        }

        h2 {
            font-size: ac(44px, 31px);
            color: var(--white);
            border-bottom: 1px solid var(--color-orange);
            letter-spacing: -1.6px;
            line-height: ac(53px, 35px);
            margin-bottom: 9px;
            padding-bottom: 9px;
        }

        h3 {
            font-size: ac(38px, 28px);
            color: var(--color-light-blue);
            border-bottom: 1px solid var(--color-light-blue);
            letter-spacing: -1px;
            line-height: 1.3;
            margin-bottom: 14px;
        }

        h4 {
            font-size: ac(30px, 25px);
            color: var(--color-white);
            border-bottom: 1px solid var(--color-orange);
            letter-spacing: -0.7px;
            line-height: 1.5;
            margin-bottom: 14px;
            font-weight: 700;
        }

        h5 {
            font-size: ac(22px, 20px);
            color: var(--color-light-blue);
            border-bottom: 1px solid var(--color-light-blue);
            letter-spacing: -0.4px;
            line-height: 1.7;
            margin-bottom: 14px;
            font-weight: 700;
        }

        h6 {
            border-bottom: 1px solid var(--color-orange);
            color: var(--white);
            font-size: ac(20px, 18px);
            font-weight: 700;
            letter-spacing: -0.6px;
            line-height: 1;
            line-height: min(max(calc(0.57143px + 2.52976vw), 20px), 37px);
            margin-bottom: 12px;
        }

        p,
        span {
            color: var(--color-white) !important;
            font-size: ac(17px, 14px) !important;
            font-weight: 400;
            letter-spacing: -0.5px !important;
            margin-bottom: 17px !important;
            line-height: 1.35 !important;
            font-family: "Gotham" !important;
        }

        a {
            color: var(--color-light-blue);
            position: relative;
            display: inline;
            &:hover {
                text-decoration: underline;
            }
        }
        ul,
        ol {
            padding: 15px 0 15px 18px;
            list-style: circle;
            li {
                color: var(--color-white);
                font-size: inherit;
            }
        }
        ol {
            list-style: decimal;
        }
    }

    &__social {
        padding-top: ac(43px, 35px);

        a:hover i {
            color: var(--color-yellow);
        }

        i {
            color: var(--color-white);
        }
    }

}

.blog-details-page .insights-dark {
    --x: ac(723px, 200px);
    --y1: ac(45px, 35px);
    --y11: ac(20px, 35px);
    --y2: ac(92px, 47px);

    padding-bottom: var(--y2);
    padding-top: var(--y1);
    margin-top: 0;
    .insights-cont-wr {
        padding-bottom: 0;
        &::before {
            clip-path: polygon(
                0 0,
                50% var(--y1),
                100% var(--y11),
                100% 100%,
                0 100%
            );
        }
    }

    .cont {
        padding-bottom: ac(176px, 80px);
        padding-top: ac(147px, 77px);
    }

    .insights-shape {
        --y1: ac(230px, 42px);
        top: auto;
        bottom: 0;
        clip-path: polygon(0 0, 100% var(--y1), 0 100%);
        width: var(--x);
        transform: translateY(-3.2%);

        &::before {
            padding-top: 43%;
        }

        img {
            transform: rotateY(180deg);
        }
    }
}

.blog-details-page .footer {
    margin-top: -6px;
}

.hero-logo {
    max-width: 180px;
    min-width: 100px;
    margin-bottom: 25px;
    @media (max-width: 450px) {
        width: 150px;
        margin-left: 0;
        margin-bottom: 15px;
    }
}

.case-study-page {
    .hero-section {
        padding-top: ac(330px, 150px);
        .cont {
            z-index: 3;
        }
    }
    .hero-blog-details__inner {
        max-width: 1100px;
        padding-bottom: 11px;
        display: flex;
        justify-content: space-between;
        .hero-logo {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media (max-width: 450px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .hero-blog-details__box {
        padding-top: 0;
        margin-top: ac(-7px, 0px);
        position: relative;
        z-index: 2;
        padding-bottom: ac(86px, 50px);
        .hero-title {
            padding-right: 25px;
        }
    }
    .blog__inner {
        p {
            margin-bottom: ac(79px, 35px);
        }
    }
    .social {
        margin-top: 0;
        padding-top: ac(50px, 11px);
        a {
            text-decoration: none;
            &::before {
                display: none;
            }
        }
        p {
            margin-bottom: 17px;
            font-size: 15px;
            letter-spacing: -0.45px;
        }
    }

    .feedback.different-section {
        padding: 0;
        clip-path: none;
        margin-top: 0;
        background: transparent;
        z-index: 1;

        .section-clip-path {
            --y1: ac(46px, 35px);
            --y2: 0px;
            background: #fff;
            background: var(--color-white);
            clip-path: polygon(
                0 0,
                50% var(--y1),
                100% 0,
                100% calc(100% - var(--y2)),
                50% 100%,
                0 calc(100% - var(--y2))
            );
            padding-bottom: var(--y2);
            padding-top: var(--y1);
        }
        .cont {
            padding: ac(112px, 80px) 0 ac(213px, 100px);
        }
        .insights-shape {
            --y1: ac(230px, 119px);
            top: auto;
            bottom: 0;
            clip-path: polygon(0 0, 100% var(--y1), 0 100%);
            transform: translateY(26.9%);
            &::before {
                padding-top: 43.1%;
            }
            img {
                transform: rotateY(180deg) translateZ(-1px);
                object-position: left top;
            }
        }
    }
    .feedback.different-section .feedback__cont {
        @media (min-width: 1026px) {
            width: 42.3%;
        }
        padding-top: ac(63px, 0px);
        padding-right: ac(118px, 10px);
        padding-bottom: 30px;
        h2 {
            margin-bottom: 29px;
        }
        p {
            line-height: 138%;
            margin-bottom: 22px;
        }
    }
    .feedback__arrows {
        @media (min-width: 1440px) {
            right: 17px;
        }
    }
    .footer__map {
        margin-top: ac(0px, 69px);
    }
}
