.about-us-page {
    --bot-space: 145px;

    .hero-section {
        height: unset;
        min-height: 78.6vh;
        padding-bottom: ac(225px, 50px);
    }

    .total {
        --y2: ac(57px, 35px);
        --y3: ac(90px, 35px);
        clip-path: polygon(
                0 0,
                50% var(--y2),
                100% 0,
                100% calc(100% - var(--y3)),
                50% 100%,
                0 calc(100% - var(--y3))
        );

        .cont {
            padding-top: ac(56px, 0px);
        }
    }

    .feedback {
        .feedback__decor {
            display: none;
        }
    }

    .feedback.different-section {
        z-index: 3;
    }

    .insights {
        margin-top: 0;

        .insights-shape {
            display: none;
        }
    }

    .join-team-section {
        margin-top: -94px;
    }

    .sectors-section {
        padding-top: 58px;
        padding-bottom: ac(80px, 25px);
    }

    .placeholder-section {
        --y1: ac(120px, 35px);
        --y2: ac(136px, 35px);
        padding-top: 23px;
        padding-bottom: var(--y2);
        clip-path: polygon(
                0 0,
                100% 0,
                100% calc(100% - var(--y1)),
                75.7% 100%,
                0 calc(100% - var(--y2))
        );
    }

    .feedback:not(.different-section) {
        padding-top: ac(80px, 30px);
        padding-bottom: ac(150px, 100px);

        .feedback__cont {
            padding-top: 0;

            p {
                margin-bottom: ac(35px, 20px);
            }
        }

        .feedback__card {
            p {
                margin: 0;
            }

            &-arrow {
                line-height: 20px;
                height: unset;
                padding-top: 18px;
                margin-bottom: 0;
            }
        }
    }

    .insights.insights-dark {
        --y1: ac(96px, 50px);
        --x1: ac(719px, 460px);
        margin-top: calc(-1 * var(--y1));
        clip-path: polygon(
                var(--x1) var(--y1),
                100% var(--y1),
                100% calc(100% - 87px),
                calc(100% - 162px) 100%,
                0 100%,
                0 0
        );
        padding-top: var(--y1);
        background: #fff;

        .cont {
            padding-top: ac(102px, 58px);
        }
    }
}

.feedback.different-section {
    background: var(--color-white);
    --y1: ac(57px, 35px);
    --y2: ac(90px, 35px);
    --ytop: var(--y1);
    --ybot: var(--y2);
    padding-bottom: var(--y2);
    padding-top: var(--y1);
    margin-top: calc(-1 * var(--y2));
    clip-path: polygon(
            0 0,
            50% var(--y1),
            100% 0,
            100% calc(100% - var(--y2)),
            50% 100%,
            0 calc(100% - var(--y2))
    );

    .cont {
        padding: ac(57px, 0px) 0 ac(60px, 0px);
    }

    .feedback__cont {
        color: var(--color-dark-blue);
        width: 33.6%;
        padding-top: ac(47px, 20px);
        padding-right: ac(100px, 20px);
        padding-bottom: ac(37px, 0px);
        @media (max-width: 1025px) {
            width: 100%;
        }

        h2,
        p {
            color: var(--color-dark-blue);
        }

        p {
            width: 96%;
        }
    }
}

.different-card {
    display: block;
    background: linear-gradient(
            to right,
            var(--color-blue),
            var(--color-light-blue)
    );
    color: var(--color-white);
    position: relative;
    height: unset;

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    &-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 45px 12px 45px 22px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-title {
        font-size: 24px;
        font-weight: 600;
        text-align: right;
        line-height: 1;
        max-height: calc(26px * 3);
        color: var(--color-white);
        padding-right: 10px;
        margin-bottom: 5px;

    }

    &-info {
        height: calc(15px * 1.4 * 5);
        overflow-y: auto;
        padding-right: ac(10px, 6px);
        padding-left: ac(10px, 6px);

        &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            cursor: pointer;
        }
    }

    p {
        color: var(--color-white);
        text-align: right;
        font-size: 15px;
        line-height: 1.4;
    }
}

.journey-section {
    --mt: 100px;
    --mb: 100px;
    padding: calc(var(--mt) + 63px) 0 calc(var(--mt) + 43px);
    margin-top: calc(var(--mt) * -1);
    margin-bottom: calc(var(--mb) * -1);


    .section-bg {
        mix-blend-mode: multiply;
        overflow: visible;

        img {
            opacity: 1;
            width: 100%;
            height: 100%;
            top: -50px;
            object-fit: cover;
            mix-blend-mode: multiply;
            @media (max-width: 1024px) {
                height: 150%;
            }
        }
    }

    .swiper {
        position: relative;
    }

    .swiper-btn {
        position: absolute;
        z-index: 2;
        top: 39px;

        &::before {
            content: "";
            display: block;
            height: 1px;
            background: var(--color-white);
            width: ac(358px, 30px);
            position: absolute;
            top: 50%;
            @media (max-width: 374px) {
                display: none;
            }
        }
    }

    .journey-prev-btn {
        left: 0;

        &::before {
            left: 100%;
        }
    }

    .journey-next-btn {
        right: 0;

        &::before {
            right: 100%;
        }
    }

    &-title {
        color: var(--color-white);
        text-align: center;
        margin-bottom: 31px;
    }

    p,
    h2,
    h3,
    h1 {
        color: var(--color-white);
    }
}

.journey-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px 10px 20px;
    opacity: 0.2;
    transition: opacity 0.2s;

    &-title {
        margin-bottom: 36px;
        font-size: 40px;
        letter-spacing: -0.4px;
        transition: 0.3s;
        font-weight: 900;
    }

    p {
        font-weight: 700;
        font-size: 19px;
        line-height: 163%;
    }
}

.swiper-slide-active {
    .journey-card {
        opacity: 1;

        &-title {
            transform: translateY(11px);
            color: var(--color-orange);
        }
    }
}

.case-cards-section {
    @media (max-width: 1280px) {
        .swiper {
            overflow: visible;
        }

        .swiper-slide {
            width: 278px;
        }
    }
    @media (max-width: 768px) {
        .swiper-slide {
            width: 200px;
            max-width: 100%;
        }
    }
}

.team-section {
    background: var(--color-white);
    --y2: ac(76px, 35px);
    --y3: ac(89px, 35px);
    clip-path: polygon(
            0 0,
            50% var(--y2),
            100% 0,
            100% calc(100% - var(--y3)),
            50% 100%,
            0 calc(100% - var(--y3))
    );
    padding-top: var(--y2);
    padding-bottom: calc(var(--y3) - 8px);
    z-index: 1;

    &-title {
        padding-top: ac(104px, 40px);
        padding-bottom: ac(43px, 25px);
        text-align: center;
        color: var(--color-dark-blue) !important;
    }

    &-description {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin: 0 auto;
        @media (max-width: 650px) {
            width: 100%;
            padding-bottom: 40px;
        }

        p {
            margin-bottom: 20px;

            font-size: 15px;
            margin-bottom: 41px;
        }
    }

    .team-swiper {
        padding-bottom: 54px;
    }
}

.team-swiper,
.hiring-swiper,
.sectors-swiper {
    padding-bottom: 54px;

    .swiper-wrapper {
        justify-content: center;
    }

    &:has(.swiper-slide-duplicate) {
        .swiper-wrapper {
            justify-content: flex-start !important;
        }
    }
}

.safari-12,
.safari-13 {
    .team-swiper,
    .hiring-swiper,
    .sectors-swiper {
        .swiper-wrapper:not(.justify-center) {
            justify-content: flex-start !important;
        }
    }
}

.marquee-gallery {
    display: flex;
    flex-direction: column;
    padding-bottom: 54px;
}

.marquee-left,
.marquee-right {
    width: fit-content;
    margin: 0 auto;

    .js-marquee {
        /*margin-right: 0;*/
    }
}

.marquee-right {
    .team-list {
        flex-direction: row-reverse;
    }
}

.team-list {
    display: flex;
}

.team-person-card {
    margin-right: 12px;
    margin-bottom: 12px;
    width: 222px;
    position: relative;
   /* box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.7);*/
    display: inline-block;

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &-info {
        background: rgba(0, 44, 90, 0.70);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 12px 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
        transition: opacity 0.3s;

        h3 {
            color: var(--color-white) !important;
        }

        &:hover {
            opacity: 0;
        }
    }

    &-name {
        font-size: ac(20px, 16px);
        font-weight: 900;
        letter-spacing: -0.4px;
        padding-left: ac(17px, 0px);
    }

    &-position {
        font-size: ac(17px, 14px);
        font-weight: 700;
        color: var(--color-orange);
    }
}

.join-team-section {
    position: relative;
    --y1: ac(89px, 30px);
    padding-top: var(--y1);
    --ytop: var(--y1);
    --ybot: 0px;

    .cont {
        display: flex;
        justify-content: flex-end;
    }
}

.join-team-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    width: 50.65vw;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    --ytop: var(--y1);
    --ybot: 0;
    clip-path: polygon(
            0 0,
            100% var(--y1),
            calc(100% - 137px) 53.3%,
            calc(100% - 6px) 100%,
            0 100%
    );

    img {
        position: absolute;
        left: 0;
        top: 0;
        max-width: unset;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 1440px) {
            object-position: left 91%;
        }
    }
}

.joint-team-content {
    padding: ac(192px, 50px) 0;

    position: relative;
    color: var(--color-white);
    word-break: break-word;
    @media (min-width: 769px) {
        width: ac(534px, 240px, 769, 1440);
    }

    .joint-team-title {
        margin-bottom: 18px;
        color: var(--color-white);
        font-size: ac(36px, 28px) !important;
        font-weight: 700;
        line-height: 1.2em;
        letter-spacing: -0.3px;
        *{
            font-size: inherit!important;
            font-weight: inherit!important;
            margin: inherit!important;
            line-height: inherit!important;
        }
    }

    h1,
    h2,
    h3 {
        margin-bottom: 18px;
        color: var(--color-white);
    }

    p {
        margin-bottom: 15px;

        letter-spacing: -0.4px;
        color: var(--color-white);

        &:last-of-type {
            margin-bottom: ac(47px, 30px);
        }
    }

    .btn {
        margin-top: 25px;
        padding-right: ac(57px, 18px);
        color: var(--color-white);
    }

    ul,
    ol {
        padding: 15px 0 15px 18px;
        list-style: circle;

        li {
            color: var(--color-white);
            font-size: inherit;
        }
    }

    ol {
        list-style: decimal;
    }

    a:not([class]) {
        color: var(--color-light-blue);

        &:hover {
            text-decoration: underline;
        }
    }
}

.case-cards-section {
    padding-top: 39px;
    padding-bottom: 109px;
    color: var(--color-white);

    .swiper-buttons {
        margin: 0 auto;

        .swiper-btn {
            margin-top: 20px;
        }
    }

}

@media (max-width: 768px) {
    .join-team-section .cont {
        flex-direction: column;
    }

    .join-team-thumbnail {
        position: relative;
        right: auto;
        top: auto;
        bottom: auto;
        left: auto;
        -webkit-clip-path: none !important;
        clip-path: none !important;
        margin: 0 auto !important;
        max-width: 500px;
        width: 100% !important;
        background: var(--color-light-blue);

        &::before {
            content: "";
            display: block;
            padding-top: 70%;
        }
    }

    .joint-team-content {
        width: 100% !important;
        padding-bottom: 80px;
    }
}

@media (max-width: 650px) {
}

.different-section.different-section-about-us {
    .feedback__block {
        flex-direction: column;
    }

    .feedback__cont {
        width: 100%;
        padding-bottom: 20px;
        padding-right: 0;

        p {
            width: 100%;
        }
    }

    .case-studies__swiper {
        width: 100%;
        @media (max-width: 768px) {
           max-width: revert;
        }
    }

    .feedback__arrows {
        position: static;
        margin: 35px auto 0;
        transform: none;
    }

    .swiper-slide {
        max-width: 278px;
    }

    .different-card-title {
        font-weight: 900;
        @media (max-width: 650px){
            font-size: ac(24px, 15px, 320, 650);
            padding-right: 0;
            margin-bottom: 0;
          }
    }
}

.about-us-page {
    .section-approach {
        margin-bottom: ac(90px, 30px);

        .approach-cont-wr {
            position: relative;
            z-index: 0;
        }
    }
}

@media (max-width: 650px) {
    .about-us-page {
        .feedback:not(.different-section) {
            display: none;
        }
    }
    .different-card{
        &-content{
            justify-content: center;
            padding: 10px;
        }
        &-title{
            *{
                font-size: ac(21px, 14px, 320, 650);
            }
        }
        &-info{
            margin-top: 10px;
            *{
                font-size: ac(16px, 12px, 320, 650);
                line-height: 1.2;
            }
            max-height: calc(ac(16px, 12px, 320, 650) * 5 * 1.2);
        }
    }
}
